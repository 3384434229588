import React from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import items from '../data/items.js'

const images = require.context('../assets/products', true);

export const ProfileComponent = () => {
  const { user } = useAuth0();

  function click(d) {
    var self = this;
  }

  return (
    <Container className='egrecorp home'>
      <div>INVENTORY———</div>
      {/*items.map(function(d,i){
        let productName = d.name.replace(" ", "");

        if (!user["https://egrecorp.com/" + productName]) return

        return (
          <div className='product' key={"product" + i} onClick={ click.bind(this, d) }>
            <img src={ images(`./${productName}.gif`).default } />
            <div className='title'>{d.name.toUpperCase()}</div>
          </div>
        )
      })*/}
      <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
