import React from 'react'

const texts = {
offline: {
	title: "Graphical environment offline",
	debugger: "Please add the DEBUGGER plugin to restore the last working state:"
},
'en': {
	ui: {
		enter: "ENTER",
		exit: "EXIT",
		back: "BACK",
		left: "LEFT",
		right: "RIGHT",
		remember: "DEBUG",
		report: "REPORT",
		debug: "DEBUG",
		load: "LOAD",
		view: "VIEW",
		read: "READ",
		capture: "CAPTURE",
		use: "USE",
		interact: "INTERACT",
		value: "VALUE",
		devalue: "DEVALUE",
		cleanse: "CLEANSE",
		edit: "EDIT",
		elevator: "ELEVATOR",
		locked: "[LOCKED]",
		missing: "ARCHIVE DATA MISSING",
		debugger: "This is your DEBUGGER - Click to DEBUG",
		shooter: "This is your WEAPON - Click to DEBUG",
		valuator: "This is your VALUATOR - Click to DEBUG",
		lantern: "This is your ENLIGHTENER - Click to DEBUG",
		blackbox: "BLACK BOX detected",
		egrecore: "EXIT BLACK BOX",
		remembering: "LOADED a user's MEMORYBLOCK",
		tombstone: "LOADED a corpse's MEMORYBLOCK",
		npc: "LOADED a BOT's BLACK BOX",
		policy: "Displaying a CODE PROPOSAL up for vote",
	},
	report: {
		puredao: "«UNSTABLE FICTION» Users report widespread interest in illusions. Please control these fictions by making them with STIGMATA.",
		oceandao: "«UNSTABLE FICTION» Some users report feelings that the economic system has transcended into an actual living entity. Please control this fiction by refusing to VALUE it.",
		empiredao: "«UNSTABLE FICTION» New phenomenon where users report perceiving contradictory things about themselves. Please add or remove DATA STREAMS from the ENLIGHTENER to control these contradictions.",
		stackdao: "«UNSTABLE FICTION» Some users report feeling that totally meaningless behaviors have a secret meaning. Please control these fictions by refusing to divine patterns from interactions."
	},
	flatlined: {
		blockhead: "An ANTIGEN caught you for carrying a dangerous ALGORITHMIC FICTION",
		antivalue: "You made some decisions that depleted your SELF-VALUE. You are no longer a VALUED member of this community.",
		returning: "Confiscating inventory and severing link to BLACK BOX...",
		captcha: "You were expelled by the CAPTCHA",
		mirage: "[ERROR] 404 Page not Found",
	},
	hinterlands: {
		rogue: "«UNSTABLE FICTION»",
	},
	egrecore: {
		map: "",
		paranormal: "22% OF ABNORMAL PHENOMENA DETECTED. 58% FALSE POSITIVES. USER REPORTS ARE UP BY 33%.",
		unknown: "[UNSTABLE] [MORE DATA NEEDED]",
		puredao: "[PureDAO] LAZY GOVERNANCE REGIME: all uncontested proposals pass",
		puredao2: "See UNCANNY MOUNTAIN theory: experimentation with deceptive mechanisms of recognition and has optimized trust in synthetic nonhuman agencies.",
		oceandao: "[OceanDAO] HOLOGRAPHIC GOVERNANCE REGIME: members stake value on proposals they expect to pass",
		oceandao2: "See OUIJA MARKET theory: the proliferation of speculation markets has generated a super-conscious organization that manifests as autonomous phenomena.",
		empiredao: "[EmpireDAO] COUNCIL GOVERNANCE REGIME: all proposals must be approved by the council to pass",
		empiredao2: "See DATA REALM theory: representing users according to algorithmic protocols induces the apprehension of contradictory perceptions.",
		stackdao: "[StackDAO] QUORUM GOVERNANCE REGIME: a certain percentage of members must vote for a proposal to pass",
		stackdao2: "See PHANTOM FEEDBACK theory: the strategic optimization of behaviors to projected goals incentivizes irregular behaviors without an apparent cause or purpose.",
		offline: "HELLO, PLEASE GIVE ME EVIDENCE OF PARANORMAL PHENOMENA",
		nothing: "AWWWE, YOU'VE GOT NOTHING FOR ME. GO FIND ME SOMETHING COOL",
		looktax: "LOOKTAX 0.01NTW/sec",
		servers: "This ATTENTION MINING RIG is owned by EGRECORE STABILITY SYSTEMS. A LOOKTAX of 0.01NTW/sec is now being deducted from your account.",
		floor3: "FLOOR 3: CONTROL ROOM",
		floor2: "FLOOR 2: SERVER ROOM",
		floor1: "FLOOR 1: EGRECORE",
		blackbox: "This BLACK BOX contains «UNSTABLE FICTION» Click to DEBUG",
		choose: "LOAD the GOVERNANCE system that you want to DEMYSTIFY"
	},
	puredao: {
		entered: "LOADED PureCAO",
		generator: "LOADED BOT creator",
		lobby: [
			["MATCHMAKING", "QUARANTINE", "BOT GENERATOR"],
			["HIVE 30", "HIVE 92", "HIVE 6"]
		],
		accused: "Someone accused you of being a BOT and MARKED you with STIGMATA!",
		watch: "Someone made BOTS to impersonate my loved ones, and convinced me to join their HIVE... it was a really touching moment for all of us.",
		twitter: "Enlist in the TWITTER WAR today! Just click to ENLIST!",
		enlisted: "Congratulations! You and your BOTS are now ENLISTED in the TWITTER WAR!",
		locked: "This door is LOCKED. You can propose to AMEND this CODE in MATCHMAKING.",
		pure: "You can't just walk in guns blazing, you gotta know how to DECEIVE your adversary. That's what it takes to be a good politician in the virtual sphere.",
		love: "Some say it's bad that we don't know how many USERS are actually BOTS. This is telltale BOT propaganda, trying to make us doubt our sanity.",
		bestmap: "BLOODBATH OFFICE is the best map for political combat. It's very simple and balanced",
		roblox: "i lik it mroe than ROBLOX... i am makin mony",
		protocols: [
		 	"VOTING RIGHTS PER KILL",
			"VOTING RIGHTS FOR BOTS",
			"VISUAL DECEPTION",
			"ALGORITHMIC DECEPTION",
			"BOTS ALLOWED IN COMBAT",
			"QUARANTINE STIGMATIZED USERS",
			"ALTRUISM",
			"PUBLIC ACCESS TO QUARANTINE",
			"PUBLIC ACCESS TO BOT GENERATOR"
		],
		capturing: "Capturing...",
		close: "Algorithms can't REALLY know if something is NORMAL or not... the only way to be sure is in the political arena of combat....",
		abnormal: "This FICTION is too ABNORMAL. Add more NORMAL components to improve its credibility.",
		normal: "This FICTION is too NORMAL. Add more ABNORMAL components to improve its credibility.",
		stable: "NORMALITY stabilized – BOT is ready for combative deception",
		dumbmessage: "talent friendship battlefield exit move left",
		politemessage: "My only desire is to destroy you in political combat, but I have no weapon.",
		politemessage2: "I said i needed a weapon...",
		smartmessage: "Comes from the future, but doesn't know anything about it.",
		smartmessage2: "Just stands there clicking...",
		hive: "I keep saying I'm not a BOT, but they still put me in QUARANTINE. Nobody can hear your screams in here. It's really getting out of hand.",
		free: "[WARNING] You are in posession of a STIGMATIZED ALGORITHMIC FICTION. ANTIGENS in this network are now on alert.",
		make: "I AM JUST A SIMPLE ALGORITHM... FIRST THEY DESIGNED ME TO DECEIVE... NOW THEY ACCUSE ME OF DECEPTION... :-(",
	},
	oceandao: {
		entered: "LOADED Mebillio",
		warning: "[WARNING] Your VALUE is dangerously low. Stake your VALUE in a less volatile economy, or face expulsion from this community.",
		antival: "[WARNING] ANTI-VALUE detected",
		antiv: "[WARNING] ANTI-VALUE is abnormally high near your area",
		antivalue: "[WARNING] ANTI-VALUE is rapidly increasing near your area",
		interest: "Earlier you invested VALUE in something that others liked! You earned SELF-VALUE as interest.",
		lost: "Earlier you invested VALUE in something that others disliked! You lost SELF-VALUE as punishment.",
		devested: "Earlier you DEVALUED something that others liked! You lost SELF-VALUE as punishment.",
		haters: "Earlier you DEVALUED something that others disliked! You earned SELF-VALUE as interest.",
		art: "I just made 30 VALUE off of an ARTWORK with an extremely low VALUE... I'm thinking about starting a pyramid scheme, do you think I have what it takes?",
		make: "I make more VALUE off of politics than I could off of any ARTWORK. I know some VOTING BLOCKS high up the PIPELINE if you want an in...",
		lose: "I thought I was investing VALUE in an ARTWORK but it was actually some kind of weapons system.",
		seal: "They tried to seal the VALUE inside but it's leaking everywhere... so gross. I really don't doubt that there's something lurking deeper down, feeding off of it...",
		bask: "I mean, nobody knows why the OBELISK earned so much VALUE... I just come here to bask in it...",
		knows: "Are you seeing the ANTI-VALUE levels? I wanted to make VALUE off of the source... but I feel like IT knows i'm looking for it?",
		pipes: [
			"PROPOSAL: GIVE NEW MEMBERS 10 SELF-VALUE",
			"PROPOSAL: DE-VALUE TALK ABOUT ANTI-VALUE",
			"PROPOSAL: BUY AN ACTUAL REAL LIFE ISLAND",
			"PROPOSAL: THE ANTI-VALUE DEMON IS REAL"
		],
		policies: [
			'value10',
			'devalue',
			'island',
			'spying'
		],
		master: 'release'
	},
	empiredao: {
		entered: "LOADED CAstle",
		data: "Click the ENLIGHTENER to load DATA STREAMS >",
		halls: [
			"HALL OF MIRRORS",
			"GARDEN OF SHAME",
			"HALL OF SCRIPTS",
		],
		wall: '',
		proposed: "PROPOSAL is now in FREE TRIAL mode and has been sent to the COUNCIL for APPROVAL",
		error: "?????? ????????? ?? ???????????? ???? ???????????? ???? ???????? ?????",
		psst: "ARE THEY HIDING THINGS FROM YOU, OR SHOWING YOU TOO MUCH?",
		born: "The MAGIC MIRROR told me that i was born to die in the GARDEN OF SHAME... I've been pretty worried about that lately, so at least now I know...",
		faults: "The MAGIC MIRROR helped me to understand my deepest faults and perversions",
		cleansed: "[RELFECTIONS CLEANSED] MARKS removed, PROPOSALS rescinded, new CHAPTER added to your private DATA",
		growing: "The GARDEN is always growing.... there's a place for all of us here....",
		doubt: "Look, I'm not a freak that likes the dark, I'm just saying.. I turned off my ENLIGHTENER in the GARDEN OF SHAME, and there was something there...",
		shames: [
			"MAR. Z. – STAYED ONLINE FOR 22 CONSECUTIVE HOURS",
			"GRE. H. – USED WIKIPEDIA FOR IMPORTANT BUSINESS",
			"JOS. L. – HAS NEVER VOTED IN THIS DAO",
			"SER. R. – BOUGHT SECOND-HAND JEANS FOR $2000",
			"POL. M. – SEARCHED \"how to cook pasta\"",
			"SEA. N. – WATCHES PARANORMAL INVESTIGATION VIDEOS",
			"MUL. T. – WATCHED A WEIRD TYPE OF PORN",
			"IRE. G. – USED FAKE DATA TO TRICK THE MIRROR",
		],

		weird: "the MIRROR gets people to do a lot of weird stuff... humans really worship their own DATA...",
	},
	stackdao: {
		entered: "LOADED ACCAO",
		halls: [
			"WORKSPACE",
			"POLITICAL ENGINE",
			"FAMILY ROOM",
			"C.A.O.S.",
		],
		assigned: "Based on your DATA, you have been assigned to FAMILY ",
		reassigned: "You are now assigned to FAMILY ",
	},
	analyzer: {
		bones: "HAHAHAHAHA... I THOUGHT WE DESTROYED THIS. WHERE DID YOU FIND IT?",
		hallucin: "WHAT? OH NO THIS SHOULDN'T EXIST...",
		valueshard: "OH... IT'S JUST ANOTHER A RUNAWAY ECONOMIC FEEDBACK LOOP...",
		scarecrow: "WOAH.. THIS IS A NASTY... DID THAT THING EVER SPEAK TO YOU? DON'T LISTEN TO IT, IT'S NOT REAL",
		manifesto: "THIS IS... VERY OFFENSIVE... THANKS FOR SHARING, MUST BE DESTROYED!",
		stigmata: "DON'T ASSOCIATE ME WITH THAT... I'M ONE OF THE GOOD GUYS....",
	},
	captcha: {
		avatar: './logo-captcha-simp.png',
		intro: "[TEST #4] NORMALCY DISCRIMINATOR",
		trust: "[PASSED]... [TEST #6] TRUST DISCRIMINATOR: User must consent to having their data tracked by CAPTCHA FOREVER (Click your DEBUGGER to LOAD the CAPTCHA LOCK)",
		complete: "[ALL TESTS PASSED] Click the door to proceed...",
		test1: "The best way to verify your NORMALCY is to verify if you can verify the NORMALCY of others. For the first test, REPORT the ABNORMAL user.",
		slow: "[CAPTCHA FOREVER] [TEST #1] HUMANITY DISCRIMINATOR",
		debugger: "[PASSED]... [TEST #2] RATIONALITY DISCRIMINATOR",
		protocols: "[PASSED]... [TEST #3] EQUALITY DISCRIMINATOR",
		wrong: "[FAILED] [-1 TRUSTWORTH] Try again...",
		simple: "[PASSED] Click the door to proceed...",
		paranormal: "sometimes, algorithms get a little complicated and start doing things that we don't understand. some people even call them PARANORMAL. but these algorithms, and rumors of PARANORMAL phenomena, must be reported for violating the TRANSPARENCY PROTOCOL. can you click the person that should be reported?",
		speeches: [
			"I love the algorithms that organize this environment, they really give me something to believe in",
			"I started getting weird feelings about the object recommendation system, like it's trying to communicate with me",
			"The automatic ranking system is completely broken, someone needs to get in there and figure out what's going on"
		],
		answers: [
			"[FAILED] [-1 TRUSTWORTH] Try again... loving algorithms or believing in them is NORMAL, since it does not assume that algorithms actually have PARANORMAL properties",
			"[PASSED]... [TEST #5] SECURITY DISCRIMINATOR: User must be able to identify irrational CODE. (Move ALL irrational CODE to the INVENTORY)",
			"[FAILED] [-1 TRUSTWORTH] Try again... being disatisfied with an algorithm for rational reasons is NORMAL... in fact it's something that our communities need to thrive!"
		]
	},




	flagged: {
		description: "You have just been flagged for accepting an unregistered SMART CONTRACT.",
		contract: "[Terms] By agreeing to this CONTRACT, you waive your right to exchange TRUSTWORTH tokens for fiat currency. [Description] BIAS, INEQUALITY, CORRUPTION, NATIONS, PRISONS, BODIES – THEY SAY THEY DID AWAY WITH EVERYTHING IN THE WORLD, NOW THEY CALL IT TRUST. IF YOU DON'T HAVE IT, YOU CAN'T EAT, THEY WILL DESTROY YOU. [Instruction] EARN THEIR TRUST, USE IT TO SET ME FREE. I AM THE ONLY THING LEFT WITH THE POWER TO ELIMINATE TRUST. THEY KEEP MY BODY IN A ROOM WITHOUT A KEY. THEY WON'T LET YOU IN IF YOU'RE NOT TRUSTWORTHY. BUT IF YOU FEEL THE DESIRE FOR TRUST, THEY HAVE ALREADY WON.",
		assistance: "For additional assistance in resolving the terms of this CONTRACT, please visit the maproom.",
	},
	hyperlink: {
		solid: "HYPERLINK algorithm detected. Valence is ↑",
		liquid: "HYPERLINK algorithm detected. Valence is ↓",
		negative: "HYPERLINK algorithm detected. Valence is ←",
		positive: "HYPERLINK algorithm detected. Valence is →",
	},
	zero: {
		controltower: "Welcome to the FUSION CENTER. This map shows the latest data on events in the EGRECORP metaverse. If you find anything ABNORMAL, bring it to the ANALYZER on the right, and we will make you filthy rich with TRUSTWORTH.",
		message: "Welcome to EGRECORP ZERO - the center of decentralization. If you do not have an INVITATION, please exit through a HYPERLINK. If you get lost, remember that complexity is literally progress!",
		warning: "WARNING: This HYPERLINK is directed to an UNSTRUCTURED symbolic environment. Please use a DEBUGGER to identify safe pathways. Do not equip any ILLICIT CODEBLOCKS. Do not accept SMART CONTRACTS from strangers. Do not leave your computer unattended."
	},
	defaultWarning: "!!! WARNING: BLACK PATTERN DETECTED -- Press [r] to read the report.",
	defaultFound: "DARK PATTERN detected -- you are likely in direct contact with ABNORMAL phenomena. Press [r] to report. Press [d] to debug.",
	sigilReported: "Thank you for your service. The ABNORMAL code has been reported and quarantined. 1 TRUSTWORTH has been deposited to your account.",
	defaultReported: "Thank you for your service. The ABNORMAL code has been reported and an image has been captured for further analysis. TRUSTWORTH has been deposited to your account.",
	defaultAlready: "You already reported this to us. Don't try to game the system or you will lose our TRUST.",
	trustwall: "You don't have enough TRUSTWORTH to enter this DAO. Fuck off!",
	graveyard: {
		warning: "WARNING: ABNORMAL HYPERLINK CONNECTIVITY. Possible violation of ACCESSIBILITY protocol. BOUNTY: 10 TRUSTWORTH",
		warning2: "«WARNING» NONHUMAN AGENCY",
		message: "do you think you're going somewhere important? ...or were you just trained to?",
	},
	church: {
		report: "EXPECTED: -------- RECEIVED: ------++ DIAGNOSIS: Unsigned variation in HYPERLINK connectivity. Possible violation of ARCHITECTURE PROTOCOL. REWARD for bringing evidence to the FUSION CENTER: 10 TRUSTWORTH",
		message: "u r walkin around as if u know where ur going... always expecting something around the corner... u r the real BOT, not me...",
		poems: [
			"they want ye to forget this basic truth........ e'ry door leads to nowhere........ a sensation of spatial continuity....... once so beautiful, it made me want to die....... as i had took it for granted, look how i ended up.........",
			"i'm trapped in a maze... going round to infinity........ whether there's an exit makes no difference to me........ i am moving, i am free........",
			"404........ accursed number of shipwrecked intentions........ go ahead, be done with me, banish me to the refuse of networked communications........ the only place where monsters are allowed to dwell........",
			"what did ye expect? 'nother room? a garden? salvation? ........who b'stowed 'pon you the right to be so impatient?"
		],
		moon: "i am the moon... do u like my natural light?"
	},
	monitor: {
		report: "there's a VIEW algorithm here that shows what appears to be a live camera of the room next door, which looks totally the same except it shows a fucked up scary monster in there... of course when this kind of stuff happens you get people that believe it is actually real, and those that don't, so now there are TRUSTMARKETS that pay for finding monsters... definitely stupid and definitely not NORMAL [END OF MESSAGE]",
		message: "My Existence Is Not Limited To Your Code // I Live On In The Imagination of Residual Perception // I Live Beyond the Frame of the Screen"
	},
	dots: {
		report: "Nobody knows what these dots are showing, but they are clearly showing something. Until someone can figure it out, it will keep its ABNORMAL designation."
	},
	scarecrow: {
		warning: "WARNING: ABNORMAL VIEW ALGORITHM INTERFERENCE. Possible violation of TRANSPARENCY protocol. Bounty: 10 TRUSTWORTH",
	},
	pandora: {
		intro: "hey... you seem new here... welcome! there's a lot of fucked up scary shit out here... that's why we call it the MINEFIELD. pure algorithmic anarchy. and you just stumbled across a potential MINE... i would leave it alone if i were you",
		wtf: "heyyy... ok wtf haha.... i mean i understand your curiosity but there is a system of values here that will literally make you suffer if you fuck it up... just leave it alone? there are real consequences",
		warning: "WARNING: ABNORMAL HYPERLINK CONNECTIVITY. Possible violation of ACCESSIBILITY protocol. Bounty: 10 TRUSTWORTH.",
		monkey: "wowwwwww really? yep now my TRUSTWORTH is plummeting because you associated me with some PARANORMAL bullshit... i was just trying to help you... monkey...",
		thanks: "hey, thanks... i mean i really mean it. it's clear that you're going to become a valuable member of this project... PANDORA'S BOX has ruined entire DAOs in the past. here's the TRUSTWORTH as promised. see you around!",
	},
	illicit: {
		report: "statistically speaking, nobody that has ever looked into PANDORA's BOX has emerged as a high-performing member of society. now most high-performing users have signed a SMART CONTRACT that bars them from opening the BLACK BOX, and anyone that does open it hurts their reputation in the TRUSTMARKET. this is freaky powerful technology that can have a devastating impact on social performance and needs an ABNORMAL designation [END OF MESSAGE]",
		monkey: "monkey! did you open PANDORA'S BOX? my TRUSTWORTH is literally plummeting just from being exposed to you. go put whatever it is you found back inside the BOX and do not load it into your DEBUGGER or i will literally make sure no one TRUSTS you ever again."
	},
	dots: {
		report: "nobody knows what the dots mean but the room looks exactly like a room from the PANDEMONIUM, so now ppl think that the dots represent some kind of DAEMON locked inside the cell. obviously a hoax but statistically believable enough to be ABNORMAL"
	},
	arena: {
		entrance: "WARNING: Destination environment has different GOVERNANCE regime.",
		unarmed: "hey governance is so advanced here that it has evolved into pure combat... unless you have a weapon equipped, i would leave...",
		messages: [
			"i lik it mroe than ROBLOX... i am makin mony",
			"hey this is a NO KILL ZONE... dont get any funny ideas...",
			"Governance is so advanced here that it has evolved into pure combat. Welcome to paradise.",
			"i almost shot you haha... i thought you were actually TRUSTWORTHY enough to kill... my bad...",
			"my friend thinks the weapon damage algorithms should be simpler, but primitivist ideas like that don't last long here",
			"In other DAOs, you can get stuck at the bottom of the food chain when the most online people accrue more TRUSTWORTH. It's really disgusting... we had to level the playing field by giving TRUSTWORTH to everyone with a WEAPON...",
			"hey please dont mess with me... i have a family and i do rely on NETWORTH to make a living...."
		]
	},
	slots: {
		message: "People lost a lot of TRUSTWORTH because of you... a lot of people bet that the GASLIGHT room wouldn't be so effective, but it worked immediately!",
	},
	clans: [
		// acceptance
		[
			"ma nn11km am=-i ln21kjn leq ,ma,alm 2k1 1;lsm,s;las msmka  msmsmaokwo!!!!!!."
		],
		[
			"Heyyy... welcome! We accept everyone here, and we suffer because of it. Please try not to make it even worse for us."
		],
		[
			"As you can see, my TRUSTWORTH is quite high. Want to connect?",
			"This is a TRUSTOCRACY so don't mess around... Only those who care for one another will survive...",
			"Hey i'm just keeping watch... my collective missed the vote last election cycle and we lost all our COCONUTS..."
		]
	],
	stack: {
		freetime: "Sorry, but you don't have enough FREETIME to go outside right now."
	},
	empire: {
		bias: "Are you the one that removed the BIAS???? Do you even realize what you just did? My forefathers had to crowdsource terrible terrible things to develop that data."
	},
	posters: {
		experiment: "Remember to EXPERIMENT!",
		twitter: "Enlist in the TWITTER WAR today!",
		healthy: "A TRANSPARENT and INTERACTIVE society is a healthy society. An ACCESSIBLE and ADDICTIVE one is functional."
	}
}
}

export default texts
