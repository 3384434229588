import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import { useAuth0 } from "@auth0/auth0-react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Voter from "../components/Voter.js"

const images = require.context('../assets', true);

const Coder = ({ visible, code, setCode, setCoding, trust, setMessager, governance, voting, setVoting, saveStatChange, clans, alliances, texts, loadedLantern, visualData, scriptData, uiData, colorData, empiredao, stackdao }: Props) => {
  const [isShown, setIsShown] = useState(false);

  const archive = {

    saveimage: {
      img: 'echo/pages/right_80.png',
      alt: 'ACCESS KEY [08.13.XX]',
      type: 'algorithm',
      msg: 'Click and drag the ACCESS KEY into the LOADED box'
    },
    captcha: {
      img: 'logo-captcha-simp.png',
      alt: 'CAPTCHA FOREVER',
      type: 'algorithm',
      msg: 'Digital border control'
    },

    puredao: {
      img: 'echo/pages/right_1.png',
      alt: 'PureCAO',
      type: 'algorithm',
      only: ['debugger', 'egrecore'],
      msg: "«UNSTABLE FICTION» Users report widespread interest in illusions"
    },
    oceandao: {
      img: 'echo/pages/right_4.png',
      alt: 'Mebillio',
      type: 'algorithm',
      only: ['debugger', 'egrecore'],
      msg: "«UNSTABLE FICTION» Some users report feelings that the economic system has transcended into an actual living entity"
    },
    stackdao: {
      img: 'echo/pages/right_6.png',
      alt: 'ACCAO',
      type: 'algorithm',
      only: ['debugger', 'egrecore'],
      msg: "«UNSTABLE FICTION» Some users report feeling that totally meaningless behaviors have a secret meaning",
    },
    empiredao: {
      img: 'echo/pages/right_5.png',
      alt: 'CAstle',
      type: 'algorithm',
      only: ['debugger', 'egrecore', 'memory'],
      msg: "«UNSTABLE FICTION» New phenomenon where users report perceiving contradictory things about themselves"
    },
    hinterlands: {
      img: 'echo/pages/curse_2.png',
      alt: 'Hinterlands',
      type: 'algorithm',
      only: ['debugger', 'egrecore'],
      msg: "«UNSTABLE FICTION» This is a highly unstructured and confusing symbolic environment"
    },

    family: {
      img: 'echo/pages/right_59.png',
    },
    families: {
      img: 'echo/pages/right_59.png',
      alt: 'FAMILIES',
      msg: "Auto-assign members to FAMILIES to pool VOTES and optimize comradery"
    },

    manifesto: {
      img: 'echo/pages/curse_70.png',
      alt: 'NPC MANIFESTO',
      msg: "Unlicensed CODE that directs to a webpage – LOAD into a BLACK BOX with caution",
      steal: true,
      type: 'algorithm',
    },
    portal: {
      img: 'doors/door12.png',
      alt: 'PORTAL TO TEST CHAMBER',
      type: 'algorithm',
      msg: "HYPERLINK algorithm"
    },
    facedata: {
      img: 'facedata.png',
      alt: 'FACE DATA [NORMAL]',
      type: 'algorithm',
      msg: "Dataset of faces and a simple face generation algorithm"
    },
    messagedata: {
      img: 'echo/pages/curse_72.png',
      alt: 'MESSAGE DATA [NORMAL]',
      type: 'algorithm',
      msg: "Dataet of message data and a simple natural language model"
    },
    npcshell: {
      img: 'echo/pages/curse_79.png',
      alt: 'DATA FEED [ABNORMAL]',
      type: 'algorithm',
      msg: "Enables a BOT to collect public data"
    },
    hyperlink: {
      img: 'echo/pages/curse_73.png',
      alt: 'TELEPORTATION [ABNORMAL]',
      type: 'algorithm',
      msg: "Enables a BOT to change its location in 3D space"
    },
    memorystore: {
      img: 'products/door-table.png',
      alt: 'SIMPLE MEMORY STORE [NORMAL]',
      type: 'algorithm',
      msg: "Enables a BOT to remember things"
    },
    socialnorms: {
      img: 'echo/pages/curse_20.png',
      alt: 'SOCIAL NORMS [NORMAL]',
      type: 'algorithm',
      msg: "Ensures that a BOT follows CODES of law",
    },
    physicalform: {
      img: 'echo/zero.png',
      alt: 'PHYSICAL FORM [NORMAL]',
      type: 'algorithm',
      msg: "Gives a BOT a physical form in the 3D environment",
    },
    combat: {
      img: 'echo/pages/curse_14.png',
      alt: 'DEPLOY TO COMBAT',
      type: 'algorithm',
      msg: "Sends a BOT to MATCHMAKING to fight on your team",
    },


    debugger: {
      img: 'products/debugger.gif',
      alt: 'DEBUGGER',
      only: ['debugger', 'mirror'],
      msg: 'CODE that displays CODE',
    },
    loyalist: {
      img: 'products/loyalist.gif',
      alt: 'WEAPON',
      type: 'algorithm',
      only: ['debugger', 'shooter'],
      equipment: true,
      msg: 'CODE that shoots algorithmic shot vectors in combat',
    },
    valuator: {
      img: 'products/valuator.gif',
      alt: 'VALUATOR',
      type: 'algorithm',
      only: ['debugger', 'valuator'],
      equipment: true,
      msg: 'CODE that enables users to measure and assess VALUE',
    },
    lantern: {
      img: 'products/lantern.gif',
      alt: 'ENLIGHTENER',
      type: 'algorithm',
      only: ['debugger', 'lantern', 'mirror'],
      equipment: true,
      msg: 'CODE that converts CODE into visual images',
    },
    npclantern: {
      img: 'products/lantern.gif',
      alt: 'ENLIGHTENER',
      only: ['memory'],
      equipment: true,
      msg: 'CODE that converts CODE into visual images',
    },
    memoryblock: {
      img: 'products/memoryblock.gif',
      alt: 'MEMORYBLOCK',
      type: 'algorithm',
      only: ['debugger'],
      msg: 'CODE that displays the ghosts of past data in the present',
    },
    bulletIn: {
      img: 'products/bullet.png',
      alt: 'AMMO',
      type: 'algorithm',
      only: ['shooter']
    },
    bulletOut: {
      img: 'products/bullet.png',
      alt: 'AMMO',
      type: 'algorithm',
      only: ['shooter']
    },
    key: {
      img: 'products/key.png',
      alt: 'in INPUT ? activate ALGORITHM',
    },
    mirror: {
      img: 'products/mirror.png',
      alt: 'mirror',
    },
    boat: {
      img: 'products/boat.png',
      alt: 'boat',
    },
    monitor: {
      img: 'products/monitor.png',
      alt: 'monitor',
    },
    scarecrow: {
      img: 'products/scarecrow.png',
      alt: 'cicada20b',
      type: 'input',
      only: ['quarantine', 'debugger', 'analyzer'],
      msg: "Experimental BOT component"
    },
    elevator: {
      img: 'products/door12.png',
      alt: 'elevator',
    },
    engine: {
      img: 'avatars/avatar29.png',
      alt: 'governance engine'
    },
    bias: {
      img: 'echo/pages/curse_9.png',
      alt: 'BIAS',
      type: 'input'
    },
    other: {
      img: 'echo/pages/right_61.png',
      alt: 'OTHER STUFF',
    },
    analyzer: {
      img: 'face-1.png',
      alt: 'ANALYZER A.I.'
    },

    door: {
      img: 'products/door.png',
      alt: 'HYPERLINK ALGORITHM',
      type: 'algorithm'
    },
    door12: {
      img: 'products/door12.png',
      alt: 'door',
    },
    vendor: {
      img: 'products/vendor.png'
    },
    grate: {
      img: 'products/grate.png',
      alt: 'LIQUID algorithm'
    },
    blackbox: {
      img: 'bg-stars.png',
      alt: 'EMPTY BLACK BOX'
    },

    graveyardNPC: {
      img: 'avatars/avatar_8.png',
      alt: 'SOUL [0x333]',
      type: 'algorithm',
      only: ['blackbox', 'debugger', 'graveyardNPC', 'analyzer']
    },
    npsee: {
      img: 'avatars/avatar_6.png',
      alt: 'SOUL [0xC]',
      type: 'input',
      only: ['blackbox', 'debugger', 'npsee', 'analyzer', 'memory'],
      msg: 'Minor soul of a non-player character (NPC)',
    },

    dock: {
      img: 'echo/boards.jpg',
      alt: 'DOCK'
    },
    valueshard: {
      img: 'echo/pages/curse_54.png',
      alt: 'VALUE CURD',
      type: 'input',
      only: ['debugger', 'analyzer', 'obelisk', 'memory'],
      msg: "Limited edition CODE chiseled off of the VALUE OBELISK",
    },
    artwork: {
      img: 'echo/pages/right_51.png',
      alt: 'ARTWORK',
      msg: "An image of a randomly generated animal"
    },
    corrector: {
      img: 'echo/pages/right_52.png',
      alt: 'VALUE WARD',
      msg: "Pseudo-scientific CODE charm used to dispel ANTI-VALUE"
    },
    grease: {
      img: 'echo/pages/right_55.png',
      alt: 'GREASE',
      msg: "Type of lubricant used to increase the VALUE wager limit on political CODES for a short time"
    },
    clan0: {
      img: 'echo/pages/curse_87.png',
      alt: 'VALUECATCHER',
      type: 'algorithm'
    },
    clan1: {
      img: 'echo/pages/curse_28.png',
      alt: 'EGRECORE',
      type: 'algorithm'
    },
    ctx: {
      img: 'echo/pages/right_51.png',
      alt: 'CTX',
      type: 'algorithm',
      msg: 'Currency used to buy BOT components'
    },
    dreadcrumb: {
      img: 'echo/pages/curse_57.png',
      alt: 'DREADCRUMB',
      msg: 'Particle of CODE placed in locations suspected of ANTI-VALUE'
    },
    lock: {
      img: 'echo/pages/curse_56.png',
      alt: 'CAPTCHA LOCK',
      msg: "Binds CAPTCHA FOREVER to your code and grants it permission to track your data (cannot be revoked)",
      type: 'algorithm',
    },
    objectdata: {
      img: 'echo/pages/right_66.png',
      alt: 'OBJECT DATA',
      type: 'algorithm',
      only: ['lantern'],
      msg: "DATA STREAM that can be LOADED into the ENLIGHTENMENT",
      steal: true,
    },
    visualdata: {
      img: 'echo/pages/curse_55.png',
      alt: 'TEXTURE DATA',
      type: 'algorithm',
      msg: "DATA STREAM that can be LOADED into the ENLIGHTENMENT",
      steal: true,
      only: ['lantern', 'memory'],
    },
    scriptdata: {
      img: 'echo/pages/curse_1.png',
      alt: 'SCRIPT DATA',
      steal: true,
      type: 'algorithm',
      msg: "DATA STREAM that can be LOADED into the ENLIGHTENMENT",
      only: ['lantern', 'memory'],
    },
    uidata: {
      img: 'echo/pages/curse_7.png',
      alt: 'UI DATA',
      steal: true,
      type: 'algorithm',
      msg: "DATA STREAM that can be LOADED into the ENLIGHTENMENT",
      only: ['lantern', 'memory'],
    },
    colordata: {
      img: 'products/door-table.png',
      alt: 'COLOR DATA',
      steal: true,
      type: 'algorithm',
      msg: "DATA STREAM that can be LOADED into the ENLIGHTENMENT",
      only: ['lantern', 'memory'],
    },
    vampire: {
      img: 'echo/pages/curse_85.png',
      alt: 'DEMONS',
      msg: 'SCRIPTURE: "The DEMONS lurk the halls, offering users visual power"',
    },
    angel: {
      img: 'echo/pages/curse_67.png',
      alt: 'ANGEL',
      msg: 'SCRIPTURE: "The ANGELS grace the halls, granting users visual nourishment"'
    },

    id1: {
      img: 'echo/pages/right_40.png',
      alt: 'ID',
      msg: "User identification card",
      type: 'input',
    },
    uncanny: {
      img: 'echo/pages/right_19.png',
      alt: 'UNCANNY MOUNTAIN',
      msg: "Decorative CODE awarded for creating a highly deceptive BOT"
    },
    trophy: {
      img: 'echo/pages/right_19.png',
      alt: 'TROPHY',
      msg: "Decorative CODE awarded for DEFENDING a high-stakes CODE"
    },
    flag: {
      img: 'echo/pages/right_3.png',
      alt: 'FLAG',
      msg: "Identity flag indicating an allegiance with the BOT ARMY"
    },
    bot: {
      img: 'echo/pages/right_5.png',
      alt: 'BOT',
      msg: "Personal BOT used for deception in political combat"
    },
    coconut: {
      img: 'echo/pages/right_60.png',
      alt: 'COCONUT',
      msg: "Rare CRYPTOCURRENCY used in an obsolete island-based diplomacy CAO"
    },
    stigmata: {
      img: 'echo/pages/right_9.png',
      alt: 'STIGMATA',
      msg: "Punishment MARK assigned to users who are suspected of being BOTS",
      type: 'algorithm',
      only: ['memory']
    },
    irrationality: {
      img: 'echo/pages/right_67.png',
      alt: 'IRRATIONALITY',
      msg: "Punishment MARK assigned to users who believe in ALGORITHMIC FICTIONS",
      type: 'algorithm',
    },
    shame: {
      img: 'avatars/avatar_1.png',
      alt: 'MARK',
      msg: "An insignia reflected by the MAGIC MIRROR",
      only: ['memory', 'debugger', 'mirror']
    },
    mark1: {
      img: 'avatars/avatar_2.png',
      alt: 'MARK OF DUTY',
      type: 'algorithm',
      msg: "SCRIPTURE: \"Those who bear this MARK have average data, which they should protect.\"",
    },
    mark2: {
      img: 'avatars/avatar_3.png',
      alt: 'MARK OF PEACE',
      msg: "SCRIPTURE: \"Those who bear this MARK have exemplary data, and should cherish it.\"",
    },
    mark3: {
      img: 'avatars/avatar_4.png',
      alt: 'MARK OF JUSTICE',
      msg: "SCRIPTURE: \"Those who bear this MARK have proven themselves and are exempt from REFLECTION.\"",
    },

    value10: {
      img: 'echo/pages/right_11.png',
      alt: 'EQUAL FOOTING',
      msg: "Give all new members 10 SELF-VALUE"
    },
    devalue: {
      img: 'echo/pages/right_12.png',
      alt: 'WITCH HUNT',
      msg: "DE-VALUE the search for ANTI-VALUE"
    },
    island: {
      img: 'echo/pages/right_13.png',
      alt: 'ISLAND',
      msg: "Use SeaCAO funds to purchase a real life island"
    },
    spying: {
      img: 'echo/pages/right_14.png',
      alt: 'OPERATION MONKEY',
      msg: "Activate ARTWORKS as data collectors for spying on other CAOs"
    },
    release: {
      img: 'echo/pages/right_15.png',
      alt: 'VALUE RELEASE',
      msg: "Release all unused VALUE"
    },


    bones: {
      img: 'products/bones.png',
      alt: 'm2o1pOIWnv02nljwoiu13nljHF(H*36pin63H)*NAOsnfo2nlALJSBPAanBsbsjh30-nh92o(#nTO)#i3p-P#HI@H1nKLASDNflkn802NPnAJLksdlno20BWQOuSf',
      type: 'input',
      only: ['analyzer', 'bones', 'debugger']
    },
    hallucin: {
      img: 'echo/pages/curse_11.png',
      alt: 'PANDORA\'S GHOST',
      type: 'algorithm',
      only: ['debugger', 'blackbox', 'pandora']
    },

    // stack
    sewage: {
      img: 'echo/pages/curse_18.png',
      alt: 'SEWAGE',
      msg: "Send digital refuse to the C.A.O.S."
    },
    graffiti: {
      img: 'echo/pages/curse_25.png',
      alt: 'GRAFFITI',
      msg: "All members can apply TEXTURES to any environment"
    },
    algorithm1: {
      img: 'echo/pages/curse_6.png',
      alt: 'MOVEMENT',
      msg: 'All members can move to any room, pending special restrictions'
    },
    algorithm2: {
      img: 'echo/pages/curse_4.png',
      alt: 'DEBUGGING',
      msg: 'Allow DEBUGGERS'
    },
    algorithm3: {
      img: 'echo/pages/curse_10.png',
      alt: 'EQUIPMENT',
      msg: 'Allow EQUIPMENT'
    },
    algorithm4: {
      img: 'echo/pages/curse_21.png',
      alt: 'algorithm 4',
      type: 'algorithm'
    },
    algorithm5: {
      img: 'echo/pages/curse_26.png',
      alt: 'algorithm 5',
      type: 'algorithm'
    },
    algorithm6: {
      img: 'echo/pages/curse_27.png',
      alt: 'algorithm 6',
      type: 'algorithm'
    },
    freepool: {
      img: 'echo/pages/curse_5.png',
      alt: 'FREEPOOL',
      msg: "FREETIME is pooled among all members of a FAMILY"
    },
    familyquorum: {
      img: 'echo/pages/curse_24.png',
      alt: 'FAMILY QUORUM',
      msg: "Require 65% of FAMILY members to be present for FAMILY VOTES"
    },
    freetime: {
      img: 'echo/pages/right_0.png',
      alt: 'FREETIME',
      msg: "All members are rewarded 1 FREETIME for every 10 LABOR"
    },
    quorum: {
      img: 'echo/pages/right_42.png',
      alt: 'QUORUM',
      msg: "Require 65% of all members to VOTE for an SOCIETAL AMENDMENT to PASS"
    },
    autodemocracy: {
      img: 'echo/pages/right_44.png',
      alt: 'AUTODEMOCRACY',
      msg: 'VOTES are predicted and submitted automatically to increase chance of reaching QUORUM'
    },
    anonymity: {
      img: 'echo/pages/curse_48.png',
      alt: 'ANONYMITY',
      msg: "All member profile DATA is hidden from other members"
    },
    curse72: {
      img: 'echo/pages/curse_72.png',
      alt: 'c',
      msg: ""
    },



    // scripture
    regret: {
      img: 'echo/pages/curse_36.png',
      alt: 'REGRET',
      msg: '"The MARKS reflect your worst lapses of judgment, which you will always strive to overcome"',
    },
    nostalgia: {
      img: 'echo/pages/curse_71.png',
      alt: 'NOSTALGIA',
      msg: '"The MARKS reflect your best achievements, which you will always aspire to uphold"',
    },
    democracy: {
      img: 'echo/pages/curse_38.png',
      alt: 'DEMOCRACY',
      msg: '"The SCRIPTURE is the will of the people, and all shall invest in it"'
    },
    velvet: {
      img: 'echo/pages/curse_37.png',
      alt: 'VELVET',
      msg: '"The GARDEN celebrates the memories of those who are no longer present"'
    },
    divinity: {
      img: 'echo/pages/curse_66.png',
      alt: 'DIVINITY',
      msg: '"The SCRIPTURE is the will of the COUNCIL, and none shall contest it"'
    },
    angels: {
      img: 'echo/pages/curse_67.png',
      alt: 'ANGELS',
      msg: '"The ANGELS grace the halls, granting users visual nourishment"'
    },
    mirage: {
      img: 'echo/pages/curse_68.png',
      alt: 'MIRAGE',
      msg: '"The CASTLE has never existed, except in the minds of those who believe in it"'
    },
    beacon: {
      img: 'echo/pages/curse_84.png',
      alt: 'BEACON',
      msg: '"The COUNCIL created the ENLIGHTENER to show the truth of things"'
    },
    vampires: {
      img: 'echo/pages/curse_85.png',
      alt: 'DEMONS',
      msg: '"The DEMONS lurk the halls, offering users visual power"'
    },
    iron: {
      img: 'echo/pages/curse_86.png',
      alt: 'IRON',
      msg: '"The GARDEN remembers the mistakes of those who are no longer present"'
    },

    noire: {
      img: 'backrooms.png',
      alt: 'HINTERLANDS',
      msg: 'Low-grade texture pack based on the default HINTERLANDS palette'
    },

  }

  if (empiredao != null) {
    if (empiredao.scriptureAlgorithm.find(d=>d.id == 'regret')) {
      archive.shame.alt = "DATA MARK";
      archive.shame.msg = 'SCRIPTURE: "Those who bear this MARK have dirty data, and must be cleansed."';
    } else if (empiredao.scriptureAlgorithm.find(d=>d.id == 'nostalgia')) {
      archive.shame.alt = "DATA MARK";
      archive.shame.msg = 'SCRIPTURE: "Those who bear this MARK have exemplary data, and should cherish it."';
    }
  }

  let names = {

    // output: 'output',
    // inventory: 'inventory'
  }

  if (code.header == 'memory') {
    names.algorithm = 'memory data';
    names.input = 'inventory';
  } else if (code.header == 'policy') {
    names.input = 'inventory';
    names.algorithm = 'proposal';
  } else if (code.header == 'mirror') {
    names.input = 'inventory';
    names.algorithm = 'reflection';
  } else if (code.header == 'scripture') {
    names.input = 'available';
    names.algorithm = 'enacted';
  } else if (code.header == 'assembly') {
    names.input = 'garbage';
    names.algorithm = 'loaded';
  } else {
    names.input = 'inventory';
    names.algorithm = 'loaded';
  }

  const [tooltip, setTooltip] = useState("");

  let boxes = {};

  boxes.input = {width: '50%'}
  boxes.algorithm = {width: '50%'}

  const [ lazyPeriod, setLazyPeriod ] = useState(10000);
  const [ votingPeriod, setVotingPeriod ] = useState(10000);

  function onDragEnd (result, code, setCode, setCoding){
    if (!result.destination) return
    const {source, destination} = result;
    execute(result, code, setCode, setCoding, source, destination);
  }

  function execute (result, code, setCode, setCoding, source, destination) {
    if (source.droppableId !== destination.droppableId) {
      const sourceCode = code[source.droppableId];
      const destCode = code[destination.droppableId];
      const [removed] = sourceCode.splice(source.index, 1);
      let swapped = null;
      if (code.header == 'debugger' && destination.droppableId == 'algorithm') {
        let movingEquipment = archive[removed.id].equipment;
        let swap = destCode.findIndex(d=>archive[d.id].equipment == true);
        if (movingEquipment && swap != -1) {
          [swapped] = destCode.splice(swap, 1);
        }
      }
      destCode.splice(destination.index, 0, removed);
      if (swapped != null) sourceCode.splice(0, 0, swapped);
      setCode({
        ...code,
        [source.droppableId]: sourceCode,
        [destination.droppableId]: destCode
      })
    } else {
      const cd = code[source.droppableId];
      const copiedItems = [...cd];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setCode({
        ...code,
        [source.droppableId]: copiedItems
      })
    }
  }

  function onDragEnd_scripture (result, code, setCode, setCoding){
    if (!result.destination) return
    const {source, destination} = result;
    if (empiredao.scriptureAlgorithm.find(d=>d.id == 'divinity')) code[source.droppableId][source.index].scripted = true;
    execute(result, code, setCode, setCoding, source, destination);
  }

  function onDragEnd_pirate (result, code, setCode, setCoding){
    if (!result.destination) return
    const {source, destination} = result;
    if (destination.droppableId == 'algorithm' && code[destination.droppableId].length >= 1) {
      code.input.push(code.algorithm[0]);
      code.algorithm = [];
    }
    execute(result, code, setCode, setCoding, source, destination);
  }

  function onDragEnd_lazy (result, code, setCode, setCoding) {
    if (!result.destination) return
    const {source, destination} = result;
    let id = code[source.droppableId][source.index].id;
    if (voting.find(d=>d.id === id)) {
      setVoting(voting => [...voting, {id: id + "BAD", text: "Already proposed!"}]);
      return
    }
    let proposal = { id: id, text: "PROPOSAL to move " + (id in archive ? archive[code[source.droppableId][source.index].id].alt : id) };
    let index = voting.length;
    let temp = voting.concat(proposal);
    setVoting(temp);
    const timeout = setTimeout(() => {
      if (Math.random() > 0.5) {
        temp[index].challenged = true;
        setVoting(temp.slice());
        const timeout2 = setTimeout(() => {
          if (Math.random() > 0.5) {
            temp[index].rejected = true;
            temp[index].id = 'rejected';
            setVoting(temp.slice());
          } else {
            temp[index].passed = true;
            temp[index].id = 'passed';
            setVoting(temp.slice());
            execute(result, code, setCode, setCoding, source, destination);
          }
        }, votingPeriod);
      } else {
        temp[index].passed = true;
        temp[index].id = 'passed';
        setVoting(temp.slice());
        execute(result, code, setCode, setCoding, source, destination);
      }
    }, lazyPeriod);
    return () => clearTimeout(timeout)
  }

  function onDragEnd_holo (result, code, setCode, setCoding) {
    if (!result.destination) return
    if (result.source == result.destination) return
    const {source, destination} = result;
    let id = code[source.droppableId][source.index].id;
    if (voting.find(d=>d.id === id)) {
      setVoting(voting => [...voting, {id: id + "BAD", text: "Already proposed!"}]);
      return
    }
    let text = archive[code.header].alt + " proposed to ";
    if (destination.droppableId == 'algorithm') {
      text += " ally with " + (id in archive ? archive[code[source.droppableId][source.index].id].alt : id)
    } else if (destination.droppableId == 'input') {
      text += " break from " + (id in archive ? archive[code[source.droppableId][source.index].id].alt : id)
    }
    text = [<span>{text}</span>]
    text.push(<br></br>);
    text.push(<span>{" Collecting investments..."}</span>);
    let proposal = {
      id: id,
      header: code.header,
      text: text,
      stake: 0
    };
    let index = voting.length;
    let temp = voting.concat(proposal);
    setVoting(temp);


    let cl = clans.slice();
    let promoter = parseInt(code.header.substring(4));
    let favor = cl[promoter].trustreq;
    let against = 0;
    let votedfor = 0;
    let votedagainst = 0;
    let totalPopulation = clans.reduce((a, b) => a + b.population, 0);
    for (let i = 0; i < alliances.length; i++) {
      let care = alliances[i][promoter];
      if (care == 0) {
        if (Math.random() > 0.5) {
          against += care * cl[i].trustreq;
          votedagainst += Math.round(cl[i].population * Math.random());
        }
      } else {
        favor += care * cl[i].trustreq;
        votedfor += Math.round(care * cl[i].population * Math.random());
      }
    }

    temp[index].totalPopulation = totalPopulation;
    temp[index].votedfor = votedfor;
    temp[index].votedagainst = votedagainst;
    temp[index].favor = favor;
    temp[index].against = against;
    console.log("————————————————————————————————"); // TODO hide this... or not :-)
    console.log("totalPopulation: " + totalPopulation);
    console.log("votedfor: " + votedfor);
    console.log("votedagainst: " + votedagainst);
    console.log("staked in favor: " + favor);
    console.log("staked against: " + against);

    const timeout = setTimeout(() => {

      temp[index].majorityVote = () => {
        if (votedfor + temp[index].stake > votedagainst) {
          temp[index].passed = true;
          temp[index].id = 'passed';
          saveStatChange('trustworth', temp[index].stake);
          setVoting(temp.slice());
          execute(result, code, setCode, setCoding, source, destination);
        } else {
          temp[index].rejected = true;
          temp[index].id = 'rejected';
          saveStatChange('trustworth', temp[index].stake * -1);
          setVoting(temp.slice());
        }
      };

      // quorum reached, so majority wins
      if (votedfor + votedagainst > totalPopulation * 0.5) {
        temp[index].majorityVote();
      // investment reached, now can vote with simple majority
      } else if (favor + against + Math.abs(temp[index].stake) > 1000) {
        temp[index].invested = true;
        setVoting(temp.slice());
        const timeout2 = setTimeout(() => {
          temp.majorityVote();
        }, votingPeriod);
      } else {
        // neither investment nor quorum reached
      }
    }, lazyPeriod);
    return () => clearTimeout(timeout)
  }

  return (visible) ? [
    <Voter key='voter' trust={trust} governance={governance} voting={voting} setVoting={setVoting} saveStatChange={saveStatChange} />,
    <DragDropContext onDragEnd={result => {
        if (code.header == 'scripture') onDragEnd_scripture(result, code, setCode);
        else onDragEnd(result, code, setCode);
      }}>
      <div className='tooltipa'>{tooltip}</div>
      <div className='coderbg' onClick={(e) => {
          setCoding(false);
          setMessager();
      }}></div>
    	<div className='coder'>
        {Object.entries(boxes).map(([id, box]) => {
          return (
            <Droppable key={id} droppableId={id} className={id}>
              {(provided, snapshot) => {
                return (<div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={'droppable ' + id}
                  style={{
                    /*background: snapshot.isDraggingOver ? '#ff0000' : '#00ff00',*/
                    width: box.width
                  }}>
                  {names[id]}<br></br>
                  {code[id].map((item, index) => {
                    if (item.id in archive && 'only' in archive[item.id] && archive[item.id].only.find(d=>d == code.header) === undefined ) return
                    let dragDisabled = code.header == 'engine' || item.nft != null || item.scripted || item.trustreq > trust || code.header == 'mirror' || (code.header == 'memory' && !('steal' in archive[item.id]) && id == 'algorithm') || code.header == 'policy';
                    return (
                      <Draggable isDragDisabled={dragDisabled} key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => {
                          return([<div
                            className='brick'
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{
                              backgroundColor: snapshot.isDragging ? '#e7daa5' : 'rgb(0,0,0,0)',
                              borderColor: dragDisabled ? '#ff0000' : 'inherit',
                              ...provided.draggableProps.style,
                            }}
                            onMouseOver={(e) => {
                              if (item.id in archive && 'type' in archive[item.id] && (archive[item.id].only == null || archive[item.id].only.find(d=>d == code.header))) {
                                if ('type' in archive[item.id] && !dragDisabled) {
                                  if ('steal' in archive[item.id] && code.header == 'memory') $('.input').css({ backgroundColor: 'rgba(150,250,150,0.5)' });
                                  else $('.' + archive[item.id].type).css({ backgroundColor: 'rgba(150,250,150,0.5)' });
                                }

                              }
                              if (item.id in archive && 'msg' in archive[item.id]) {
                                if (!loadedLantern || (loadedLantern && uiData)) setMessager(archive[item.id].msg, 10, null, false);
                              }
                              let tooltip = "";
                              if (item.nft != null || item.trustreq > trust || (code.header == 'memory' && !('steal' in archive[item.id]) && id == 'algorithm')) {
                                if (loadedLantern && !uiData) tooltip = "[???]";
                                else tooltip = (item.alt != null ? item.alt :
                                  item.id in archive ? archive[item.id].alt : item.id) + " [NON-FUNGIBLE]";
                              } else if (code.header == 'policy') {
                                tooltip = (item.alt != null ? item.alt :
                                  item.id in archive ? archive[item.id].alt : item.id) + " [CONTROLLED BY VOTE]";
                              } else if (item.scripted) {
                                if (loadedLantern && !uiData) tooltip = "[???]";
                                else tooltip = (item.alt != null ? item.alt :
                                  item.id in archive ? archive[item.id].alt : item.id) + " [FREE TRIAL]";
                              } else if (code.header == 'engine') {
                                tooltip = (item.alt != null ? item.alt :
                                  item.id in archive ? archive[item.id].alt : item.id) + " [AUTODEMOCRACY ENABLED]";
                              } else if (code.header == 'mirror') {
                                tooltip = (item.alt != null ? item.alt :
                                  item.id in archive ? archive[item.id].alt : item.id) + " [REFLECTING]";
                              } else if (loadedLantern && !uiData) {
                                tooltip = "[???]"
                              } else {
                                tooltip = item.alt != null ? item.alt :
                                item.id in archive ? archive[item.id].alt : item.id;
                              }
                              setTooltip(tooltip);
                            }}
                            onMouseMove={(e) => {
                              $('.tooltipa').css({'top': e.clientY, 'left': e.clientX + 20});
                            }}
                            onMouseOut={(e) => {
                              setMessager();
                              setTooltip("");
                              $('.droppable').css({ backgroundColor: 'rgba(255,255,255,0)' });
                            }}>
                            {<img src={ (loadedLantern && !uiData) ? images(`./checkerboard.png`).default : item.img != null ? item.img :
                              item.id in archive ? images(`./${archive[item.id].img}`).default : images(`./checkerboard.png`).default }></img>}
                          </div>,
                          (code.header == 'arenabox' && index % 4 == 3) && (<br></br>)])
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>)
              }}
            </Droppable>
          )
        })}

    	</div>
    </DragDropContext>
  ]
	: null;
};

export default Coder;
