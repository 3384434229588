import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import { useAuth0 } from "@auth0/auth0-react";

import Writer from "../components/Writer.js"

import blank from "../assets/valuator.png"
import active from "../assets/valuator-active.png"
import anti from "../assets/valuator-anti.png"
import bar from "../assets/valuator-bar.png"

const Valuator = ({ visible, code, setCode, setMessager, texts, setCoding, value, yourValue, roomValue, stalker, inventory, equipped }: Props) => {

  let val = null;
  if (value != null) val = value;
  else if (roomValue == 'stalker') val = stalker[2];
  else if (roomValue != null) val = roomValue

  return (visible) ?
	<div className='valuator'>
    <div style={{ backgroundColor: yourValue > 5 ? '#e7daa5' : '#d43f3f' }}className='hud'>{yourValue != null ? `SELF-VALUE: ${yourValue}V` : ""}</div>
		<div className='image'>
      {<div className='ammotip' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>{val == null ? "..." : val + "V"}</div>}
      <img
        onMouseOver={() => {
          document.body.style.cursor = "context-menu";
          setCode({
            ...code,
            'header': 'valuator',
            'input': inventory || [],
            'algorithm': equipped || []
          });
          setMessager(texts.ui.valuator, 10);
        }}
        onMouseOut={() => {
          document.body.style.cursor = "default";
          setMessager();
        }}
        onClick={() => {
          setCoding(true);
        }}
        style={{  position: 'absolute', zIndex: '950', right: '50px',
          bottom: '0' }}
        src={ val == null ? blank : (val >= 0 ? active : anti) }>
      </img>
      <img style={{ bottom: (((Math.abs(val) > 336 ? 336 : Math.abs(val)) / 4) - 15) + 'px' }} className='bar' src={bar}></img>
		</div>
	</div>
	: null;
};

export default Valuator;
