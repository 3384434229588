import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets/', true);

const Gatekeeper = () => {

  const [ language, setLanguage ] = useGetAndSet('language');
  const [ message, setMessage ] = useState("");
  const [coding, setCoding] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [code, setCode] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });

  useEffect(() => {
    if (!coding) return
    if (code.algorithm.length == 2) {
      setCoding(false);
    }
  }, [code]);

  function addPlugin() {
    setEquipped(equipped.concat({id: 'debugger', trustreq: 99999}));
    setInventory(inventory.concat({id: 'saveimage'}));
    setTimeout(() => {
      setMessage("< Please click the DEBUGGER to load an ACCESS KEY", 10);
    }, 1000);
  }

  let debugging = equipped.find(d=>d.id == 'debugger');
  let loaded = equipped.find(d=>d.id == 'saveimage');

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} message={message} setMessager={setMessage} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    (<div className='egrecorp intro'>
      {<div>
        <img className='alertimage' src={images('./face-2.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"WAIT!"} /></u>
        <Writer delay={1000} speed={10} msg={"ALGORITHMIC FICTION in this governance environment is UNSTABLE"}/><br></br>
        <div className='alertheader'><Writer delay={3000} speed={30} msg={"ALGORITHMIC FICTION may cause creepy feelings, mystical beliefs, and «paranormal» perceptions that appear to go beyond the CODE that created it".toUpperCase()}/>{/*<Delayer delay={8000}>See <NavLink to='exorcism'>EXECUTIVE ORDER «EXORCISM»</NavLink></Delayer>*/}</div><br></br>
      </div>}
      {!debugging && !loaded && (
        <div>
        <Writer delay={10000} speed={10} msg={"Please use a DEBUGGER to reveal the truth of CODE and dispel its disgusting mystifications:"}/><br></br>
        <Delayer delay={12000}>
          <div className='plugin'>
            <img src={ images(`./products/debugger.gif`).default }/>
            <div style={{ textAlign: 'left' }}>
              <div className='title'>{"DEBUGGER v4.1.2"}</div>
              <div className='title'>{"Demystification utility"}</div><br></br>
              <a className='add' onClick={ addPlugin.bind(this) }>{"Click to add CODE"}</a><br></br>
              <img className='pluginlogo' src={ images(`./logo-egrecorp.png`).default }/>
            </div>
          </div>
        </Delayer>
        </div>
      )}
      {debugging && !loaded && (
        <div>
        <Writer delay={0} speed={10} msg={"DEBUGGER added"}/><br></br>
        </div>
      )}
      {loaded && (
        <div>
          <Writer delay={0} speed={10} msg={"ACCESS KEY loaded, nice..."}/><br></br>
          <Writer delay={1000} speed={10} msg={"Starting ECHO sequence with coordinates 0,0,0"}/><br></br>
          <Delayer delay={6000}> <Redirect to={{ pathname: 'echo', state: {room:[0, 0, 'egrecore']} }} /> </Delayer>
        </div>
      )}

    </div>)

  ]);

};

export default Gatekeeper;
