import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import useKeypress from 'react-use-keypress';

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

let lightning = 0;
const threshold = 4;

const Graveyard = ({ images, setMessage, setAvatar, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, setWait, setMessager, saveStatChange, addAffiliation, affiliations, seeds, texts, setCoding, inventory, equipped, setEquipped, loadedBubble, empiredao, setEmpiredao, visualData }) => {

	// const [ lighting, setLighting ] = useState(0);
	//
	// useFrame(() => {
	// 	lightning += Math.random() - 0.5;
	// 	lightning = Math.abs(lightning);
	// 	if (lightning > threshold - 1) setLighting(lightning)
	// 	if (lighting > threshold + 3) lightning -= 0.3;
	// });

	// useEffect(() => {
	// 	if (lighting > threshold - 0.5 && !coding) {
	// 		setCode({
	// 			...code,
	// 			'header': 'graveyard',
	// 			'input': [],
	// 			'algorithm': [{id: 'door'}],
	// 			'output': [],
	// 		});
	// 	} else if (!coding) {
	// 		document.body.style.cursor = "default";
	// 		if (code.algorithm.length != 0) setCode({
	// 			...code,
	// 			'header': 'graveyard',
	// 			'input': [],
	// 			'algorithm': [],
	// 			'output': [],
	// 		});
	// 	}
	// }, [lighting]);


				// {lighting > threshold - 0.5 && <pointLight intensity={1}></pointLight>}
				/*lighting > threshold -0.5 &&*/

	let tombstones = [];
	for (let i = 0; i < texts.church.poems.length; i++) {
		tombstones.push(<Image
      material='phong'
			image={images(visualData ? `./doors/door7.png`: `./checkerboard.png`).default}
			onOver={(e) => {
        document.body.style.cursor = "help";
        setCode({
          ...code,
          header: 'memory', input: inventory,
          algorithm: [{id: 'shame'}],
        });
        setMessager("", 10, null, false, texts.ui.remember);
      }}
      onClick={(e) => {
        setCoding(true);
        setMessager(texts.ui.tombstone, 10, null, true);
      }}
      onOut={(e) => {
        document.body.style.cursor = "default";
        setMessager();
      }}
			repeat={false}
			position={[10 + (i/2) * 20, -5, -20 - ((texts.empiredao.shames.length - i)%2)*10]}
			scale={[3,3.5,2]}
			rotation={[-0.2 + seeds[i*3] * 0.4, 0 + seeds[i*3+1] * -0.4, -0.2 + seeds[i*3+2] * 0.4]}/>);
	}

	return (<group>

		<Suspense  fallback={<Loader/>}>
			{<Image
				material='phong'
				image={ images(visualData ? `./avatars/building4.png`: `./checkerboard.png`).default }
				scale={[1.5,3,1]}
				position={[0, 0, -7]}
				onMove={() => {
					document.body.style.cursor = 'grab';
					setMessager("", 10, null, false, texts.ui.cleanse);
				}}
				onOut={() => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={() => {
					document.body.style.cursor = 'grabbing';
					setMessager(texts.empiredao.cleansed, 10, null, true);
					let temp = equipped.slice().filter(d=>d.reflection == null);
					setEquipped(temp);
					setEmpiredao({
						...empiredao,
						scriptureInput: [{id: 'divinity'},  {id: 'nostalgia'},  {id: 'velvet'}, {id: 'mirage'}, {id: 'vampires'}],
					  scriptureAlgorithm: [{id: 'regret'}, {id: 'iron'}, {id: 'beacon'}, {id: 'democracy'}, {id: 'angels'}],
					});
				}}
				/>}
			{<Image
				geometry={<cylinderGeometry/>}
				material='phong'
				image={ images(visualData ? `./brick.jpg`: `./checkerboard.png`).default }
				repeat={true}
				scale={[1,2,1]}
				position={[0, -2, -7]}
				/>}
			<Image
				key='moon'
				geometry={<sphereGeometry></sphereGeometry>}
				image={images(visualData ? `./moon.png`: `./checkerboard.png`).default}
				repeat={false}
				material='lambert'
				position={[-35, 25, -45]}
				scale={5}
				onOver={(e) => {
					document.body.style.cursor = "help";
					setCode({
						...code,
						header: 'memory',
						input: inventory,
						algorithm: [],
					});
					setMessager(texts.graveyard.warning2, 10, null, false, texts.ui.debug);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.npc, 10, null, true);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				rotation={[0, 0, 0]} />

			{ <group>
			{empiredao.scriptureAlgorithm.find(d=>d.id == 'vampires') && <NPC
				scale={2}
				position={[5, 0, -20]}
				rotation={[0, -Math.PI/1.8, 0]}
				image={images(visualData ? `./avatars/avatar_8.png`: `./checkerboard.png`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					setCode({
						...code,
						header: 'memory',
						input: inventory,
						algorithm: empiredao.graveyardNPC,
					});
					setMessager("", 10, null, false, texts.ui.debug);
				}}
				onClick={(e) => {
					setCoding(true);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>}

		{tombstones}

				{<mesh
					position={[0, -10, -25]}
					scale={68}
					rotation={[-Math.PI / 2, 0, 0]}>
					<planeBufferGeometry/>
					<meshPhongMaterial color='#000'/>
				</mesh>}
			</group>}


				{<Image
					material='phong'
					image={images(visualData ? `./doors/door24.png`: `./checkerboard.png`).default}
					repeat={false}
					onMove={(e) => {
						document.body.style.cursor = "nw-resize";
						setMessager("", 10, null, false, texts.ui.exit);
	        }}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						setRoomX(roomX - 1);
					}}
					position={[-20, -3, -30]}
					scale={20}
					rotation={[0, Math.PI / 8, 0]} />}

		</Suspense>


	</group>)
}

export default React.memo(Graveyard);
