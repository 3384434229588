import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, setRedirect, equipped, readValue }) => {

	right = true;
	left = true;

	let doors = [];
	if (up) {
		doors.push(<Image
			image={ images(`./doors/door-sub1.png`).default }
			material='phong'
			repeat={false}
			position={[0, -3, -20]}
			scale={[7, 10, 1]}
			rotation={[0, 0, 0]}
			onOver={(e) => {
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomY(roomY + 1);
			}}
			/>);
	}
	if (right) {
		doors.push(<Image
			image={ images(`./doors/door-sub2.png`).default }
			material='phong'
			repeat={false}
			position={[15, -3, -15]}
			scale={[7, 10, 1]}
			rotation={[0, -Math.PI/3, 0]}
			onOver={(e) => {
				document.body.style.cursor = "e-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX + 1);
			}}
			/>);
	}
	if (left) {
		doors.push(<Image
			image={ images(`./doors/door-sub1.png`).default }
			material='phong'
			repeat={false}
			position={[-15, -3, -15]}
			scale={[7, 10, 1]}
			rotation={[0, Math.PI/3, 0]}
			onOver={(e) => {
				document.body.style.cursor = "w-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX - 1);
			}}
			/>);
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./echo/pages/right_62.png`).default }
				material='phong'
				repeat={false}
				position={[0, 0, -20]}
				scale={[7, 10, 1]}
				onOver={() => {
					readValue('painting1');
				}}
				onOut={() => {
					readValue();
				}}
				onClick={() => {
					readValue('painting1', 1);
				}}
				rotation={[0, 0, 0]} />
			<Image
				image={ images(`./echo/porthole1.png`).default }
				material='phong'
				repeat={false}
				position={[-8, 0, -20]}
				scale={3}
				rotation={[0, -Math.PI/20, 0]} />
			<Image
				image={ images(`./echo/porthole1.png`).default }
				material='phong'
				repeat={false}
				position={[8, 0, -20]}
				scale={3}
				rotation={[0, Math.PI/20, 0]} />
			{doors}
			</Suspense>


	</group>)
}

export default React.memo(Room);
