import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Lab = ({ images, setMessager, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, setCoding, seeds, texts, inventory, family, plainroom }) => {

	const [ pos, setPos ] = useState([0, 0]);
	const [ textures, setTextures ] = useState([]);
	const [ applied, setApplied ] = useState([]);

	useEffect(() => {
		let available = [];
		if (family.x > 0.9) {
		}
		if (family.x > 0.8) {
		}
		available.push({id: 'noire'});
		setTextures(available);
	}, []);


	let members = [];
	for (let i = 0; i < Math.round(seeds[19] * 5); i++) {
		members.push(<NPC
			scale={1.5}
			offset={seeds[i+4] * 2}
			position={[seeds[i] * 20 - 10, seeds[i+1] * 20 - 10, seeds[i+2] * 20 - 30]}
			rotation={[0, -Math.PI/3, 0]}
			image={images(`./scanline.png`).default}
			geometry={<sphereGeometry attach="geometry" />}
			material='phongwireframe'
			onOver={(e) => {
				document.body.style.cursor = "help";
				setCode({
					...code,
					header: 'memory',
					input: inventory,
					algorithm: [{id: 'captcha'}, {id: 'family', alt: 'FAMILY ' + family.name.toUpperCase(), nft: true}],
				});
				setMessager("", 10, null, false, texts.ui.remember);
			}}
			onClick={(e) => {
				setCoding(true);
				setMessager(texts.ui.remembering, 10, null, true);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
		/>);
	}


	return (<group
			position={[pos[0], 0, pos[1]]}
			>
			<Suspense fallback={<Loader/>}>
				{members}

			{applied.length == 0 && <mesh scale={10} position={[0, 0, -5]}>
				<boxBufferGeometry args={[1, 1, 2]} />
      	<meshNormalMaterial wireframe />
			</mesh>}

			{applied.length > 0 && applied[0].id == 'noire' && plainroom}

			{applied.length == 0 && <mesh scale={1} position={[0, 0, -5]}
				onPointerOver={(e) => {
					document.body.style.cursor = 'context-menu';
					setCode({
						...code,
						header: 'blackbox',
						input: textures,
						algorithm: applied,
					});
					setMessager(texts.ui.blackbox, 10, null, false, texts.ui.debug);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setCoding(true);
				}}
				>
				<boxBufferGeometry args={[1, 1, 2]} />
      	<meshNormalMaterial wireframe />
			</mesh>}

			{applied.length > 0 && applied[0].id == 'noire' && <Image
				scale={4}
				rotation={[0, Math.PI * (seeds[19] - 0.9) * 20, 0]}
				position={[0, -5, -20]}
				image={images(`./bg-stars.png`).default}
				geometry={<boxGeometry attach="geometry" />}
				onOver={(e) => {
					document.body.style.cursor = 'context-menu';
					setCode({
						...code,
						header: 'blackbox',
						input: textures,
						algorithm: applied,
					});
					setMessager(texts.ui.blackbox, 10, null, false, texts.ui.debug);
				}}
				onOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setCoding(true);
				}} />}

			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerOver={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setRoomX(roomX - 1);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>

			</Suspense>
	</group>)
}

export default React.memo(Lab);
