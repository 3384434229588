import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));

  return (
      <Plane args={[100, 100]} ref={ref}>
        <meshBasicMaterial opacity={0} transparent />
      </Plane>
  );
}

const desirables = [4,57,11];

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setRoomZ, setWait, roomX, roomY, setHeaded, islands, clans, alliances, texts }) => {

  useEffect(() => {
		setMessager(texts.report.oceandao, 30, null, true);
	}, []);

//25,27,36
//29 digital
	return (<group>

		<Suspense fallback={<Loader/>}>
      <Image
				key={'floor'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				textureRepeat={4}
				repeat={true}
				position={[0, -7, -40]}
				scale={[5, 60, 3]}
        geometry={<cylinderGeometry></cylinderGeometry>}
				rotation={[0, 0, 0]} />
      <Image
				key={'floor'}
				image={ images(`./echo/boards.jpg`).default }
				material='phong'
				textureRepeat={4}
				repeat={true}
				position={[0, -7, -10]}
				scale={[5, 60, 3]}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'skybox'}
				image={ images(`./echo/pages/curse_${2}.png`).default }
				geometry={<sphereGeometry></sphereGeometry>}
				material='phong'
				repeat={false}
				repeatTexture={false}
				position={[0, 0, -100]}
				scale={600}
				rotation={[Math.PI/8, 0, 0]} />
			<Image
				key='floor'
				image={ images(`./echo/pages/curse_${40}.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={1}
				position={[0, -8, -10]}
				scale={[300, 150, 1]}
				rotation={[-Math.PI / 2, 0, 0]} />
      <Image
        image={ images(`./doors/door3.png`).default }
        repeat={false}
        position={[0, -4, -35]}
        scale={7}
        rotation={[0, 0, 0]}
        onOver={() => {
          document.body.style.cursor = "n-resize";
          setMessager("", 10, null, false, texts.ui.enter);
        }}
        onOut={() => {
          document.body.style.cursor = "default";
          setMessager();
        }}
        onClick={() => {
          setRoomY(roomY + 1);
        }}
        />
			</Suspense>

	</group>)
}

export default React.memo(Room);
