import React, { useState, useEffect } from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import Writer from "../components/Writer.js"

import handgun from "../assets/handgun.png"
import handgunShot from "../assets/handgun-shot.png"
import handgunFlare from "../assets/handgun-flare.png"

const Shooter = ({ shooting, clip, setClip, setMessager, code, setCode, visible, setCoding, texts, hud }: Props) => {
  function doNothing() {}
  setClip = setClip || doNothing;
  const [isShown, setIsShown] = useState(false);
	const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (visible) ?
	<div className='shooter'>
    <div className='hud'>{hud ? `AMENDERS: 0 DEFENDERS: 0` : ""}</div>
		<div className='image'>
      {<div className='ammotip' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>{clip > 0 ? clip : "DEBUG TO RELOAD"}</div>}
      <img
        onMouseOver={() => {
          document.body.style.cursor = "context-menu";
          let algorithm = [{id: 'loyalist'}]
          if (clip > 0) algorithm.push({id: 'bulletIn'});
          setCode({
            ...code,
            'header': 'shooter',
            'input': clip < 8 ? [{id: 'bulletOut', content: 'bullet'}] : [],
            'algorithm': algorithm
          });
          setMessager(texts.ui.shooter, 10);
        }}
        onMouseOut={() => {
          document.body.style.cursor = "default";
          setMessager("");
        }}
        onClick={() => {
          setCoding(true);
        }}
        style={{  position: 'absolute', zIndex: '950', right: '50px',
          bottom: '0', transform: shooting ? 'translate(' + (Math.random() * 10) + 'px) rotate(' + (Math.random() * 5) +'deg)' : '' }} src={ shooting ? handgunShot : handgun }>
      </img>
    {(shooting) && (<img style={{ position: 'absolute', bottom: '-10px', right: '85px', zIndex: '930', transform: 'rotate(' + (Math.random() * 360) +'deg)' }} src={handgunFlare}></img>)}
		</div>
	</div>
	: null;
};

export default Shooter;
