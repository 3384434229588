import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue, setCoding, inventory, obelisk }) => {

	let doors = [];
	let windows = [];
	for (var i = 0; i < 2; i++) {
		for (var j = 0; j < 2; j++) {
			windows.push(<Image
				image={ images(`./echo/porthole1.png`).default }
				material='phong'
				repeat={false}
				position={[-20 + (j * 40), 10, -40 + i * 20]}
				scale={3}
				rotation={[0, -j * -Math.PI/20, 0]} />);
		}
	}
	doors.push(<Image
		image={ images(`./echo/porthole2.png`).default }
		material='phong'
		repeat={false}
		position={[0, 10, -60]}
		scale={10}
		rotation={[-Math.PI/8, 0, 0]}
		/>);
	doors.push(<NPC
		image={ images(`./echo/obelisk.png`).default }
		geometry={<boxGeometry></boxGeometry>}
		material='phong'
		position={[0, 10, -30]}
		scale={[5, 10, 5]}
		rotation={[0, 0, 0]}
		onOver={(e) => {
			document.body.style.cursor = "help";
			readValue('obelisk');
			setMessager("", 10, null, false, texts.ui.debug);
			setCode({
				...code,
				header: 'obelisk',
				input: inventory,
				algorithm: obelisk
			});
		}}
		onOut={(e) => {
			readValue();
			document.body.style.cursor = "default";
			setMessager();
		}}
		onClick={(e) => {
			setCoding(true);
		}}
		/>);

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./echo/porthole4.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.enter);
					readValue(11);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
					readValue();
				}}
				onClick={(e) => {
					readValue();
					setRoomZ(roomZ - 1);
				}}
				material='phong'
				repeat={false}
				position={[0, -3, -10]}
				scale={4}
				rotation={[0, 0, Math.PI/4]} />
			{doors}
			{windows}
			{face != null && speech != null && <NPC
				scale={1.5}
				position={position == null ? [0, 0, -20] : position}
				rotation={[0, -Math.PI/2 + (position == null ? 0 : -position[0]*Math.PI/100), 0]}
				image={images(`./enemy/face${face}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setMessager(speech, 40, images(`./enemy/face${face}.jpg`).default, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>}
			<NPC
				scale={1.5}
				position={position == null ? [-10, 0, -20] : position}
				rotation={[0, -Math.PI/2 + -(-10)*Math.PI/100, 0]}
				image={images(`./enemy/face${25}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setCode({
						...code,
						header: 'memory', input: [],
						algorithm: [{id: 'valueshard'}],
					});
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.remembering, 10, null, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>
			</Suspense>


	</group>)
}

export default React.memo(Room);
