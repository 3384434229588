import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef, forwardRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import $ from 'jquery';
import useKeypress from 'react-use-keypress';
import texts from "../data/texts.js"

import axios from "axios";

import * as THREE from 'three'
import { Box, Plane, Loader } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame, extend } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { EffectComposer, Noise, Pixelation, Glitch, GlitchMode, Scanline, Sepia, DotScreen } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import CameraControls from 'camera-controls';

import Image from "../components/Image.js";
import Delayer from "../components/Delayer.js";
import Writer from "../components/Writer.js";
import AnimatedImage from "../components/AnimatedImage.js";
import AnimatedGroup from "../components/AnimatedGroup.js";
import Offline from "./Offline.js";

CameraControls.install({ THREE })
extend({ CameraControls })

function Controls({ freedom }) {
  const ref = useRef()
  const camera = useThree((state) => state.camera)
  const gl = useThree((state) => state.gl)
  useFrame((state, delta) => {
    ref.current.azimuthAngle = -state.mouse.x * freedom;
    ref.current.polarAngle = Math.PI / 2 + state.mouse.y * freedom;
    ref.current.update(delta)
  })
  return <cameraControls minDistance={1} maxDistance={1} azimuthRotateSpeed={-0.3} polarRotateSpeed={-0.3} truckSpeed={0} ref={ref} args={[camera, gl.domElement]} />
}

const images = require.context('../assets', true);

const Echo = (props) => {

  const [ equipped, setEquipped ] = useGetAndSet('equipped');
  const [ redirect, setRedirect ] = useState(null);
  const [ start, setStart ] = useState(false);
  const [ tooltip, setTooltip ] = useState("");

  let debugging = equipped.find(d=>d.id == 'debugger');
  let loaded = equipped.find(d=>d.id == 'saveimage');

  let grid = [
    <AnimatedImage
      image={ images('./checkerboard.png').default }
      geometry={ <planeBufferGeometry args={[1,1,10,10]} /> }
      material='wireframe'
      scale={10}
      rotation={[-Math.PI/2, 0, 0]}
    />
  ];

  grid.push(<mesh scale={[10,2,1]}>
    <planeBufferGeometry args={[1,1,10,10]} />
    <meshBasicMaterial wireframe color='#00ff00' />
  </mesh>);

  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 2; j++) {
      grid.push(<Delayer delay={3000}><mesh position={[i*6-3, 0, j*6-3]}>
        <sphereGeometry/>
        <meshBasicMaterial wireframe color='#ff0000' />
      </mesh></Delayer>);
    }
  }

  for (let i = 0; i < 30; i++) {
    grid.push(<AnimatedImage
      image={ images('./stigmata.png').default }
      geometry={ <planeBufferGeometry args={[1,1,10,10]} /> }
      material='phong'
      position={[Math.random() * 10 - 5, 0, Math.random() * 10 - 5]}
      scale={debugging ? 0.5 : 0}
      rotation={[0, 0, 0]}
      animRotation={[0, -0.002, 0]}
      animEase={[0, Math.random() * 3, 0]}
    />);
  }

  if (loaded) grid.push(<AnimatedImage
    image={ images(`./echo/reticule.png`).default }
    repeat={false}
    material='phong'
    position={[0, 2, 0]}
    scale={3}
    animRotation={[0, 0.02, 0.02]}
    rotation={[0, 0, 0]} />);

  let group = <AnimatedGroup contents={grid} animRotation={[0, 0.002, 0]} position={[0, -2, -10]} />

  return ([
    start && <Offline />,
    <div className='tooltip0'>{tooltip}</div>,
    <div className='echo' onMouseMove={(e) => {
      $('.tooltip0').css({'top': e.clientY, 'left': e.clientX + 20});
    }}>
      {redirect}
      {!start && <div className='scroll' style={{ top: '20px' }}>CODE IS LAW</div>}
      {!start && <div className='scroll' style={{ bottom: '20px' }}>WELCOME TO PARADISE</div>}
      {!start && <div className='arrows'><img src={ images('./arrows.png').default }></img></div>}
      {start && !debugging && <Delayer style={{ zIndex: '999' }} delay={3000}><div className='legend' style={{ top: '48vh', color: !debugging ? '#ff2e00' : '#ccb49d', filter: 'none !important' }}><Writer msg="HIGH FICTION ZONES"/></div></Delayer>}
      {debugging && <div className='legend' style={{ top: '48vh', color: '#ccb49d', filter: 'none !important' }}><Writer msg="FICTION REPORTS"/></div>}
      <Canvas className='threejs' gl={{antialias:false}} camera={{ position: [0, 0, 1], near: 0.1, far: 1000 }}>

        <Suspense fallback={null}>

        <mesh scale={[20,20,1]} position={[0, 2, -30]} rotation={[0, 0, 0]}
          onPointerOver={() => {
            if (start) return
            document.body.style.cursor = 'n-resize';
            setTooltip("ENTER");
          }}
          onPointerOut={() => {
            if (start) return
            document.body.style.cursor = 'default';
            setTooltip("");
          }}
          onClick={() => {
            document.body.style.cursor = 'default';
            setTooltip("");
            setStart(true);
          }}
          >
          <planeBufferGeometry/>
          <meshBasicMaterial color='#000'/>
        </mesh>
        <mesh scale={[50,50,1]} position={[0, -2, -30]} rotation={[-Math.PI/2, 0, 0]}>
          <planeBufferGeometry/>
          <meshPhongMaterial color='#333'/>
        </mesh>
        <mesh scale={[50,50,1]} position={[0, -2, 20]} rotation={[-Math.PI/2, 0, 0]}>
          <planeBufferGeometry/>
          <meshPhongMaterial color='#333'/>
        </mesh>

        {start && group}
        {start && <pointLight intensity={1} position={[0, 5, 0]} />}

        </Suspense>

        {debugging && <EffectComposer>
          <Noise blendFunction={BlendFunction.OVERLAY} />
          <Sepia />
          <DotScreen scale={1} />
        </EffectComposer>}

        <Controls freedom={0.1} />

      <pointLight intensity={1} position={[0, 0, -50]} />

      </Canvas>

  </div>
]);

};

export default React.memo(Echo);
