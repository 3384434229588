import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));

  return (
      <Plane args={[100, 100]} ref={ref}>
        <meshBasicMaterial opacity={0} transparent />
      </Plane>
  );
}

const Arena = ({ images, setMessage, setAvatar, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, seeds, armed, setWait, saveStatChange }) => {

	let enemies = [];
	for (var i = 0; i < 3; i++) {
		enemies.push(<Enemy spawn={[
			seeds[1 + i] * 40 - 20,
			1,
			seeds[8 + i] * -50
		]}
		image={images(`./avatars/arena${Math.floor(1 + seeds[20 - i] * 8)}.png`).default }
		key={'enemy' + seeds[10]}
		material='phong'
		armed={armed}
		doGlitch={doGlitch} stopGlitch={stopGlitch} clip={clip} setAvatar={setAvatar} setSpeed={setSpeed} setMessage={setMessage} setRoomX={setRoomX} setRoomY={setRoomY} setWait={setWait} saveStatChange={saveStatChange} />);
	}

	return (<group>
			<Suspense fallback={<Loader/>}>

				<Image
					image={ images(`./echo/pages/curse_${Math.floor(seeds[3] * 20) + 40}.png`).default }
					onOver={(e) => {
						document.body.style.cursor = "pointer";
						setMessage("HYPERLINK algorithm detected. Valence is SOLID.");
						setAvatar(null);
					}}
					onOut={(e) => document.body.style.cursor = "default"}
					onClick={(e) => {
						if (document.body.style.cursor != "crosshair") setRoomY(roomY + 1);
					}}
					material='phong'
					position={[0, 0, -50]}
					rotation={[0, 0, 0]} />

			<Image
				key='floor'
				image={ images(`./echo/pages/curse${Math.floor(seeds[0] * 33)}.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={2}
				position={[0, -7, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />

			<Physics gravity={[0, -100, 0]}>
					<PhyPlane position={[0, -7, -10]} scale={68} rotation={[-Math.PI / 2, 0, 0]} />
					{enemies}
				</Physics>

			</Suspense>
	</group>)
}

export default Arena;
