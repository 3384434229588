import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import * as THREE from 'three'
import { Box, Plane, useTexture } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { EffectComposer, Pixelation, Glitch, GlitchMode } from '@react-three/postprocessing';

const AnimatedImage = ({ image, repeat, position, rotation, scale, material, onClick, geometry, onOver, onOut, onMove, textureRepeat, animRotation, animPosition, animEase, color }) => {

  const ref = useRef();

  useFrame(() => {
    if (animRotation != null) {
      ref.current.rotation.x += animRotation[0];
      ref.current.rotation.y += animRotation[1];
      ref.current.rotation.z += animRotation[2];
    }
    if (animPosition != null) {
      ref.current.position.x = ref.current.position.x > 20 ? -20 : ref.current.position.x + animPosition[0];
      ref.current.position.y += animPosition[1];
      ref.current.position.z += animPosition[2];
    }
    if (animEase != null) {
      ref.current.position.x = position[0] + animEase[0] / (ref.current.position.x + 1);
      ref.current.position.y = position[1] + animEase[1] / (ref.current.position.y + 1);
      ref.current.position.z = position[2] + animEase[2] / (ref.current.position.z + 1);
    }
  });

  const props = useTexture({
    map: image
  })

  const texture = useLoader(TextureLoader, image);
  const { viewport } = useThree();
  useMemo(() => {
    texture.generateMipmaps = false
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping
    texture.minFilter = LinearFilter
    texture.needsUpdate = true
  }, [
    texture.generateMipmaps,
    texture.wrapS,
    texture.wrapT,
    texture.minFilter,
    texture.needsUpdate,
  ])
  if (repeat) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set( textureRepeat || 20, textureRepeat || 20 );
  }

  /*
  let meshGeometry = geometry != null ? geometry : new THREE.PlaneBufferGeometry() ;
  let meshMaterial = material == 'phong' ? new THREE.MeshPhongMaterial({map: texture, transparent: true, side: THREE.DoubleSide}) : new THREE.MeshLambertMaterial({map:texture, transparent: true, side: THREE.DoubleSide});

  let mesh = new THREE.Mesh(meshGeometry, meshMaterial);

  return (
    <primitive object={mesh} key={k} scale={scale} rotation={rotation} position={position} onClick={click} onPointerOut={onOut} onPointerOver={onOver} ref={ref} castShadow={true} receiveShadow={true}>
    </primitive>
  )
  */

  let mat = <meshLambertMaterial attach="material" {...props} transparent={true} side={THREE.DoubleSide} />;
  if (material == 'phong') mat = <meshPhongMaterial attach="material" {...props} transparent={true} side={THREE.DoubleSide} />;
  else if (material == 'wireframe') mat = <meshNormalMaterial color={color != null ? color : '#ffffff'} wireframe />;
  if (material == 'phongwireframe') mat = <meshPhongMaterial color={color != null ? color : '#ffffff'} wireframe />;

  return (
    <mesh scale={scale} rotation={rotation} position={position} onClick={onClick} onPointerOut={onOut} onPointerOver={onOver} onPointerMove={onMove} ref={ref} castShadow={true} receiveShadow={true}>
      { geometry != null ? geometry : <planeBufferGeometry attach="geometry" />}
      { mat }
    </mesh>
  )
}

export default AnimatedImage;
