import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"
import $ from 'jquery';

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"
import Shooter from "../components/Shooter.js"
import Valuator from "../components/Valuator.js"
import Lantern from "../components/Lantern.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets', true);
let timeout = null;

const Gatekeeper = () => {

  const [tooltip, setTooltip] = useState("");
  const [ puredao, setPuredao ] = useGetAndSet('puredao');
  const [ language, setLanguage ] = useGetAndSet('language');
  const [ debugging, setDebugging ]  = useState(true);
  const [ added, setAdded ]  = useState(false);
  const [ message, setMessage ] = useState("");
  const [coding, setCoding] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [code, setCode] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });
  const [level, setLevel] = useState(0);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (!coding) return
    if (code.algorithm.find(d=>d.id == 'captcha')) {
      setCoding(false);
    }
  }, [code]);

  function addPlugin() {
    setInventory(inventory.concat({id: 'captcha'}));
    setAdded(true);
  }

  let hasCaptcha = code.input.find(d=>d.id == 'captcha');
  let loadedCaptcha = code.algorithm.find(d=>d.id == 'captcha');
  let loadedWeapon = code.algorithm.find(d=>d.id == 'loyalist');
  let loadedValuator = code.algorithm.find(d=>d.id == 'valuator');
  let loadedLantern = code.algorithm.find(d=>d.id == 'lantern');

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} setMessager={() => {setMessage("")}} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    <Shooter texts={texts[language]} visible={loadedWeapon} setMessager={() => {setMessage("")}} shooting={false} clip={8} setClip={() => {setMessage("")}} code={code} setCode={setCode} setCoding={setCoding} />,

    <Valuator visible={loadedValuator} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,

    <Lantern visible={loadedLantern} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,

    level == 0 && <div className='tooltipc'>{tooltip}</div>,

    (<div className='egrecorp offline'>
      {<div>
        <img className='alertimage' src={images('./echo/pages/right_6.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"WAIT!"} /></u>
        <Writer delay={1000} speed={10} msg={"You must complete a CAPTCHA to enter this CAO"}/><br></br>
      </div>}
      {!added && !hasCaptcha && !loadedCaptcha && (
        <div>
        <Writer delay={2000} speed={10} msg={"Please add the CAPTCHA CODE:"}/><br></br>
        <Delayer delay={4000}>
          <div className='plugin'>
            <img style={{ backgroundColor: '#000' }} src={ images(`./logo-captcha-simp.png`).default }/>
            <div style={{ textAlign: 'left' }}>
              <div className='title'>{"CAPTCHA FOREVER"}</div>
              <div className='title'>{"Digital Border Control"}</div><br></br>
              <a className='add' onClick={ addPlugin.bind(this) }>{"Click to add CODE"}</a>
              <img className='pluginlogo' src={ images(`./logo-egrecorp.png`).default }/>
            </div>
          </div>
        </Delayer>
        </div>
      )}
      {(added || hasCaptcha) && !loadedCaptcha && (
        <div>
        <Writer delay={0} speed={10} msg={"CAPTCHA added"}/><br></br>
        <Writer delay={1000} speed={10} msg={"Please click the DEBUGGER to load the CAPTCHA"}/><br></br>
        </div>
      )}
      {loadedCaptcha && (
        <Redirect to={{
          pathname: 'echo',
          state: { room: [0, 0, 'captcha'] }
        }} />
      )}
      {(level == 1 || level == 2) && (
        <div>
          <Writer speed={10} msg={level == 1 ? "No you are NOT. Our algorithms are smart enough to know that you're NOT. We are subtracting 5 TRUSTWORTH from your account." : "We already knew that, so 5 TRUSTWORTH have been added to your account."}/><br></br>
          <Writer delay={5000} speed={10} msg={"Starting Test #2..."}/><br></br>
        </div>
      )}
      {level == 10 && (
        <div>
          <Writer delay={1000} speed={10} msg={"CAPTCHA loaded... starting Test #1"}/><br></br>
          <Delayer delay={3000}>
            <div className='coder special' onMouseMove={(e) => {
              $('.tooltipc').css({'top': e.clientY, 'left': e.clientX + 20});
            }}>
              <div className='droppable input'>
                <div>AVAILABLE</div>
                  {texts[language].puredao.protocols.map((d,i) => {
                    if (puredao.protocols[i]) return
                    return (<div className='brick'
                    onMouseOver={() => {
                      setTooltip(texts[language].puredao.protocols[i]);
                    }}
                    onMouseOut={() => {
                      setTooltip("");
                    }}
                    onClick={() => {
                      document.body.style.cursor = 'default';
                      setLevel(1); setPuredao({...puredao, contesting: i}); }}>
                        { <img src={images(`./echo/pages/right_${i+40}.png`).default}/> }
                      </div>)
                  })}
              </div>
              <div className='droppable algorithm'>
                <div>LOADED</div>
                  {texts[language].puredao.protocols.map((d,i) => {
                    if (!puredao.protocols[i]) return
                    return (<div className='brick'
                    onMouseOver={() => {
                      setTooltip(texts[language].puredao.protocols[i]);
                    }}
                    onMouseOut={() => {
                      setTooltip("");
                    }}
                    onClick={() => {
                      document.body.style.cursor = 'default';
                      setTooltip("");
                      setLevel(1); setPuredao({...puredao, contesting: i}); }}>
                        { <img src={images(`./echo/pages/right_${i+40}.png`).default}/> }
                      </div>)
                  })}
              </div>
            </div>
          </Delayer>
          <Delayer delay={1000000}> <Redirect to={{
              pathname: 'informing'
            }} /> </Delayer>
        </div>
      )}
      {level == 11 && (
        <div>
          <Writer delay={0} speed={10} msg={`Will you be AMENDING or DEFENDING this CODE? (${texts[language].puredao.protocols[puredao.contesting]})`}/><br></br>
          <Delayer delay={2000}>
            <a onClick={() => {setLevel(2); setPuredao({...puredao, stance: 'amend'});}}>[1] AMEND (win game to edit CODE)</a><br></br>
            <a onClick={() => {setLevel(2); setPuredao({...puredao, stance: 'defend'});}}>[2] DEFEND (win game to keep CODE in place)</a><br></br>
            <a onClick={() => {setLevel(2); setPuredao({...puredao, stance: 'abstain'});}}>[3] ABSTAIN (just play)</a><br></br>
          </Delayer>
        </div>
      )}
      {level == 12 && (
        <div>
          <Writer delay={0} speed={10} msg={"Searching for other contestants..."}/><br></br>
          <div className='alertheader'><Writer delay={3000} speed={30} msg={"No contesants found. Governance mode is LAZY: Unless someone arrives to dispute you, your political action will pass."}/></div><br></br>
          <Writer delay={8000} speed={10} msg={"Starting a new match..."}/>

        </div>
      )}
      {level == 13 && (
        <Redirect to={{
          pathname: 'echo',
          state: { room: [-100, -98, 0] }
        }} />
      )}
    </div>)

  ]);

};

export default Gatekeeper;
