import React, { Fragment, Suspense, useMemo, useEffect, memo, useRef, useState } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const MirrorRoom = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, MMirror, texts, loadedBubble, inventory, equipped, setEquipped, setCoding, empiredao, visualData, colorData, scriptData }) => {

	const [ saw, setSaw ] = useState(false);
	const [ sawMirror, setSawMirror ] = useState(false);

	return (<group>

		<Suspense fallback={<Loader/>}>
			{empiredao.scriptureAlgorithm.find(d=>d.id == 'vampires') && <NPC
				scale={2}
				position={[-15, 0, -20]}
				rotation={[0, -Math.PI/2, 0]}
				image={images(visualData ? `./avatars/avatar_29.png` : `./checkerboard.png`).default}
				geometry={<sphereGeometry />}
				material='phong'
				onMove={(e) => {
						document.body.style.cursor = "help";
						setCode({
							...code,
							header: 'memory',
							input: inventory,
							algorithm: empiredao.mirrorNPC,
						});
						setMessager("", 10, null, false, texts.ui.debug);
				}}
				onOut={() => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={() => {
					setCoding(true);
				}}
			/>}
			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setRoomY(roomY - 1);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>
			{<group>
			<Image
				key='floor'
				image={ images(visualData ? `./echo/pages/curse_58.png` : `./checkerboard.png`).default }
				material='phong'
				scale={[10, 100, 100]}
				position={[0, -7, 0]}
				rotation={[Math.PI/2, 0, 0]} />
			<Image
				key={'stainedglass1'}
				geometry={<boxGeometry attach="geometry" />}
				image={images(visualData ? `./echo/stainedglass.png` : `./checkerboard.png`).default}
				material='phong'
				repeat={false}
				position={[10, 0, -50]}
				scale={[8, 10, 1]}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'stainedglass2'}
				geometry={<boxGeometry attach="geometry" />}
				image={images(visualData ? `./echo/stainedglass.png` : `./checkerboard.png`).default}
				material='phong'
				repeat={false}
				position={[-10, 0, -50]}
				scale={[8, 10, 1]}
				rotation={[0, Math.PI / 2, 0]} /></group>}
				{<Image
					key={'eastdoor'}
					image={images(visualData ? `./doors/door10.png` : `./checkerboard.png`).default}
					onOver={(e) => {
						document.body.style.cursor = "e-resize";
						setMessager("", 10, null, false, texts.ui.enter + ": " +  texts.empiredao.halls[2]);
					}}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						setRoomX(roomX + 1);
					}}
					repeat={false}
					position={[19.9, -3, -30]}
					scale={10}
					rotation={[0, Math.PI / 2, 0]} />}
			<Image
				key={'mirror'}
				image={images(visualData ? scriptData ? `./echo/cursedmirror.png` : `./echo/mirror.png` : `./checkerboard.png`).default}
				onOver={(e) => {
					document.body.style.cursor = "zoom-in";
          setMessager("", 10, null, false, texts.ui.view);
        }}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					if (!equipped.find(d=>d.id == 'visualdata')) {
						setMessager("[ERROR] The MAGIC MIRROR cannot REFLECT you when the ENLIGHTENER does not have any TEXTURES loaded. Please find the TEXTURE ANGEL", 30, null, true);
					} else {
						setRoomZ('mirror');
					}
				}}
				repeat={false}
				position={[0, 0, -49]}
				scale={[10, 13, 1]}
				rotation={[0, 0, 0]} />
			</Suspense>

	</group>)
}

export default React.memo(MirrorRoom);
