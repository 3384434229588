import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue, setCoding }) => {

	let doors = [];
	if (up) {
		doors.push(<Image
			image={ images(`./doors/door-sub1.png`).default }
			material='phong'
			repeat={false}
			position={[0, -3, -20]}
			scale={[7, 10, 1]}
			rotation={[0, 0, 0]}
			onOver={(e) => {
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomY(roomY + 1);
			}}
			/>);
	}
	if (right) {
		doors.push(<Image
			image={ images(`./doors/door-sub1.png`).default }
			material='phong'
			repeat={false}
			position={[15, -3, -15]}
			scale={[7, 10, 1]}
			rotation={[0, -Math.PI/3, 0]}
			onOver={(e) => {
				document.body.style.cursor = "e-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX + 1);
			}}
			/>);
	}
	if (left) {
		doors.push(<Image
			image={ images(`./doors/door-sub1.png`).default }
			material='phong'
			repeat={false}
			position={[-15, -3, -15]}
			scale={[7, 10, 1]}
			rotation={[0, Math.PI/3, 0]}
			onOver={(e) => {
				document.body.style.cursor = "w-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX - 1);
			}}
			/>);
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./echo/porthole1.png`).default }
				material='phong'
				repeat={false}
				position={[-8, 0, -20]}
				scale={3}
				rotation={[0, -Math.PI/20, 0]} />
			<Image
				image={ images(`./echo/porthole1.png`).default }
				material='phong'
				repeat={false}
				position={[8, 0, -20]}
				scale={3}
				rotation={[0, Math.PI/20, 0]} />
			{doors}
			{face != null && speech != null && <NPC
				scale={1.5}
				position={position == null ? [0, 0, -20] : position}
				rotation={[0, -Math.PI/2 + (position == null ? 0 : -position[0]*Math.PI/100), 0]}
				image={images(`./enemy/face${face}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setCode({
						...code,
						header: 'memory', input: [],
						algorithm: speech,
					});
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.remembering, 10, null, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>}
			</Suspense>


	</group>)
}

export default React.memo(Room);
