import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import useKeypress from 'react-use-keypress';

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Captcha = ({ images, setMessage, setAvatar, roomY, setRoomY, clip, setSpeed, setWait, code, setCode, setMessager, hallend, texts, setCoding, stackdao, setStackdao}) => {

	const [ stage, setStage ] = useState(0);
	const [ texture, setTexture ] = useState(`./bg-stars.png`);
	const [ tries, setTries ] = useState(0);

	const avatar = images(texts.captcha.avatar).default;

	useEffect(() => {
		let timer = null;
		switch (stage) {
			case 0:
				timer = setTimeout(() => {
					setMessager(texts.captcha.intro, 40, avatar, true);
					setStage(stage + 1);
				}, 1000);
			break;
			case 1:
				timer = setTimeout(() => {
					setMessager(texts.captcha.test1, 40, avatar, true);
					setStage(stage + 1);
				}, 2000);
			break;
			return () => clearTimeout(timer);
		}
	}, [stage]);

	useEffect(() => {
    if (stage == 2) {
			if (code.input.find(d=>d.id == 'corrector')) {
				setCoding(false);
				setMessager(texts.captcha.simple, 40, avatar, true)
				setStage(3);
			} else if (code.input.find(d=>d.id == 'other')) {
				setMessager(texts.captcha.wrong, 40, avatar, true)
			}
		}
  }, [code]);

	let npcs = [];
	for (let i = 0; i < 3; i++) {
		npcs.push(<NPC
			scale={2}
			offset={i * 20}
			position={[i*10 - 10, 0, -20]}
			rotation={[0, -Math.PI/2 + -(i*10 - 10)*Math.PI/100, 0]}
			image={images(`./enemy/face${i+21}.jpg`).default}
			geometry={<sphereGeometry attach="geometry" />}
			material='phong'
			onOver={(e) => {
				document.body.style.cursor = "context-menu";
				setMessager("", 40, null, false, texts.ui.debug);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				let options = [
					[{id: 'coconut', nft: true}],
					[{id: 'corrector', nft: true}],
					[{id: 'grease', nft: true}]
				];
				setCode({
					...code,
					header: 'captcha' + i,
					input: [{id: 'irrationality'}],
					algorithm: options[i],
				});
				setCoding(true);
			}}
		/>);
	}

	return ([

		<Suspense key={'foyer'} fallback={null}>
			{stage == 2 && npcs}
			{stage == 3 &&
				<NPC
					image={images('./doors/door12.png').default}
					onOver={(e) => {
						document.body.style.cursor = "n-resize";
						setMessager("", 10, null, false, texts.ui.enter);
					}}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						setRoomY(roomY + 1);
					}}
					repeat={false}
					position={[0, 0, -29.9]}
					scale={10}
					rotation={[0, 0, 0]} />}
		</Suspense>

	])
}

export default memo(Captcha);
