import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Debugger from "../components/Debugger.js"

import items from '../data/items.js'
import stars from "../assets/bg-stars.png"

const images = require.context('../assets', true);

const Products = () => {

  return ([
    <div className='egrecorp services' style={{ height: '100vh' }}>

      <img style={{width: '50px'}} className='alertimage' src={images('./checkerboard.png').default}></img>
      <u>{"EXECUTIVE ORDER «EXORCISM»"}</u><br></br>
      <div className='alertheader'>LEGISLATION TIER ALPHA: You need 99999 TRUSTWORTH to suggest AMENDMENTS to this CODE</div><br></br>

      <div>
      Any unusual feeling, apparition, or weird vibe should be investigated and reported as possible evidence of «PARANORMALITY»<br></br><br></br>
    «PARANORMAL» phenomena are perceptual consistencies and inconsistencies which appear to have an ambiguous truth value, and which are difficult to trace back to CODE<br></br><br></br>
  Because of their difficulty to find in CODE, «PARANORMAL» phenomena tend to be detected first by users, who report uncanny feelings or weird vibes about algorithmic processes. The implications of this for the premise that CODE IS LAW are still being evaluated.<br></br><br></br>
Some automatic detection systems have been prototypes, but cannot match the accuracy of user reports. For this reason, users should be given a DEBUGGER to investigate and report «PARANORMAL» phenomena.</div>

      <br></br>
      <NavLink to={{ pathname: 'offline' }}>{"< Back"}</NavLink>

    </div>
]);

};

export default Products;
