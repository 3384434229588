import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"
import AnimatedImage from "../AnimatedImage.js"

const LabFoyer = ({ images, setMessager, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, setCoding, seeds, texts, inventory, stackdao, family }) => {

	return (<group>
			<Suspense fallback={<Loader/>}>

			<AnimatedImage
				image={ images(`./echo/reticule.png`).default }
				geometry={<sphereGeometry/>}
				repeat={false}
				material='wireframe'
				position={[0, 0, -5]}
				scale={10}
				animRotation={[0, 0.0002, 0.0002]}
				rotation={[Math.PI/2, 0, 0]}
				onMove={() => {
					setMessager("", 10, null, false, "VOTE: " + "METAVERSE");
				}}
				onOut={() => {
						setMessager();
				}}
				onClick={() => {
					setCode({
						...code,
						header: 'engine',
						input: stackdao['metaverse'].input,
						algorithm: stackdao['metaverse'].algorithm,
					});
					setCoding(true);
				}}
				/>

			<AnimatedImage
				image={ images(`./echo/reticule.png`).default }
				geometry={<sphereGeometry/>}
				repeat={false}
				material='wireframe'
				position={[0, 0, -5]}
				scale={5}
				animRotation={[0, 0.0005, 0.0005]}
				rotation={[Math.PI/2, 0, 0]}
				onMove={() => {
					setMessager("", 10, null, false, "VOTE: " + "SOCIETY");
				}}
				onOut={() => {
						setMessager();
				}}
				onClick={() => {
					setCode({
						...code,
						header: 'engine',
						input: stackdao['society'].input,
						algorithm: stackdao['society'].algorithm,
					});
					setCoding(true);
				}}
				/>

			<AnimatedImage
				image={ images(`./echo/reticule.png`).default }
				geometry={<boxGeometry/>}
				repeat={false}
				material='wireframe'
				position={[0, 0, -5]}
				scale={2}
				animRotation={[0, 0, 0.005]}
				rotation={[Math.PI/2, 0, 0]}
				onMove={() => {
					setMessager("", 10, null, false, "VOTE: " + "FAMILY " + family.name.toUpperCase());
				}}
				onOut={() => {
						setMessager();
				}}
				onClick={() => {
					setCode({
						...code,
						header: 'engine',
						input: stackdao['family'].input,
						algorithm: stackdao['family'].algorithm,
					});
					setCoding(true);
				}}
				/>

				<AnimatedImage
					image={ images(`./echo/reticule.png`).default }
					geometry={<sphereGeometry/>}
					repeat={false}
					material='wireframe'
					position={[0, 0, -5]}
					scale={0.2}
					animRotation={[0, 0.002, 0.002]}
					rotation={[Math.PI/2, 0, 0]}
					onMove={() => {
						setMessager("", 10, null, false, "VOTE: " + "SELF");
					}}
					onOut={() => {
							setMessager();
					}}
					onClick={() => {
						setCode({
							...code,
							header: 'engine',
							input: stackdao['self'].input,
							algorithm: stackdao['self'].algorithm,
						});
						setCoding(true);
					}}
					/>

			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setCoding(false);
					setRoomY(roomY - 1);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>

			</Suspense>
	</group>)
}

export default React.memo(LabFoyer);
