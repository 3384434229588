import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { Redirect } from "react-router-dom";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));

  return (
      <Plane args={[100, 100]} ref={ref}>
        <meshBasicMaterial opacity={0} transparent />
      </Plane>
  );
}

const desirables = [4,57,11];

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, roomX, roomY, roomZ, setHeaded, texts, setRedirect, puredao }) => {

	const [ inside, setInside ] = useState(false);

  useEffect(() => {
    if (roomZ == 0 && roomX == -100 && roomY == -98) setMessager("CAPTURE THE FLAG -- Find the enemy team's flag", 30, null, true);
  }, []);

	let castles = [];
	if (!inside) {
		for (let i = 0; i < 3; i++) {
			castles.push(
				<Image
					image={ images(`./echo/pages/curse_${desirables[i]}.png`).default }
					geometry={<sphereGeometry></sphereGeometry>}
					material='phong'
					repeat={true}
					textureRepeat={1}
					position={[seeds[i*4+1]*40 -60 + (i*50), seeds[i*4+2]*10 + 15, seeds[i*4+3]*20 -60]}
					scale={10}
          onMove={() => {
						// document.body.style.cursor = pointers[i] + '-resize';
					}}
					onOut={() => {
						// document.body.style.cursor = 'default';
					}}
					onClick={() => {
						// document.body.style.cursor = 'default';
						// go(directions[i]);
					}}
					rotation={[0, seeds[i] * Math.PI, 0]} />
			);
		}
	}

	let buildings = [];
	if (roomY == -98) {
		for (let i = 0; i < 10; i++) {
			buildings.push(
				<Image
					image={ images(`./echo/pages/curse_${Math.floor(seeds[i] * 80)}.png`).default }
					geometry={<boxGeometry></boxGeometry>}
					material='phong'
					repeat={true}
					textureRepeat={0.5}
					position={[seeds[i*4+1]*80 -40, -10, seeds[i*4+3]*20 -70]}
					scale={[40 + seeds[i*4+2]*40, 20 + seeds[i*4+2]*10, seeds[i*4+2]*30]}
					onMove={() => {
						document.body.style.cursor = 'n-resize';
            setMessager("", 10, null, false, texts.ui.enter);
					}}
					onOut={() => {
						document.body.style.cursor = 'default';
            setMessager();
					}}
					onClick={() => {
						document.body.style.cursor = 'default';
						setRoomY(roomY + 1);
					}}
					rotation={[0, 0, 0]} />
			);
		}
	} else {
    buildings.push(<Image
      material='phong'
      image={images(`./echo/pages/right_55.png`).default}
      repeat={false}
      position={[0, 5, -28]}
      scale={[20, 10, 1]}
      onOver={() => {
        document.body.style.cursor = "grab";
        setMessager("", 10, null, false, texts.ui.capture);
      }}
      onOut={() => {
        document.body.style.cursor = "default";
        setMessager();
      }}
      onClick={() => {
        document.body.style.cursor = "wait";
        setMessager(texts.puredao.capturing, 10, null, true);
        setTimeout(() => {
          document.body.style.cursor = "default";
          setRedirect(<Redirect to={{ pathname: 'victory' }} />);
        }, 5000);
      }}
      rotation={[0, 0, 0]} />);
    buildings.push(<Image
      material='phong'
      geometry={<boxGeometry></boxGeometry>}
      image={images(`./echo/pages/right_10.png`).default}
      repeat={false}
      position={[-8, -1, -30]}
      scale={[1, 10, 1]}
      rotation={[0, 0, 0]} />);
    buildings.push(<Image
      material='phong'
      geometry={<boxGeometry></boxGeometry>}
      image={images(`./echo/pages/right_10.png`).default}
      repeat={false}
      position={[8, -1, -30]}
      scale={[1, 10, 1]}
      rotation={[0, 0, 0]} />);
  }

//25,27,36
//29 digital
	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				key={'skybox'}
				image={ images(`./echo/pages/curse_38.png`).default }
				geometry={<sphereGeometry></sphereGeometry>}
				material='phong'
				repeat={false}
				repeatTexture={false}
				position={[0, 0, -100]}
				scale={600}
				rotation={[Math.PI/8, 0, 0]} />
			<Image
				key='floor'
				image={ images(`./echo/pages/curse_41.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={1}
				position={[0, -7, -10]}
				scale={[300, 150, 1]}
				rotation={[-Math.PI / 2, 0, 0]} />
			{castles}
			{buildings}

			</Suspense>

	</group>)
}

export default React.memo(Room);
