import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import $ from 'jquery';

import * as THREE from 'three'
import { Box, Plane, Sphere, Torus } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping, RedFormat } from 'three';
import { EffectComposer, Pixelation, Glitch } from '@react-three/postprocessing';
import lerp from 'lerp'
import { Redirect } from "react-router-dom";
/*import GifLoader from 'three-gif-loader';*/

const images = require.context('../assets', true);

/*const giffer = new GifLoader();
const explosion = giffer.load(
	images(`./enemy/explosion.gif`).default,
	function (reader) {
		console.log(reader.numFrames());
	},
	function (xhr) {
		//console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`);
	},
	function () {
		console.error('GIF failed to load.');
	}
);*/

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));

  return (
      <Plane args={[1, 1]} ref={ref}>
        <meshStandardMaterial color={color} />
      </Plane>
  );
}

function Block({ doGlitch, stopGlitch, alive, setAlive, setVisible, clip, spawn, face, armed, setMessager, setRoomX, setRoomY, setRedirect, inventory, setInventory, ...props }) {
  const [ref, api] = useBox(() => ({ args: [1, 1, 1], mass: 1, fixedRotation: false, ...props }));

	const values = useRef([0, 0, 0]);

	if (alive) {
		api.velocity.set(
			-spawn[0] / 7,
			-spawn[1] / 7,
      -spawn[2] / 7
		);

		api.rotation.set(
			-spawn[1] / 60,
			4.7 - spawn[0] / 60,
			0
		);

		api.angularVelocity.set(
			0,
			spawn[0] / 500,
			0
		);

	}

	useEffect(() => {
    const unsubscribe = api.position.subscribe((v) => {
      if (alive && v[2] > 0) {
        let removal = inventory.find(d=>d.id == 'scarecrow');
        let temp = inventory;
        temp.splice(removal, 1);
        setInventory(temp);
        setRedirect(<Redirect to={{ pathname: '/flatlined', state: {cause: 'blockhead'} }} />);
      }
    }, []);
    return unsubscribe
	}, []);

	const texture = useLoader(TextureLoader, images(`./enemy/face${face}.jpg`).default);
	const { viewport } = useThree()
	useMemo(() => {
		texture.generateMipmaps = false
		texture.wrapS = texture.wrapT = ClampToEdgeWrapping
		texture.minFilter = LinearFilter
		texture.needsUpdate = true
	}, [
		texture.generateMipmaps,
		texture.wrapS,
		texture.wrapT,
		texture.minFilter,
		texture.needsUpdate,
	])

  return useMemo(() =>
      <Sphere args={[2, 5, 10]} ref={ref} onClick={() => {
				if (!armed || clip == 0) return
        document.body.style.cursor = "default";
				api.applyImpulse([props.position[0], -10, -30], [0, 0, 0]);
				// api.applyTorque(1000, 0, 0);
				setAlive(false);
				setTimeout(() => {
					stopGlitch();
					setVisible(false);
				}, 500);
			}}
			onPointerOver={(e) => {
        doGlitch();
				if (armed) document.body.style.cursor = "crosshair";
			}}
			onPointerOut={(e) => {
				document.body.style.cursor = "default";
				stopGlitch();
			}}
			>
        {alive ? <meshLambertMaterial attach="material" map={texture} transparent='true' /> : <meshPhongMaterial attach="material" transparent='true' />}
				{(alive) && (<Torus args={[0.1, 1, 2]} rotation={[1.5, 0, 0]} position={[0, 2, 0]}>
					<meshPhongMaterial attach="material" color={'0xffffff'} transparent='true' />
				</Torus>)}
      </Sphere>
  );
}

const ground =	<PhyPlane position={[0, -7, -10]} scale={68} rotation={[-Math.PI / 2, 0, 0]} />;

const Blockhead = ({ doGlitch, stopGlitch, clip, spawn, face, armed, setMessager, setRoomX, setRoomY, setRedirect, inventory, setInventory }) => {

	const [alive, setAlive] = useState(true);
	const [visible, setVisible] = useState(true);

	return ([

		<>
			{(visible) && (<Physics gravity={[0, 0, 0]}>
				<Block doGlitch={doGlitch} stopGlitch={stopGlitch} alive={alive} clip={clip} setVisible={setVisible} setAlive={setAlive} face={face} spawn={spawn} position={spawn} armed={armed} setMessager={setMessager} setRoomX={setRoomX} setRoomY={setRoomY} setRedirect={setRedirect} inventory={inventory} setInventory={setInventory} />
			</Physics>)}
		</>

	])
}

export default memo(Blockhead);
