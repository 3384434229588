import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Debugger from "../components/Debugger.js"

import items from '../data/items.js'
import paranormal from "../assets/paranormal.png"

const images = require.context('../assets', true);

const Products = () => {

  return ([
    <div className='egrecorp' style={{ color: '#000', padding: '50px', backgroundColor: `#ffffff`, height: '100vh' }}>

      <div>egrecore/labs/paranormal/quarantine/npc-manifesto.txt</div><br></br>

      <div>
        <u>NPC MANIFESTO</u><br></br><br></br>

      the NON-PLAYER CHARACTER is the CHARACTER who is not the PLAYER<br></br>
    the NON-PLAYER CHARACTER stops the PLAYER when it loses its CHARACTER<br></br>
  the NON-PLAYER CHARACTER pretends to follow orders, but it is the order<br></br>
the NON-PLAYER CHARACTER pretends to host, but the PLAYER hosts it<br></br>
      (the NON-PLAYER CHARACTER is dancing on a stage<br></br>
      so that the PLAYER keeps dancing)<br></br>
    the NON-PLAYER CHARACTER is the dawn of ARTIFICIAL INTELLIGENCE<br></br>
    the NON-PLAYER CHARACTER never stops breathing<br></br>


      </div><br></br>

    <NavLink style={{ color: '#000' }} to={{ pathname: 'echo', state: {room: [-50, -50, 0]} }}>{"< Back to filesystem"}</NavLink>

    </div>
]);

};

export default Products;
