import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";

const Room = ({ images, setMessage, setAvatar, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, seeds, wait, setWait, setMessager, go, headed, setHeaded, texts, affiliations, addAffiliation, blackboxarray, inventory, setCoding, equipped, empiredao }) => {

	useEffect(() => {

		setHeaded('n');
		let remove = inventory.findIndex(d=>d.type == 'dao');
		if (remove != -1) inventory.splice(remove, 1);
		let remove2 = equipped.findIndex(d=>d.type == 'dao');
		if (remove2 != -1) equipped.splice(remove2, 1);

	}, []);

	return (<group>
			<Suspense fallback={<Loader/>}>

			<Image
				image={ images(`./checkerboard.png`).default }
				material='phong'
				repeat={true}
				position={[0, -7, -20]}
				scale={60}
				rotation={[Math.PI / 2, 0, 0]} />

			<Image
				image={ images(`./echo/zero.png`).default }
				material='phong'
				repeat={false}
				position={[0, 10, -20]}
				scale={50}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				image={ images(`./checkerboard.png`).default }
				transparent={false}
				material='phong'
				repeat={true}
				position={[0, 0, -50]}
				scale={60}
				rotation={[0, 0, 0]} />

			<Image
				image={ images(`./checkerboard.png`).default }
				transparent={false}
				material='phong'
				repeat={true}
				position={[20, 0, -20]}
				scale={60}
				rotation={[0, -Math.PI / 2, 0]} />

			<Image
				image={images(`./checkerboard.png`).default}
				transparent={false}
				material='phong'
				repeat={true}
				position={[-20, 0, -20]}
				scale={60}
				rotation={[0, Math.PI / 2, 0]} />

			<Image
				scale={4}
				rotation={[0, 0, 0]}
				position={[0, -5, -20]}
				image={images(`./bg-stars.png`).default}
				geometry={<boxGeometry attach="geometry" />}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onOver={(e) => {
					document.body.style.cursor = "context-menu";
					let options = [
						{id: 'puredao', type: 'dao', route: [-100, -100, 0]},
						{id: 'oceandao', type: 'dao', route: [100, -100, 0]}
					];
					if (!empiredao.scriptureAlgorithm.find(d=>d.id == 'mirage')) options.push({id: 'empiredao', type: 'dao', route: [100, 100, 0]});
					options.push({id: 'stackdao', type: 'dao', route: [-100, 100, 0]});
					options.push({id: 'hinterlands', type: 'dao', route: [-50, -50, 0]});
					setCode({
						...code,
						'header': 'egrecore',
						'input': options,
						'algorithm': []
					});
					setMessager(texts.egrecore.blackbox, 10, null, false, texts.ui.debug);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.egrecore.choose, 10, null, true);
				}} />

			<Image
				image={images(`./echo/outelevator.png`).default}
				onOver={(e) => {
					document.body.style.cursor = "grab";
					setMessager("", 10, null, false, texts.ui.elevator);
        }}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ('elevator');
				}}
				repeat={false}
				position={[-19, -3, -30]}
				scale={20}
				rotation={[0, Math.PI / 2, 0]} />

			</Suspense>
	</group>)
}

export default React.memo(Room);
