import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";

import Image from "../Image.js"
import AnimatedImage from "../AnimatedImage.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const LabFoyer = ({ images, setMessager, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, setCoding, seeds, texts, inventory }) => {

	let buildings = [];

	for (let i = 0; i < Math.ceil(seeds[0] * 3); i++) {
		buildings.push(<AnimatedImage
			image={ images('./checkerboard.png').default }
			material='wireframe'
			geometry={ <boxGeometry /> }
			position={[seeds[i+1]*40-20, seeds[i+2]*20-10, seeds[i+3]*40-40]}
			scale={[2, 2, 2]}
			animEase={[0, 0, 1000]}
			onOver={(e) => {
				setRoomY(roomY + 1);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}} />);
	}

	return (<group>
			<Suspense fallback={<Loader/>}>

				<AnimatedImage
					key={roomY}
					image={ images('./checkerboard.png').default }
					position={[0, 0, 0]}
					scale={[2, 2, 2]}
					geometry={<boxGeometry/>}
					material='wireframe'
					animEase={[0, 0, -1000]}
					/>
			{buildings}

			</Suspense>
	</group>)
}

export default React.memo(LabFoyer);
