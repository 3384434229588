import React, { Fragment, Suspense, useMemo, useState } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_5.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import { useAuth0 } from "@auth0/auth0-react";

const Gatekeeper = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [ isHuman, setIsHuman ] = useState(false);
  const [ isRobot, setIsRobot ] = useState(false);
  const [ showDetails, setShowDetails ] = useState(false);

  let loginPrompt = !isAuthenticated ? <span><a onClick={() => loginWithRedirect()}>LOG IN</a> and</span> : "";

  const details = (<div><Writer delay={0} speed={10} msg={"Executive Order Summary:"}></Writer>
  <br></br>
    <u className='alertheader'><Writer delay={1000} speed={10} msg={"LEGISLATION TIER ALPHA"}></Writer></u><br></br>
    <Writer delay={2000} speed={10} msg={"You need 99999 Trustworth to participate in this discussion."}></Writer>
    <Writer delay={2500} speed={10} msg={"You need 11111 Trustworth to vote."}></Writer>
  <br></br><br></br>
  <Delayer delay={4000}>
  <div className='forumpost'>
    <div className='profileinfo'><img src={av1}></img><br></br>shyghgy</div>
    <div className='message'>
      <Writer delay={500} speed={10} msg={"there is a lot of free labor out there that we should be milking and exploiting to the highest degree. account registration should be free and all new users should get a debugger. with enough monkeys you can write shakespeare or do the shakespearean equivalent of fixing code. eventually someone is going to figure something out"}></Writer>
    </div>
  </div>
  <div className='forumpost'>
    <div className='profileinfo'><img src={av2}></img><br></br>NomicOrders</div>
    <div className='message'>
      <Writer delay={1000} speed={10} msg={"You're making it worse. Go outside. Already barely anything follows the code and you want to add even more people that don't know what's going on? Your DAO is already so biased and broken, you just want to add more people to ruin the rest of us. We shouldn't be adding untrustworthy users, we should be purging them, and do a hard fork."}></Writer>
    </div>
  </div>
  <div className='forumpost'>
    <div className='profileinfo'><img src={av3}></img><br></br>cybernetics<br></br>and chill</div>
    <div className='message'>
      <Writer delay={1500} speed={10} msg={"yall are whats broken you wanted to experiment with conjuring machine agencies, did u expect them to sit down with u at ur banquets? ppl are gonna lose money lmao"}></Writer>
    </div>
  </div>
  <div className='forumpost'>
    <div className='profileinfo'><img src={av1}></img><br></br>shyghgy</div>
    <div className='message'>
      <Writer delay={2000} speed={10} msg={"somewhere we failed to follow the templates. the debugr shows paranormal activity so we know that it can be detected and corrected. we should increase the penalty for illicit codeblocks. and we dont need to give new users all permissions, just enough so that they can comb through the code and report problems to the atlas"}></Writer>
    </div>
  </div>
  <div className='forumpost'>
    <div className='profileinfo'><img src={av4}></img><br></br>STACCMAXX</div>
    <div className='message'>
      <Writer delay={2500} speed={10} msg={"Disagree with the language but Nomic is right. We followed the templates exactly, that's not the problem. We need to return to what we know, not try to introduce more complexity into it."}></Writer>
    </div>
    <br></br><br></br>
    <u className='alertheader'>VOTE CLOSED</u><br></br> Victor: EXORCISM (shyghgy)
    <br></br><br></br>
    Results breakdown:<br></br>EXORCISM (shyghgy) 9128<br></br>HARD FORK (NomicOrders) 8582 <br></br>Abstension 123<br></br> Uncounted 2126481<br></br><br></br>
  </div></Delayer></div>)

  return (
    <div className='egrecorp gatekeeper'>

      {(!isAuthenticated || !user["https://egrecorp.com/debugger"]) && (
        <div><u className='alertheader'><Writer delay={0} speed={10} msg={"WARNING: EXECUTIVE ORDER \"EXORCISM\""} /></u>
        <Writer delay={1000} speed={10} msg={"You are about to enter a highly unstructured symbolic environment."}></Writer><br></br>
          <Delayer delay={3000}>(Please {loginPrompt} activate a <NavLink tag={RouterNavLink} to='/products'>DEBUGGER</NavLink> in order to continue.)</Delayer>
            <br></br><br></br>
            {!showDetails && (<Delayer delay={4000}><a onClick={() => setShowDetails(true)}>Click for more information</a></Delayer>)}
            {showDetails && details}
        </div>
      )}

      {(isAuthenticated && user["https://egrecorp.com/debugger"]) && (
        <Redirect to="captcha" />
      )}


    </div>
);

};

export default Gatekeeper;
