import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"

const Elevator = ({ images, setMessage, setAvatar, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, setTooltip, texts, floor, setFloor }) => {

	setSpeed(10);

	let x = 0;
	let y = 0;
	if (floor == 1) {
		x = -10.1;
		y = -6.1;
	} else if (floor == 2) {
		x = -10.3;
		y = -2.6;
	} else if (floor == 3) {
		x = -10.5;
		y = 0.7;
	}


	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				key={'elevator'}
				geometry={<boxGeometry attach="geometry" />}
				material='phong'
				image={images(`./echo/inelevator.png`).default}
				repeat={false}
				position={[0, -3, -50]}
				scale={50}
				rotation={[0, 0, 0]} />
			</Suspense>

			<mesh
				position={[2, 0, -25]}
				scale={[18, 40, 1]}
				visible={false}
				onPointerOver={() => {
					document.body.style.cursor = "grab";
					setTooltip("EXIT");
				}}
				onPointerOut={() => { document.body.style.cursor = "default";
				setTooltip(""); }}
				onClick={() => {
					if (floor == 1) setRoomZ('egrecore');
					if (floor == 2) setRoomZ('servers');
					if (floor == 3) setRoomZ(0);
				}}>
				<boxGeometry/>
			</mesh>

			<mesh
				position={[-9, 0.4, -25]}
				visible={false}
				onPointerOver={() => { document.body.style.cursor = "pointer";
				setTooltip(texts.egrecore.floor3); }}
				onPointerOut={() => { document.body.style.cursor = "default";
				setTooltip(""); }}
				onClick={() => {
					setFloor(3);
					setTimeout(() => {
						setRoomZ(0);
					}, 1000);
				}}>
				<boxGeometry/>
			</mesh>

			<mesh
				position={[-9, -2.4, -25]}
				visible={false}
				onPointerOver={() => { document.body.style.cursor = "pointer";
				setTooltip(texts.egrecore.floor2); }}
				onPointerOut={() => { document.body.style.cursor = "default";
				setTooltip(""); }}
				onClick={() => {
					setFloor(2);
					setTimeout(() => {
						setRoomZ('servers');
					}, 1000);
				}}>
				<boxGeometry/>
			</mesh>

			<mesh
				position={[-9, -5.3, -25]}
				visible={false}
				onPointerOver={() => { document.body.style.cursor = "pointer";
				setTooltip(texts.egrecore.floor1); }}
				onPointerOut={() => { document.body.style.cursor = "default";
				setTooltip(""); }}
				onClick={() => {
					setFloor(1);
					setTimeout(() => {
						setRoomZ('egrecore');
					}, 1000);
				}}>
				<boxGeometry/>
			</mesh>

			<pointLight color={0x00ff00} intensity={8} distance={10} decay={2} position={[x, y, -20]} />

	</group>)
}

export default React.memo(Elevator);
