import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts }) => {

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./echo/grate.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ(roomZ - 1);
				}}
				material='phong'
				repeat={false}
				position={[0, -5, -10]}
				scale={5}
				rotation={[Math.PI / 2, 0, 0]} />
			</Suspense>


	</group>)
}

export default React.memo(Room);
