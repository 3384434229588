import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"
import AnimatedImage from "../AnimatedImage.js"

const LabFoyer = ({ images, setMessager, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, setCoding, seeds, texts, inventory, setRedirect, stackdao, setStackdao }) => {

	let freetime = Math.floor(stackdao.coding / 10) - stackdao.relaxed;

	return (<group>
			<Suspense fallback={<Loader/>}>
			<mesh scale={10} position={[0, 0, -10]}>
				<cylinderGeometry args={[1, 1, 2]} />
      	<meshNormalMaterial wireframe />
			</mesh>

			<mesh scale={2} position={[0, -8, -10]}
				onPointerOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.enter + ": " + texts.stackdao.halls[3]);
				}}
				onClick={(e) => {
					document.body.style.cursor = 'default';
					setRoomZ(-1);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}>
				>
				<cylinderGeometry args={[1, 1, 2]} />
      	<meshPhongMaterial wireframe />
			</mesh>

			<AnimatedImage
				image={ images(`./echo/reticule.png`).default }
				geometry={<cylinderGeometry args={[1, 5, 1]} />}
				repeat={false}
				material='wireframe'
				position={[0, 0, -5]}
				scale={10}
				animRotation={[0, 0.0002, 0]}
				rotation={[0, 0, 0]} />

			<mesh scale={10} position={[0, 0, -20]}
				onPointerOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, texts.ui.enter + ": " + texts.stackdao.halls[1]);
				}}
				onClick={(e) => {
					setRoomY(roomY + 1);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}>
				<boxBufferGeometry args={[0.1, 0.2, 0.1]} />
      	<meshPhongMaterial wireframe />
			</mesh>

			<mesh scale={10} position={[-10, 0, -10]} rotation={[0, Math.PI/3, 0]}
				onPointerOver={(e) => {
					document.body.style.cursor = "nw-resize";
					setMessager("", 10, null, false, texts.ui.enter + ": " + texts.stackdao.halls[0]);
				}}
				onClick={(e) => {
					setRoomX(roomX - 1);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}>
				<boxBufferGeometry args={[0.1, 0.2, 0.1]} />
      	<meshPhongMaterial wireframe />
			</mesh>

			<mesh scale={10} position={[10, 0, -10]} rotation={[0, -Math.PI/3, 0]}
				onPointerOver={(e) => {
					document.body.style.cursor = "ne-resize";
					setMessager(freetime == 0 ? "You do not have enough FREETIME to enter – You may VOTE to change this POLICY or earn FREETIME by WORKING" : "", 10, null, false, (freetime == 0 ? "[LOCKED] " : texts.ui.enter) + ": " + texts.stackdao.halls[2]);
				}}
				onClick={(e) => {
					if (freetime > 0) {
						setStackdao({ ...stackdao, relaxed: stackdao.relaxed + 1 });
						setRoomX(roomX + 1);
					}
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}>
				<boxBufferGeometry args={[0.1, 0.2, 0.1]} />
      	<meshPhongMaterial wireframe />
			</mesh>

			<NPC
				scale={1.5}
				position={[15, -2, -25]}
				rotation={[0, -Math.PI/3, 0]}
				image={images(`./scanline.png`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phongwireframe'
				onOver={(e) => {
					document.body.style.cursor = "help";
					setCode({
						...code,
						header: 'memory',
						input: inventory,
						algorithm: [{id: 'captcha'}, {id: 'family', alt: "FAMILY J"}],
					});
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.remembering, 10, null, true);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>

			</Suspense>
	</group>)
}

export default React.memo(LabFoyer);
