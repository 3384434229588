import React, { Fragment, Suspense, useMemo, useState, useEffect, memo } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import $ from 'jquery';
import useKeypress from 'react-use-keypress';

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import axios from "axios";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"

import captcha from "../assets/logo-captcha.png"
import captchaSimp from "../assets/logo-captcha-simp.png"

import { useAuth0 } from "@auth0/auth0-react";

let perfect = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
let sequences = [];
for (var i = 0; i < 6; i++) {
  let temp = JSON.parse(JSON.stringify(perfect));
  for (var j = 0; j < 10; j++) {
    let random1 = Math.floor(Math.random() * 10);
    let random2 = Math.floor(Math.random() * 10);
    let holder = temp[random1];
    temp[random1] = temp[random2];
    temp[random2] = holder;
  }
  sequences.push(temp);
}
sequences[4] = perfect;

const Writer1 = React.memo(props => {
  return <Writer speed={30} msg={"Welcome to EGRECORP!"}></Writer>
});

const Writer2 = React.memo(props => {
  return <Writer delay={0} speed={30} msg={"We're excited to have you as a part of our meta-community. Before you get started, there are a few things we think you should know before you experience the beautiful depth of this world that we worked so hard, as a community, to create democratically."}></Writer>
});

const Writer3 = React.memo(props => {
  return <Writer delay={0} speed={30} msg={"Here you have the opportunity to become a valuable member of a number of DAOs, or Decentralized Autonomous Organizations. DAOs are like families, but families that TRUST each other and can VOTE for what they believe in. Here, CODE IS LAW, so you don't have to worry about anybody taking these rights away."}></Writer>
});

const Writer4 = React.memo(props => {
  return <Writer delay={0} speed={30} msg={"It's important that you earn the TRUST of communities so that they don't mistake you for an OPPORTUNIST, a filthy corrupted liar. To earn TRUST, you can go around making PROPOSALS to code in the environment. If your PROPOSAL is popular, your TRUSTWORTH will grow and you can play a bigger part in community affairs."}></Writer>
});

const Writer5 = React.memo(props => {
  return <Writer delay={0} speed={30} msg={"Don't have any good ideas? No problem – there's also a faster way. Lately we've had a bit of a problem with PARANORMAL PHENOMENA. DARK PATTERNS and ILLICIT CODEBLOCKS plague the hinterlands of the DAOs, even interrupting some of our VOTING SYSTEMS."}></Writer>
});

const Writer6 = React.memo(props => {
  return <Writer delay={0} speed={30} msg={"Fortunately, we gave you a DEBUGGER so that you can have an easier time finding these nasty issues. In fact, if you stay out of the UNSTRUCTURED territories, you shouldn't have to deal with them at all. But whoever finds the source of the DARK PATTERNS will be rewarded with 99999 TRUSTWORTH, giving them virtually unlimited POWER. It's just something to consider."}></Writer>
});

const Writer7 = React.memo(props => {
  return <Writer delay={0} speed={30} msg={"We hope that you will find a way to make valuable contributions to our society, whilst staying out of trouble! But first, would you please complete the following examination to make sure that you are NORMAL?"}></Writer>
});

const Intro = () => {
  const managementAPIToken = useStore('managementAPIToken')[0];
  const [ message, setMessage ] = useState("");
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [ level, setLevel ] = useState(1);

  return ([
    <div className='egrecorp intro'>

      {(level >= 1) && (
        <div>
          <Writer1 />
          <br></br>
          <Delayer delay={2000}><Writer2 /></Delayer>
        </div>
      )}

      {(level >= 2) && (
        <div>
          <br></br>
          <Writer3 />
        </div>
      )}

      {(level >= 3) && (
        <div>
          <br></br>
          <Writer4 />
        </div>
      )}

      {(level >= 4) && (
        <div>
          <br></br>
          <Writer5 />
        </div>
      )}

      {(level >= 5) && (
        <div>
          <br></br>
          <Writer6 />
        </div>
      )}

      {(level >= 6) && (
        <div>
          <br></br>
          <Writer7 />
          <br></br>
          <Delayer delay={7000}>
            <a href='/captcha'>Yes I am NORMAL, and I am ready to do anything to prove it</a>
            <br></br>
            <a href='/home'>No I am not NORMAL, please take me home</a>
          </Delayer>
        </div>
      )}

      <br></br>
      <Delayer delay={10000}><a onClick={() => {
        setLevel(level + 1);
      }}>Click to continue</a></Delayer>

    </div>
]);

};

export default Intro;
