import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Room = ({ images, setMessage, setAvatar, roomY, setRoomX, setRoomY, setRoomZ, clip, setSpeed, code, setCode, go, setMessager, texts, setLifespan, lifespan, setRedirect, inventory, setCoding }) => {


	return ([

		<Suspense fallback={null}>
			<Image
				image={ images(`./doors/door21.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					document.body.style.cursor = "default";
					setRedirect(<Redirect to={{ pathname: '/home' }} />);
				}}
				material='phong'
				repeat={false}
				position={[0, 2, -10]}
				scale={5}
				rotation={[0, 0, 0]} />
			<Image
				image={ images(`./echo/grate.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.exit);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ(0);
				}}
				material='phong'
				repeat={false}
				position={[0, -5, -10]}
				scale={5}
				rotation={[Math.PI / 2, 0, 0]} />
		</Suspense>

	])
}

export default memo(Room);
