import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"
import $ from 'jquery';

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"
import Shooter from "../components/Shooter.js"
import Valuator from "../components/Valuator.js"
import Lantern from "../components/Lantern.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets', true);
let timeout = null;

const Gatekeeper = () => {

  const [tooltip, setTooltip] = useState("");
  const [ puredao, setPuredao ] = useGetAndSet('puredao');
  const [ language, setLanguage ] = useGetAndSet('language');
  const [ debugging, setDebugging ]  = useState(true);
  const [ added, setAdded ]  = useState(false);
  const [ message, setMessage ] = useState("");
  const [coding, setCoding] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [code, setCode] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });
  const [level, setLevel] = useState(0);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    console.log(level);
  }, [level]);

  useEffect(() => {
    if (!coding) return
    if (code.algorithm.find(d=>d.id == 'captcha')) {
      setCoding(false);
    }
  }, [code]);

  function addPlugin() {
    setInventory(inventory.concat({id: 'captcha'}));
    setAdded(true);
  }

  let hasCaptcha = code.input.find(d=>d.id == 'captcha');
  let loadedCaptcha = code.algorithm.find(d=>d.id == 'captcha');
  let loadedWeapon = code.algorithm.find(d=>d.id == 'loyalist');
  let loadedValuator = code.algorithm.find(d=>d.id == 'valuator');
  let loadedLantern = code.algorithm.find(d=>d.id == 'lantern');

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} setMessager={() => {setMessage("")}} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    <Shooter texts={texts[language]} visible={loadedWeapon} setMessager={() => {setMessage("")}} shooting={false} clip={8} setClip={() => {setMessage("")}} code={code} setCode={setCode} setCoding={setCoding} />,

    <Valuator visible={loadedValuator} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,

    <Lantern visible={loadedLantern} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,

    false && <div className='tooltipc'>{tooltip}</div>,

    (<div className='egrecorp offline'>
      {<div>
        <img className='alertimage' src={images('./echo/pages/right_6.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"PANDEMONIUM"} /></u>
        <Writer delay={1000} speed={10} msg={"You must complete a CAPTCHA to enter this CAO"}/><br></br>
      </div>}
      <NavLink to={{ pathname: 'echo', state: {room: [-100, 102, 0]} }}>{"< Back"}</NavLink>
    </div>)

  ]);

};

export default Gatekeeper;
