import React, { Fragment, Suspense, useMemo, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue }) => {

	let pipes = [];
	for (var i = 0; i < 10; i++) {
		for (var j = 0; j < i % 3; j++) {
			let x = Math.sin(i/10 * Math.PI*2 + j*20) * 6;
			let y = Math.cos(i/10 * Math.PI*2 + j*20) * 6;
			if (y > 0 && x < 5 && x > -5) continue
			pipes.push(<mesh position={[
				x,
				0,
				y,
			]} scale={[0.5, 20, 0.5]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#bbbbbb'}></meshPhongMaterial>
				</mesh>);
		}
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<group rotation={[Math.PI/2, 0, 0]} position={[0, 0, -10]}>{pipes}</group>
			<mesh
				scale={[10,80,1]}
				position={[0,-8,-20]}
				rotation={[-Math.PI/2, 0, 0]}>
				<planeGeometry></planeGeometry>
				<meshPhongMaterial color='#000000'></meshPhongMaterial>
			</mesh>
			<group position={[0, 0, -20]}
				onPointerOver={(e) => {
					document.body.style.cursor = "zoom-in";
					setMessager("", 10, null, false, texts.ui.view);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ('master');
				}}>
				<mesh position={[0, 0, -10]} scale={[1, 60, 1]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<mesh position={[0, 0, -10]} scale={[2, 2, 1]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<mesh position={[0, 0, -10]} scale={[0.3, 10, 0.3]} rotation={[Math.PI/2,0,0]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<Image position={[0, 4.5, -8]} scale={[2, 4, 2]} rotation={[0,0,0]}
					image={images(`./echo/pages/right_50.png`).default}
					material='phong'
				/>
				<mesh position={[0, 5, -10]} scale={[2, 4, 2]} rotation={[0,0,0]}>
					<boxGeometry></boxGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<Image
					image={ images(`./echo/wheel2.png`).default }
					material='phong'
					repeat={false}
					position={[0, 0, -8]}
					scale={4}
					rotation={[0, 0, 0]}
					/>
			</group>
			</Suspense>


	</group>)
}

export default React.memo(Room);
