import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"
import Shooter from "../components/Shooter.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets/', true);

const Gatekeeper = () => {

  const [ puredao, setPuredao ] = useGetAndSet('puredao');
  const [ language, setLanguage ] = useGetAndSet('language');
  const [ equipped, setEquipped ] = useGetAndSet('equipped');
  const [ inventory, setInventory ] = useGetAndSet('inventory');

  const [ redirect, setRedirect ] = useState(null);
  const [ debugging, setDebugging ]  = useState(true);
  const [ added, setAdded ]  = useState(false);
  const [ message, setMessage ] = useState("");
  const [ coding, setCoding ] = useState(false);
  const [ code, setCode ] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });

  let armed = equipped.find(d=>d.id == 'loyalist') != null;

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} setMessager={() => {setMessage("")}} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    <Shooter texts={texts[language]} visible={armed} setMessager={() => {setMessage("")}} shooting={false} clip={8} setClip={() => {setMessage("")}} code={code} setCode={setCode} setCoding={setCoding} />,

    (<div className='egrecorp offline'>
      {<div>
        <img className='alertimage' src={images('./echo/pages/right_1.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"PureDAO LEADERS"} /></u>
        <i><Writer delay={1000} speed={10} msg={"\"Active in combat, active in society\""}/></i><br></br>
        <Delayer delay={3000}>
          <NavLink onClick={() => {
              setRedirect(<Redirect to={{ pathname: '/echo', state: {room: [-100, -99, 0] }
            }}/>); }}>{"Return to LOBBY"}</NavLink>
        </Delayer><br></br><br></br>
        <Delayer delay={2000}>
          <table style={{ width: '100%' }}>
            <tr><th>#</th><th>username</th><th>voting block</th><th>wins</th><th>losses</th><th>kill/death</th><th>CTX</th></tr>

            <tr><td>1</td><td>sequel</td><td>disorder</td><td>34121</td><td>6041</td><td>8.8</td><td>0</td></tr>
            <tr><td>2</td><td>pogo1</td><td>disorder</td><td>31295</td><td>8931</td><td>7.3</td><td>3291</td></tr>
            <tr><td>3</td><td>Xx pug xX</td><td>dynasty</td><td>29532</td><td>9182</td><td>7.2</td><td>6329</td></tr>
            <tr><td>4</td><td>leezus</td><td>protocall</td><td>28691</td><td>8962</td><td>6.1</td><td>12125</td></tr>
            <tr><td>5</td><td>magicate</td><td>boycott</td><td>26295</td><td>10299</td><td>5.9</td><td>4251</td></tr>
            <tr><td>6</td><td>sadly</td><td>sadclan</td><td>24286</td><td>14921</td><td>5.9</td><td>0</td></tr>
            <tr><td>7</td><td>psquared</td><td>dynasty</td><td>21915</td><td>19822</td><td>4.8</td><td>992</td></tr>
            <tr><td>8</td><td>brutis</td><td>futurist</td><td>19851</td><td>11486</td><td>4.3</td><td>1925</td></tr>
            <tr><td>9</td><td>onlain</td><td>boycott</td><td>17129</td><td>12951</td><td>4.2</td><td>983</td></tr>
            <tr><td>10</td><td>4shot</td><td>ytopia</td><td>16982</td><td>16291</td><td>4.2</td><td>163</td></tr>
          </table>
        </Delayer>
      </div>}

      {redirect}

    </div>)

  ]);

};

export default Gatekeeper;
