import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { Redirect } from "react-router-dom";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, roomX, roomY, setHeaded, texts, remembering, setRedirect, puredao }) => {

	let doors = [];
	for (let i = 0; i < 3; i++) {
		doors.push(<Image
			image={ images(`./echo/pages/curse_62.png`).default }
			onOver={(e) => {
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default"
				setMessager();
			}}
			onClick={(e) => {
				document.body.style.cursor = "default";
				setRoomY(roomY + 1);
			}}
			material='phong'
			scale={[8,15,1]}
			position={[0,0,-60]}
			rotation={[0, 0, 0]} />);
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			{doors}
			<Image
				image={ images(`./checkerboard.png`).default }
				material='phong'
				textureRepeatX={4}
				textureRepeatY={18}
				repeat={true}
				scale={[10,80,1]}
				position={[0,-8,-20]}
				rotation={[Math.PI/2, 0, 0]} />
		</Suspense>

	</group>)
}

export default React.memo(Room);
