import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { Redirect } from "react-router-dom";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setRoomZ, setWait, roomX, roomY, setHeaded, texts, remembering, setRedirect, puredao, inventory }) => {

	let doors = [];
	for (let j = 0; j < 8; j++) {
		for (let i = 0; i < 3; i++) {
			doors.push(<Image
				image={ images(`./echo/pages/curse_${i+1}${j}.png`).default }
				onOver={(e) => {
					document.body.style.cursor = 'not-allowed';
					setMessager("", 10, null, false, texts.ui.missing);
					if (j == 3 && i == 2) {
						document.body.style.cursor = 'help';
						setMessager(texts.puredao.twitter, 30);
					}
					if (j != 4 && j != 5) return
					document.body.style.cursor = i != 1 || j != 4 || puredao.protocols[7] ? "n-resize" : "not-allowed";
					setMessager(i != 1 || j != 4 || puredao.protocols[7] ? "" : texts.puredao.locked, 10, null, false, (i != 1 || j != 4 || puredao.protocols[7] ? texts.ui.enter + ": " : texts.ui.locked + " ")  + texts.puredao.lobby[j-4][i]);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					if (j == 3 && i == 2) {
						document.body.style.cursor = 'help';
						setMessager(texts.puredao.enlisted, 30);
					}
					if (j != 4 && j != 5) return
					document.body.style.cursor = "default";
					if (j == 4) {
						if (i == 0) {
							setRedirect(<Redirect to={{ pathname: 'matchmaking' }}/>);
						} else if (i == 1 && puredao.protocols[7]) {
							setRoomX(roomX + i);
							setRoomY(roomY + 1);
						} else if (i == 2) {
							setRoomX(roomX + i);
							setRoomY(roomY + 1);
						} else {
							setMessager(texts.puredao.locked, 10, null, true, texts.ui.locked + " " + texts.puredao.lobby[j-4][i]);
						}
					} else if (j == 5) {
						if (i == 0) {
							setRoomZ(1);
							setRoomY(roomY + 1);
							setRoomX(roomX + i);
						} else if (i == 1) {
							setRoomZ(1);
							setRoomY(roomY + 1);
							setRoomX(roomX + i);
						} else if (i == 2) {
							setRoomZ(1);
							setRoomY(roomY + 1);
							setRoomX(roomX + i);
						}
					}
				}}
				material='phong'
				scale={[8,15,1]}
				position={[i*30-30, j*20-80 ,i == 1 ? -60 : -40]}
				rotation={[0, [Math.PI/4, 0, -Math.PI/4][i], 0]} />);
		}
	}

	let npc = [];
	npc.push(<NPC
		scale={1.5}
		offset={2}
		position={[-5, -2, -20]}
		rotation={[0, -Math.PI/3, 0]}
		image={images(`./enemy/face6.jpg`).default}
		geometry={<sphereGeometry attach="geometry" />}
		material='phong'
		onOver={(e) => {
			document.body.style.cursor = "help";
			setCode({
				...code,
				header: 'memory', input: inventory,
				algorithm: puredao.lobby1
			});
			setMessager("", 10, null, false, texts.ui.remember);
		}}
		onClick={(e) => {
			setCoding(true);
			setMessager(texts.ui.remembering, 10, null, true);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
	/>);
	npc.push(<NPC
		scale={1.5}
		position={[6, 2, -20]}
		rotation={[0, -Math.PI/1.5, 0]}
		image={images(`./enemy/face8.jpg`).default}
		geometry={<sphereGeometry attach="geometry" />}
		material='phong'
		onOver={(e) => {
			document.body.style.cursor = "help";
			setCode({
				...code,
				header: 'memory', input: inventory,
				algorithm: puredao.lobby2,
			});
			setMessager("", 10, null, false, texts.ui.remember);
		}}
		onClick={(e) => {
			setCoding(true);
			setMessager(texts.ui.remembering, 10, null, true);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
	/>);


	return (<group>

		<Suspense fallback={<Loader/>}>
      {doors}
			{remembering && npc}
		</Suspense>

	</group>)
}

export default React.memo(Room);
