import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Hallucinogen = ({ images, setMessage, setAvatar, roomX, roomY, roomZ, setRoomX, setRoomZ, setRoomY, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, setGlitchOn, user, H1Monitor, setWait, saveStatChange, setGlitch, setMessager, texts, message, affiliations, addAffiliation, useKeypress, plainroom, lab, setLab, quar, setQuar }) => {

	const [ visible, setVisible ] = useState(true);

	let light = roomY == -96 || roomX == -99 ? <pointLight intensity={0.5} /> : <group></group>;

	let combat = lab.algorithm.find(d=>d.id == 'combat') && roomX == -98;
	let physicalform = lab.algorithm.find(d=>d.id == 'physicalform') && roomX == -98 && !combat;
	let facedata = lab.algorithm.find(d=>d.id == 'facedata') && roomX == -98 && !combat;
	let portal = lab.algorithm.find(d=>d.id == 'portal') && roomX == -98;
	let messagedata = lab.algorithm.find(d=>d.id == 'messagedata') && roomX == -98 && !combat;
	let teleportation = lab.algorithm.find(d=>d.id == 'hyperlink') && roomX == -98 && !combat;
	let omniscience = lab.algorithm.find(d=>d.id == 'npcshell') && roomX == -98 && !combat;
	let socialnorms = lab.algorithm.find(d=>d.id == 'socialnorms') && roomX == -98 && !combat;
	let memory = lab.algorithm.find(d=>d.id == 'memorystore') && roomX == -98 && !combat;

	let scarecrow = quar.algorithm.find(d=>d.id == 'scarecrow') && roomX == -99;

	let entity = [];
	if (visible && physicalform) {
		entity.push(<NPC
			scale={3}
			rotation={[0, 0, 0]}
			position={[0, 0, -20]}
			onOver={(e) => {
				if (roomY == -96 || roomY == -95) {
					document.body.style.cursor = "zoom-out";
					return
				}
				document.body.style.cursor = "help";
				setMessager("", 10, null, false, texts.ui.interact);
			}}
			onOut={(e) => {
				if (roomY == -96 || roomY == -95) return
				document.body.style.cursor = "default"
				setMessager();
			}}
			onClick={(e) => {
				if (roomY == -96 || roomY == -95) return
				document.body.style.cursor = "default";
				if (teleportation) {
					setVisible(false);
				}
				if (!messagedata) {
					setMessager("............................", 30, null, false);
				} else {
					if (!omniscience) {
						setMessager(texts.puredao.dumbmessage, 30, null, false);
					} else {
						if (!socialnorms) {
							if (!memory || lab.memory == 0 || lab.memory == 2) {
								setMessager(texts.puredao.smartmessage, 30, null, false);
							} else if (lab.memory == 1) {
								setMessager(texts.puredao.smartmessage2, 30, null, false);
							}
							if (memory) {
								setLab({...lab, memory: 1});
							}
						} else {
							if (!memory || lab.memory == 0 || lab.memory == 1) {
								setMessager(texts.puredao.politemessage, 30, null, false);
							} else if (lab.memory == 2) {
								setMessager(texts.puredao.politemessage2, 30, null, false);
							}
							if (memory) {
								setLab({...lab, memory: 2});
							}
						}
					}
				}
			}}
			image={images(facedata ? `./avatars/avatar_20.png` : `./checkerboard.png`).default}
			geometry={<planeGeometry attach="geometry" />}/>);
	}
	if (portal) {
		entity.push(<Image
			scale={[10, 12, 1]}
			rotation={[0, -Math.PI/2, 0]}
			position={[19, -3, -15]}
			image={images(`./doors/door40.png`).default}
			onMove={(e) => {
				if (roomX != -98 || roomY != -95) return
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.exit);
			}}
			onOut={(e) => {
				if (roomX != -98 || roomY != -95) return
				document.body.style.cursor = "default"
				setMessager();
			}}
			onClick={(e) => {
				if (roomX != -98 || roomY != -95) return
				document.body.style.cursor = "default";
				setRoomY(roomY - 2);
			}}
			/>);
	}
	if (scarecrow && roomX == -99) {
		entity.push(<NPC
			image={ images(`./scarecrow.png`).default }
			material='phong'
			geometry={<sphereGeometry></sphereGeometry>}
			position={[0, -5, -25]}
			scale={5}
			onOver={(e) => {
				document.body.style.cursor = "zoom-out";;
			}}
			rotation={[0, -Math.PI/2, 0]} />);
	}

	return (<group
		position={roomY == -96 || roomX == -99 ? [15, -5, -5] : [0, 0, 0]}
		rotation={roomY == -96 || roomX == -99 ? [0.5, 0.5, 0] : [0, 0, 0]}
		onPointerOver={(e) => {
			if (roomY == -96 || roomX == -99) document.body.style.cursor = "zoom-out";
		}}
		onPointerOut={(e) => {
			if (roomY == -96 || roomX == -99) document.body.style.cursor = "default";
		}}
		onClick={(e) => {
			if (roomY == -96 || roomX == -99) {
				setGlitchOn(false);
				setRoomY(roomY - 1);
				setMessager();
			}
		}}
		>

		{light}

		<Suspense fallback={<Loader/>}>

			{plainroom}
			{entity}

		</Suspense>


	</group>)
}

export default React.memo(Hallucinogen);
