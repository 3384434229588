import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"
import Shooter from "../components/Shooter.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets/', true);

const Gatekeeper = () => {

  const [ puredao, setPuredao ] = useGetAndSet('puredao');
  const [ language, setLanguage ] = useGetAndSet('language');
  const [ equipped, setEquipped ] = useGetAndSet('equipped');
  const [ inventory, setInventory ] = useGetAndSet('inventory');

  const [ redirect, setRedirect ] = useState(null);
  const [ debugging, setDebugging ]  = useState(true);
  const [ added, setAdded ]  = useState(false);
  const [ message, setMessage ] = useState("");
  const [ coding, setCoding ] = useState(false);
  const [ code, setCode ] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} setMessager={() => {setMessage("")}} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    <Shooter texts={texts[language]} visible={true} setMessager={() => {setMessage("")}} shooting={false} clip={8} setClip={() => {setMessage("")}} code={code} setCode={setCode} setCoding={setCoding} />,

    (<div className='egrecorp offline'>
      {<div>
        <img className='alertimage' src={images('./echo/pages/right_1.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"MATCH WON!"} /></u>
        <i><Writer delay={1000} speed={10} msg={"The CODE you proposed to " + puredao.stance.toUpperCase() + " has been " + puredao.stance.toUpperCase() + "ED"}/></i><br></br>
        <Writer delay={2000} msg={`You proposed to ${puredao.stance.toUpperCase()} «${texts[language].puredao.protocols[puredao.contesting]}» and nobody arrived to dispute you`} /><br></br>
        <Writer delay={4000} msg={"Play another round?"} /><br></br>
        <Delayer delay={5000}>
          <NavLink onClick={() => {
              let temp = puredao.protocols;
              if (puredao.stance == 'amend') temp[puredao.contesting] = !temp[puredao.contesting];
              setPuredao({...puredao, protocol: temp});
              setRedirect(<Redirect to='matchmaking'/>);
            }}>{"YES, return to MATCHMAKING"}</NavLink><br></br>
          <NavLink onClick={() => {
              let temp = puredao.protocols;
              if (puredao.stance == 'amend') temp[puredao.contesting] = !temp[puredao.contesting];
              setPuredao({...puredao, protocol: temp});
              setRedirect(<Redirect to={{ pathname: '/echo', state: {room: [-100, -99, 0] }
            }}/>); }}>{"NO, exit to PureCAO LOBBY"}</NavLink>
        </Delayer>
      </div>}

      {redirect}

    </div>)

  ]);

};

export default Gatekeeper;
