import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Redirect } from "react-router-dom";

import PipeEnd from "./PipeEnd"
import PipeUp from "./PipeUp"
import PipeT from "./PipeT"
import PipeL from "./PipeL"
import PipeR from "./PipeR"
import PipeLFork from "./PipeLFork"
import PipeRFork from "./PipeRFork"
import PipeRoom from "./PipeRoom"
import PipeValve from "./PipeValve"

import Image from "../Image.js"
import Loader from "../Loader.js"
import Blockhead from "../Blockhead.js"
import NPC from "../NPC.js"

const pageWidth = 30;
const pageHeight = 20;
const pageDepth = -40;
const linkDepth = pageDepth + 10;

const HallRoom = ({ reported, images, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, seeds, wait, saveStatChange, armed, setMessager, texts, useKeypress, blackbox, setBlackbox, setCoding, inventory, affiliations, plainroom, setGlitchOn, H1Chest, H1Monitor, go, blackboxarray, hintersteps, setHintersteps, stalker, setStalker, setRedirect }) => {

	const gate = 0.2;

	useEffect(() => {
		const interval = setInterval(() => {
			let dx = stalker[0] - roomX;
			let dy = stalker[1] - roomY;
			let newValue = Math.floor(-21 * (20 -	(Math.abs(dx) + Math.abs(dy))));
			if (newValue <= -300) {
				setRedirect(<Redirect to={{ pathname: '/flatlined', state: {cause: 'antivalue'} }} />);
			} else if (newValue < -220 && !coding) {
				setMessager(texts.oceandao.antivalue, 10)
			} else if (newValue < -30 && !coding) {
				setMessager(texts.oceandao.antiv, 10)
			} else if (newValue < 0 && !coding) {
				setMessager(texts.oceandao.antivalue, 10)
			}
			setStalker([
				stalker[0] + (dx > 0 ? -gate : gate),
				stalker[1] + (dy > 0 ? -gate : gate),
				newValue < 0 ? newValue : null
			]);
		}, 1000);
		return () => clearInterval(interval);
	});

	useEffect(() => {
		let dx = stalker[0] - roomX;
		let dy = stalker[1] - roomY;
		let newValue = Math.floor(-21 * (20 -	(Math.abs(dx) + Math.abs(dy))));
		if (newValue <= -300) {
			// setRedirect(<Redirect to={{ pathname: '/flatlined', state: {cause: 'antivalue'} }} />);
		} else if (newValue < -220) {
			setMessager(texts.oceandao.antivalue, 10)
		}
		setStalker([
			stalker[0],
			stalker[1],
			newValue < 0 ? newValue : null
		]);
	}, [roomX, roomY]);

	let hall = null;
	let special = [];

	let option = Math.round(seeds[0] * 10);
	if (option == 0) hall = <PipeUp texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 1) hall = <PipeUp texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 2) hall = <PipeR texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 3) hall = <PipeL texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 4) hall = <PipeT texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 5) hall = <PipeLFork texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 6) hall = <PipeRFork texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 7) hall = <PipeR texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 8) hall = <PipeL texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 9) hall = <PipeUp texts={texts} setMessager={setMessager} go={go}/>;
	else if (option == 10) hall = <PipeUp texts={texts} setMessager={setMessager} go={go}/>;
	else hall = <PipeUp texts={texts} setMessager={setMessager} go={go}/>;

	if (roomY == -1) {
		hall = <PipeEnd texts={texts} setMessager={setMessager} go={go}/>;
	}

	let dreadcrumb = [];
	if (option != 0 && option != 5 && option != 6 && option != 9 && seeds[1] < -stalker[2]/300) dreadcrumb = (
		<NPC
			scale={1}
			position={[seeds[2] * 2 - 1, seeds[2] * 2 - 1, -5]}
			offset={seeds[3]}
			rotation={[0, 0, 0]}
			image={images(`./echo/pages/curse_57.png`).default}
			material='phong'
			onMove={(e) => {
				document.body.style.cursor = "help";
				setCode({
					...code,
					header: 'memory', input: inventory,
					algorithm: [{id: 'dreadcrumb'}],
				});
				setMessager("", 10, null, false, texts.ui.remember);
			}}
			onClick={(e) => {
				setCoding(true);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
		/>
	);

	return (<group>

		<Suspense fallback={<Loader/>}>
			{dreadcrumb}
			{hall}
		</Suspense>

	</group>)
}

export default React.memo(HallRoom);
