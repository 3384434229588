import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Room = ({ images, setMessage, setAvatar, roomY, setRoomY, setRoomZ, clip, setSpeed, code, setCode, go, setMessager, texts, setLifespan, lifespan, setRedirect, equipped }) => {

	useEffect(() => {
		setMessager(texts.stackdao.entered, 30, null, true);
	}, []);

	let columns = [];
	for (var i = 0; i < 10; i++) {
		for (var j = 0; j < 2; j++) {
			columns.push(<mesh
				position={[-40 + (j * 80), -10, -475 + i * 50]}
				rotation={[0, 0, 0]}>
				<cylinderGeometry args={[10,400,10]} />
      	<meshPhongMaterial wireframe />
			</mesh>);
		}
	}

	return ([

		<Suspense fallback={null}>
			<NPC
				material='wireframe'
				dampenRotation={10}
				spin={0.2}
				geometry={<boxGeometry></boxGeometry>}
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, "ENTER");
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					document.body.style.cursor = "default";
					let captcha = equipped.find(d=>d.id == 'captcha');
					if (captcha != null && captcha.nft) setRoomY(roomY + 1);
					else setRedirect(<Redirect to={{ pathname: '/captcha' }} />);
				}}
				repeat={true}
				textureRepeat={20}
				scale={15}
				position={[0, 10, -50]}
				image={ images(`./tiles.jpg`).default } />

			{columns}

			{/*<Image scale={40} position={[0, 0, -20]} image={ images(`./echo/${X.toString() + Y.toString()}.png`).default } />*/}
			{/*<Image image={ images(`./echo/av1.png`).default } />*/}

		</Suspense>

	])
}

export default memo(Room);
