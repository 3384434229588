import React from 'react'

const items = [
	{
		'name': "debugger",
		'price': "EGRECORE Systems",
		'trustworthReq': 0,
		'networthReq': 0,
		'description': "Algorithms are the bedrock of a functional society, but they must be accountable to human control. The DEBUGGER shows you how these forces are operating behind the scenes to shape the environment."
	},
	{
		'name': "memoryblock",
		'price': "EGRECORE Systems",
		'trustworthReq': 0,
		'networthReq': 10,
		'description': "Like all societies, digital societies live and die. To remember the past and make sure history doesn't repeat itself, the MEMORYBLOCK can detect expired chat messages and error logs to play them back to you."
	},
	{
		'name': "loyalist",
		'price': "PureDAO",
		'trustworthReq': 20,
		'networthReq': 0,
		'description': "PureDAO evolved to take a unique approach to governance: virtual combat. The LOYALIST is a standard issue non-fungible armament that can also be used for defense against minor machine agencies and other nuisances."
	},
	{
		'name': "valuator",
		'price': "OceanDAO",
		'trustworthReq': 20,
		'networthReq': 0,
		'description': "Governance in OceanDAO rests on a sophisticated VALUE market, with options that users can stake their own SELF-VALUE on. The VALUATOR provides a convenient display of VALUE so that users can navigate this governance economy."
	}
]

export default items
