import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, setAvatar, roomX, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, setCoding, islands, destination, texts, equipped, inventory }) => {

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				key={'elevator'}
				geometry={<boxGeometry attach="geometry" />}
				material='phong'
				image={images(`./echo/yacht.png`).default}
				repeat={false}
				position={[0, -3, -50]}
				scale={[200, 140, 1]}
				rotation={[0, 0, 0]} />
			</Suspense>
			<mesh
				position={[8, 0, -25]}
				scale={[18, 20, 1]}
				visible={false}
				onPointerOver={() => {
					document.body.style.cursor = "context-menu";
					setCode({
            ...code,
            'header': 'boat',
            'input': equipped.find(d=>d.id == 'valuator') || inventory.find(d=>d.id == 'valuator') ? [{id: 'clan1'}] : [{id: 'clan0'}],
            'algorithm': []
          });
					setMessager("", 10, null, false, texts.ui.debug);
				}}
				onPointerOut={() => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={() => {
					setCoding(true);
				}}>
				<boxGeometry/>
			</mesh>

	</group>)
}

export default React.memo(Room);
