import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));

  return (
      <Plane args={[100, 100]} ref={ref}>
        <meshBasicMaterial opacity={0} transparent />
      </Plane>
  );
}

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, roomX, roomY, setHeaded, texts, puredao, equipped, inventory, empiredao, visualData }) => {

	let columns = [];
	for (var i = 0; i < 2; i++) {
		for (var j = 0; j < 2; j++) {
      columns.push(<Image
				image={images(visualData ? `./chest.png`: `./checkerboard.png`).default}
				geometry={<cylinderBufferGeometry args={[0.2,0.2,4]} attach="geometry" />}
				repeat={false}
				position={[-20 + (j * 40), -3, -40 + i * 20]}
				scale={[5, 2, 5]}
				rotation={[0, 0, 0]} />);
			columns.push(<Image
				material='phong'
        geometry={<sphereGeometry/>}
				image={images(visualData ? `./echo/pages/curse_11.png`: `./checkerboard.png`).default}
				repeat={false}
				position={[-20 + (j * 40), 10, -38 + i * 20]}
				scale={10}
				rotation={[0, i * j * Math.PI/3, 0]} />);
      columns.push(<Image
        geometry={<sphereGeometry/>}
				image={images(visualData ? `./echo/pages/curse_11.png`: `./checkerboard.png`).default}
				repeat={false}
				position={[-10 + (j * 20), -7, -38 + i * 20]}
				scale={2}
				rotation={[0, i * j * Math.PI/3, 0]} />);
		}
	}

  let tombstones = [];
	for (let i = 0; i < texts.empiredao.shames.length; i++) {
		tombstones.push(<Image
      material='phong'
			image={images(visualData ? `./doors/door7.png`: `./checkerboard.png`).default}
      onOver={(e) => {
        document.body.style.cursor = "help";
        setCode({
          ...code,
          header: 'memory', input: inventory,
          algorithm: [{id: 'shame'}],
        });
        setMessager("", 10, null, false, texts.ui.remember);
      }}
      onClick={(e) => {
        setCoding(true);
        setMessager(texts.ui.tombstone, 10, null, true);
      }}
      onOut={(e) => {
        document.body.style.cursor = "default";
        setMessager();
      }}
			repeat={false}
			position={[-20 + (i/3) * 20, -5, -10 - ((texts.empiredao.shames.length - i)%3)*10]}
			scale={[3,3.5,2]}
			rotation={[-0.2 + seeds[i*3] * 0.4, -0.2 + seeds[i*3+1] * 0.4, -0.2 + seeds[i*3+2] * 0.4]}/>);
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
      <Image
        image={images(visualData ? `./doors/door10.png`: `./checkerboard.png`).default}
				onMove={(e) => {
					document.body.style.cursor = "w-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
          document.body.style.cursor = "default";
          setMessager();
        }}
				onClick={(e) => {
					setRoomX(roomX - 1);
				}}
				material='phong'
        repeat={false}
				position={[-10, -3.4, -20]}
				scale={10}
				rotation={[0, -Math.PI / 2, 0]} />
      {<group><Image
				image={ images(visualData ? `./brick.jpg`: `./checkerboard.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={2}
				position={[-11, 26, -30]}
				scale={68}
				rotation={[0, -Math.PI / 2, 0]} />
      </group>}
      {<group position={[6,0,0]}>
        <Image
				key='floor'
				image={ images(visualData ? `./echo/pages/curse_${40 + Math.floor(seeds[0] * 20)}.png`: `./checkerboard.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={1}
				position={[0, -7, -10]}
				scale={[350, 150, 1]}
				rotation={[-Math.PI / 2, 0, 0]} />
			{columns}
      <mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setRoomX(100);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>
      <NPC
    		scale={1.5}
    		position={[-7, 0, -20]}
    		rotation={[0, -Math.PI/2, 0]}
    		image={images(visualData ? `./enemy/face1.jpg`: `./checkerboard.png`).default}
    		geometry={<sphereGeometry attach="geometry" />}
    		material='phong'
        onOver={(e) => {
					document.body.style.cursor = "help";
					setCode({
						...code,
						header: 'memory', input: inventory,
						algorithm: [{id: 'lantern'}, {id: 'shame'}],
					});
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.remembering, 10, null, true);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
    	/>
      {tombstones}
      <NPC
        scale={1.5}
        position={[13, 0, -25]}
        offset={1}
        rotation={[0, -Math.PI/1.5, 0]}
        image={images(visualData ? `./enemy/face26.jpg`: `./checkerboard.png`).default}
        geometry={<sphereGeometry attach="geometry" />}
        material='phong'
        onOver={(e) => {
					document.body.style.cursor = "help";
					setCode({
						...code,
						header: 'memory', input: inventory,
						algorithm: empiredao.graveyardGirl,
					});
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.remembering, 10, null, true);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
      />
      </group>}
    {<Image
        material='phong'
        image={images(visualData ? `./doors/door24.png`: `./checkerboard.png`).default}
        onMove={(e) => {
          document.body.style.cursor = "ne-resize";
          setMessager("", 10, null, false, texts.ui.enter);
        }}
        onOut={(e) => {
          document.body.style.cursor = "default";
          setMessager();
        }}
        onClick={(e) => {
          setRoomX(roomX + 1);
        }}
        repeat={false}
        position={[13, -3, -45]}
        scale={20}
        rotation={[0, -Math.PI / 6, 0]} />}
			</Suspense>

	</group>)
}

export default React.memo(Room);
