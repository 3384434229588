import React, { useState, useEffect } from 'react';

type Props = {
  children: React.ReactNode;
  waitBeforeShow?: number;
};

const Delayer = ({ children, delay = 500 }: Props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, delay);
    return () => clearTimeout(timeout)
  }, [delay]);

  return isShown ? children : null;
};

export default Delayer;
