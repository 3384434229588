import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import Blockhead from "../Blockhead.js"

const pageWidth = 30;
const pageHeight = 20;
const pageDepth = -40;
const linkDepth = pageDepth + 10;

const PageRoom = ({ reported, images, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, seeds, wait, saveStatChange, armed, setMessager, texts, useKeypress, go, saveEquipped, inventory, setInventory, up, down, left, right, setRedirect, enemy }) => {

	let section = null;
	if (roomX < 0) {
		section = 'curse';
	} else {
		section = 'right';
	}

	let enemies = [];
	if (enemy != null) {
		for (var i = 0; i < enemy; i++) {
			enemies.push(<Blockhead spawn={[
				seeds[1 + i] * 50 - 25,
				seeds[8 + i] * 15,
				seeds[3 + i] * 20 -60
			]}
			key={'blockhead' + '_' + Math.abs(roomY) + '_' + i}
			face={Math.floor(seeds[20 - i] * 11) + 1}
			armed={armed}
			doGlitch={doGlitch} stopGlitch={stopGlitch} clip={clip} setMessager={setMessager} setSpeed={setSpeed} setRoomX={setRoomX} setRoomY={setRoomY} saveStatChange={saveStatChange} setRedirect={setRedirect} inventory={inventory} setInventory={setInventory} />);
		}
	}

	function deactivate() {
		setCode({
			...code,
			header: 'shadowrealm',
			algorithm: [],
		});
	};

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./echo/pages/curse${Math.floor(seeds[0] * 33)}.png`).default }
				material='phong'
				position={[0, 0, pageDepth]}
				scale={100}
				rotation={[0, 0, 0]} />
			{left && <Image
				image={ images(`./echo/pages/${section}_${Math.floor(seeds[1] * 20) + 0}.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "w-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					deactivate();
					go('l');
				}}
				material='phong'
				position={[seeds[5] * pageWidth - pageWidth/2, seeds[6] * pageHeight - pageHeight/2, linkDepth]}
				rotation={[0, 0, 0]} />}
			{right && <Image
				image={ images(`./echo/pages/${section}_${Math.floor(seeds[2] * 20) + 20}.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "e-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					deactivate();
					go('r');
				}}
				material='phong'
				position={[seeds[7] * pageWidth - pageWidth/2, seeds[8] * pageHeight - pageHeight/2, linkDepth]}
				rotation={[0, 0, 0]} />}
			{up && <Image
				image={ images(`./echo/pages/${section}_${Math.floor(seeds[3] * 20) + 40}.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, texts.ui.exit);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					//deactivate();
					if (roomX < 0 && roomY > 0 && roomZ == -1) {
						setRoomX(-100);
						setRoomY(102);
						setRoomZ(0);
					} else if (document.body.style.cursor != 'crosshair') {
						go('u');
					}
				}}
				material='phong'
				position={[seeds[9] * pageWidth - pageWidth/2, seeds[10] *pageHeight -pageHeight/25, linkDepth]}
				rotation={[0, 0, 0]} />}
			{down && <Image
				image={ images(`./echo/pages/${section}_${Math.floor(seeds[4] * 20) + 60}.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					deactivate();
					go('d');
				}}
				scale={5}
				material='phong'
				position={[seeds[11] * pageWidth - pageWidth/2, seeds[12] *pageHeight -pageHeight/25, linkDepth]}
				rotation={[0, 0, 0]} />}
			{enemies}
		</Suspense>



	</group>)
}

export default React.memo(PageRoom);
