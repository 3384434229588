import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { Redirect } from "react-router-dom";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, roomX, roomY, setHeaded, texts, remembering, setRedirect, puredao, quar, setQuar }) => {

	let scarecrow = quar.algorithm.find(d=>d.id == 'portal');

	let monitors = [];
	for (let i = 0; i < 7; i++) {
		for (let j = 0; j < 5; j++) {
			if (i * 10 - 30 == 0 && j * 10 == 0) continue
			monitors.push(<Image
				scale={[5, 4, 2.5]}
				rotation={[0, 0, 0]}
				position={[i * 10 - 30, j * 10, -30]}
				image={images(`./products/monitor.png`).default}
				material='phong'
				repeat={false}
				castShadow={true}
				receiveShadow={true} />);
		}
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./checkerboard.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.exit);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					document.body.style.cursor = "default";
					setRoomX(-100);
					setRoomY(-99);
				}}
				repeat={true}
				material='phong'
				scale={68}
				position={[0, -15, 0]}
				rotation={[Math.PI/2, 0, 0]} />
			<group onPointerMove={(e) => {
				document.body.style.cursor = "not-allowed";
				setMessager("", 10, null, false, texts.ui.missing);
			}}
			onPointerOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}>{monitors}</group>
			<Image
				scale={[5, 4, 2.5]}
				rotation={[0, 0, 0]}
				position={[0, 0, -30]}
				image={images(`./products/monitor.png`).default}
				material='phong'
				onMove={(e) => {
					document.body.style.cursor = "zoom-in";
					setCode({
						...code,
						'header': 'quarantine',
						'input': [],
						'algorithm': quar.algorithm,
					});
					setMessager("", 10, null, false, texts.ui.view);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomY(roomY + 1);
					setCoding(true);
					setMessager(texts.puredao.make, 40, images('./scarecrow.png').default, true);
					if (quar.algorithm.find(d=>d.id == 'scarecrow'));
				}}
				repeat={false}
				castShadow={true}
				receiveShadow={true} />

		</Suspense>

	</group>)
}

export default React.memo(Room);
