import React, { useState, useEffect, memo } from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import Writer from "../components/Writer.js"

const Voter = ({ voting, setVoting, activeProposals, governance, trust, saveStatChange }: Props) => {
  const [isShown, setIsShown] = useState(false);
	const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  function vote(index, v) {
    let temp = voting.slice();
    temp[index].voted = v;
    setVoting(temp.slice());
  }

  function stake(index, v) {
    let temp = voting.slice();
    temp[index].stake += v;
    if (temp[index].favor + temp[index].against + Math.abs(temp[index].stake) > 1000) {
      temp[index].majorityVote();
    }
    setVoting(temp.slice());
  }

  return (isAuthenticated) ?
	<div className='voter'>
    <span style={{ borderBottom: '1px dotted #fae8bf' }}>{'Governance Mode: ' + (governance == null ? 'ANARCHY' : governance.toUpperCase())}</span><br></br>
    {voting.map(function(d,i){
      let text = [<span>{d.text}</span>];
      if (governance == 'lazy') {
        if ('passed' in d) {
          text = [<span>{"PROPOSAL PASSED!"}</span>];
        } else if ('rejected' in d) {
          text = [<span>{"PROPOSAL REJECTED BY VOTE!"}</span>];
        } else {
          if ('challenged' in d) {
            text = [<span>{"PROPOSAL CHALLENGED! Collecting votes..."}</span>];
            if ('voted' in d) {
              text = text.concat(<span>{" VOTED!"}</span>);
            } else {
              text = text.concat(<span> <a onClick={() => {vote(i, 1)}}>YAY</a> <a onClick={() => {vote(i, 0)}}>NAY</a></span>);
            }
          }
        }
      } else if (governance == 'holographic') {
        text.push(<br></br>);
        text.push(trust > 0 ? <span>{"YAY x"}</span> : <a onClick={() => {vote(i, 1)}}>YAY</a>);
        if (trust >= d.stake + 1) text.push(<a onClick={() => {stake(i, 1)}}>1</a>, <span>{" "}</span>)
        if (trust >= d.stake + 10) text.push(<a onClick={() => {stake(i, 10)}}>10</a>, <span>{" "}</span>)
        if (trust >= d.stake + 100) text.push(<a onClick={() => {stake(i, 100)}}>100</a>)
        text.push(trust > 0 ? <span>{" NAY x"}</span> : <span>{" "}<a onClick={() => {vote(i, 0)}}>NAY</a></span>);
        if (trust >= Math.abs(d.stake) + 1) text.push(<a onClick={() => {stake(i, -1)}}>1</a>, <span>{" "}</span>)
        if (trust >= Math.abs(d.stake) + 10) text.push(<a onClick={() => {stake(i, -10)}}>10</a>, <span>{" "}</span>)
        if (trust >= Math.abs(d.stake) + 100) text.push(<a onClick={() => {stake(i, -100)}}>100</a>)
        if (d.stake != 0) text.push(<br></br>,<span>{"You staked " + Math.abs(d.stake) + "TW on " + (d.stake > 0 ? "YAY" : "NAY") + "!"}</span>);
        if ('passed' in d) {
          text = [<span>{"PROPOSAL PASSED!"}</span>];
          if (d.stake > 0) {
            text.push(<br></br>,<span>{"You earned your stake of " + d.stake.toString() + "TW!"}</span>);
          } else if (d.stake < 0) {
            text.push(<br></br>,<span>{"You lost your stake of " + Math.abs(d.stake).toString() + "TW!"}</span>);
          }
        } else if ('rejected' in d) {
          text = [<span>{"PROPOSAL REJECTED!"}</span>];
          if (d.stake < 0) {
            text.push(<br></br>,<span>{"You earned your stake of " + Math.abs(d.stake).toString() + "TW!"}</span>);
          } else if (d.stake > 0) {
            text.push(<br></br>,<span>{"You lost your stake of " + d.stake.toString() + "TW!"}</span>);
          }
        } else {
          if ('invested' in d) {
            text = [<span>{"PROPOSAL INVESTED! Simple majority wins..."}</span>];
            if ('voted' in d) {
              text = text.concat(<span>{" VOTED!"}</span>);
            } else if (d.stake != 0) {
              text = text.concat(<span>{" (You have already staked a vote)"}</span>);
            } else {
              text = text.concat(<span> <a onClick={() => {vote(i, 1)}}>YAY</a> <a onClick={() => {vote(i, 0)}}>NAY</a></span>);
            }
          }
        }
      }
      return <span>
        {"> "}{text}
        <br></br>
      </span>
    })}
	</div>
	: null;
};

export default memo(Voter);
