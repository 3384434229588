import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore, useGetAndSet } from "react-context-hook";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useKeypress from 'react-use-keypress';
import axios from "axios";

import Writer from "../components/Writer.js"
import Debugger from "../components/Debugger.js"
import Shooter from "../components/Shooter.js"
import Coder from "../components/Coder.js"

import logo from "../assets/logo-egrecorp.png"
import face1 from "../assets/face-1.png"
import face2 from "../assets/face-2.png"
import parchment from "../assets/texture-parchment.png"
import door from "../assets/doors/door-note.png"
import vendor from "../assets/vendor.png"
import stars from "../assets/bg-stars.png"
import handle from "../assets/net-handle.png"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const images = require.context('../assets', true);

const Home = () => {
  const [ equipped, setEquipped ] = useGetAndSet('equipped');
  const [ inventory, setInventory ] = useGetAndSet('inventory');
  const [ bones, setBones ] = useGetAndSet('bones');
  const [ latestRoom, setLatestRoom ] = useStore('latestRoom');
  const [ getCodes, setCodes, deleteCodes ] = useStore('codes');
  const [ manifesto, setManifesto ] = useState(false);
  const [ exorcism, setExorcism ] = useState(false);
  const [ message, setMessage ] = useState("");

  function toggleManifesto() {
    setManifesto(!manifesto);
  }

  function toggleExorcism() {
    setExorcism(!exorcism);
  }

  const [trust, setTrust] = useState(0);
  const [coding, setCoding] = useState(false);
  const [code, setCode] = useState({
    'header': null,
    'input': [],
    'algorithm': [],
    'output': [],
    'inventory': []
  });

  const manifestoSubtitle = "You need 99999 TRUSTWORTH to suggest amendments to this public CODE";
  const manifestoText = "EGRECORP is an organization of innovators in ALGORITHMIC GOVERNANCE. We take complex political systems and distill them into CODE: rules that can be used to make beautiful interactive environments, addictive democratic processes, and ethical forms of punishment. Here, CODE IS LAW, and you can solve political problems just by knowing how to program them. Welcome to paradise!";
  const oldManifestoText = "People fear algorithms more than ever. But for us, they simply do not know how to use the internet. Fear is a failure to understand how to master your destiny through technology. ======================================== Algorithms today are not accountable to our social and political needs – they are opaque and designed by evildoers. Our mission is to develop tools that allow algorithms to be treated in terms of something that we are truly lacking today: laws. ======================================== There is nothing about human behavior, politics, the environment, the cosmos, ideas, animals, feelings, or evil that prevents it from being computed and treated as a computational problem. They all obey rules, laws, and norms - the task is to use computers to master them. ======================================== EGRECORP is creating computational systems for a better life and society. The point is not to make a perfect system – experimentation is necessary. But by making the codes that run these social systems transparent and opening them up to participatory change, we can ensure progress toward the solutions we need.";

  let debugging = equipped.find(d=>d.id == 'debugger');

  return ([
    (!manifesto && debugging && <Debugger code={code} setCode={setCode} setMessage={setMessage} message={message} images={images} governance={'exception'} />),(!manifesto && <Shooter />),
    <Coder governance={'exception'} visible={coding} code={code} setCode={setCode} setCoding={setCoding} voting={[]} setMessager={() => {setMessage("")}} trust={trust} />,
    <div className='egrecorp home' style={{ backgroundImage: `url(${stars})`, height: '100vh' }}>

      <img
        src={ logo }
        style={{ position: 'absolute', width: '5vw', left: '45vw', top: '5vh', filter: 'brightness(20%)' }}>
      </img>
      <NavLink
        tag={RouterNavLink}
        to={{ pathname: 'enter' }}
        onMouseOut={() => {
          setMessage("");
        }}
        onMouseOver={ () => {
          if (!manifesto) {
            setMessage("E.C.H.O. Chamber");
          }
        }}>
        <img
          src={door}
          style={{ position: 'absolute', width: '15vw', left: '40vw', top: '10vh', filter: 'brightness(50%)' }}>
        </img>
      </NavLink>
      <NavLink
        tag={RouterNavLink}
        to='services'
        onMouseOver={ () => {
          if (!manifesto) {
            setMessage("Our Values");
            setCode({
              ...code,
              'header': 'vendor',
              'input': [],
              'algorithm': [{id: 'vendor', content: 'vendor', alt: 'click ? travel(products)', trustreq: 9999}],
              'output': [],
            });
          }
        }}>
        <img
          src={vendor}
          style={{ position: 'absolute', width: '20vw', right: '10vw', top: '15vh', filter: 'brightness(50%)' }}>
        </img>
      </NavLink>
      <NavLink
        onClick={ toggleManifesto.bind(this) }
        onMouseOut={() => {
          setMessage("");
        }}
        onMouseOver={ () => {
          if (!manifesto) {
            setMessage("Our manifesto");
            setCode({
              ...code,
              'header': 'manifesto',
              'input': [],
              'algorithm': [{id: 'manifesto', content: 'manifesto', alt: 'click ? play(manifesto)', trustreq: 9999}],
              'output': [],
            });
          }
        }}>
        <img
          style={{ position: 'absolute', width: '5vw', left: '30vw', top: '25vh', filter: 'brightness(30%)' }}
          src={ parchment }>
        </img>
      </NavLink><br></br>
    <img
      src={ handle }
      onMouseOver={ () => {
        if (!coding && debugging) {
          document.body.style.cursor = 'context-menu';
          setMessage("UNSTRUCTURED algorithm detected. Click to DEBUG.");
          setCode({
            ...code,
            'header': 'bones',
            'input': inventory,
            'algorithm': bones
          });
         }}
      }
      onClick={() => {
        if (debugging) {
          setMessage("");
          setCoding(true);
        }
      }}
      onMouseOut={() => {
        document.body.style.cursor = '';
        setMessage("");
      }}
       style={{ position: 'absolute', width: '15vw', top: '60vw', top: '50vh', filter: 'brightness(70%)' }}>
     </img>
     <div style={{ position: 'absolute', bottom: '10px', right: '20px' }}>{"©1996 EGRECORP"}</div>

      { manifesto ? <div className='manifesto' style={{ backgroundImage:`url(${parchment})` }}>
        <Writer delay={0} speed={10} msg={"Welcome to EGRECORP's page!"} /><br></br>
        <Writer delay={1000} speed={40} msg={manifestoText} />
        <br></br>
        <a style={{ position: 'relative', top: '0', right: '0' }}onClick={ toggleManifesto.bind(this) }>CLOSE</a>
      </div> : <div></div>}



      {/*<div style={{ position: 'fixed', bottom: '25px', right: '15px', width: '100px' }}>
        <img src={creature} style={{ height: '80px' }}></img>Have you seen this creature? Please <a href='https://www.dhs.gov/see-something-say-something/how-to-report-suspicious-activity'>tell us</a> WHEN and WHERE.

      </div>*/}

  </div>


]);

};

export default Home;
