import React, { Fragment, Suspense, useMemo, useEffect, memo, useRef, useState } from "react";
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Church = ({ images, setMessage, setAvatar, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, setWait, setMessager, seeds, affiliations, texts, go, cols, wins, ldoor, rdoor, udoor, banners, equipped, setRedirect, face, speech, position, art, ltext, rtext, utext, carpet, back, inventory, loadedBubble, setCoding, scripture, empiredao, visualData }) => {

	const [ coded, setCoded ] = useState(false);

	useEffect(() => {
		if (coded && !coding) {
			setRoomZ(0);
		}
	}, [coding]);

	useEffect(() => {
		if (!equipped.find(d=>d.id == 'lantern') && !equipped.find(d=>d.id == 'bubble')) setRedirect(<Redirect to={{ pathname: 'reflecting', state: {route: [roomX, roomY, roomZ]} }} />);
		if (roomX == 100 && roomY == 101) setMessager(texts.report.empiredao, 30, null, true);
	}, []);

	useEffect(() => {
		if (!equipped.find(d=>d.id == 'lantern') && !equipped.find(d=>d.id == 'bubble')) setRedirect(<Redirect to={{ pathname: 'reflecting', state: {route: [roomX, roomY, roomZ]} }} />);
	}, [code]);

	let benches = [];
	if (scripture) {
		for (let i = 0; i < 4; i++) {
			for (let j = 0; j < 2; j++) {
				benches.push(<Image
					scale={[1, 3, 7]}
					rotation={[0, Math.PI / 2, 0]}
					position={[-5 + j * 10, -5, -25 + i * 5]}
					image={images(visualData ? `./brick.jpg` : `./checkerboard.png`).default}
					geometry={<boxGeometry attach="geometry" />}
					material='phong'
					repeat={true}
					castShadow={true}
					textureRepeat={1}
					receiveShadow={true} />);
			}
		}
	}

	let columns = [];

	if (cols == 2) {
		for (var i = 0; i < 4; i++) {
			for (var j = 0; j < 2; j++) {
				columns.push(<Image
					material='phong'
					image={images(visualData ? `./chest.png` : `./checkerboard.png`).default}
					geometry={<cylinderBufferGeometry args={[0.2,0.2,4]} attach="geometry" />}
					scale={[2, 2, 2]}
					repeat={false}
					position={[-20 + (j * 40), 10, -40 + i * 15]}
					scale={10}
					rotation={[0, 0, 0]} />);
			}
		}
	} else if (cols) {
		for (var i = 0; i < 2; i++) {
			for (var j = 0; j < 2; j++) {
				columns.push(<Image
					material='phong'
					image={images(visualData ? `./chest.png` : `./checkerboard.png`).default}
					geometry={<cylinderBufferGeometry args={[0.2,0.2,4]} attach="geometry" />}
					scale={[2, 2, 2]}
					repeat={false}
					position={[-20 + (j * 40), 10, -40 + i * 15]}
					scale={10}
					rotation={[0, 0, 0]} />);
			}
		}
	}
	if (banners != null) {
		for (var i = 0; i < 4; i++) {
			for (var j = 0; j < 2; j++) {
				columns.push(<Image
					material='phong'
					image={images(visualData ? `./echo/pages/${banners}.png` : `./checkerboard.png`).default}
					scale={30}
					repeat={false}
					position={[-20 + (j * 40), 10, -65 + i * 15]}
					scale={[5,20,1]}
					rotation={[0, 0, 0]} />);
			}
		}
	}

	let doors = [];

	if (udoor) {
		doors.push(<Image
			material='phong'
			image={images(visualData ? `./doors/door10.png` : `./checkerboard.png`).default}
			onOver={(e) => {
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.enter + (utext ? ": " + utext : ""));
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomY(roomY + 1);
			}}
			repeat={false}
			position={[0, -3, udoor && utext == null ? -60 : -40]}
			scale={10}
			rotation={[0, 0, 0]} />);
	}
	if (ldoor) {
		doors.push(<Image
			key={'leftdoor'}
			material='phong'
			image={images(visualData ? `./doors/door10.png` : `./checkerboard.png`).default}
			onOver={(e) => {
				document.body.style.cursor = "w-resize";
				setMessager("", 10, null, false, texts.ui.enter + (ltext ? ": " + ltext : ""));
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX - 1);
			}}
			repeat={false}
			position={[-19.9, -3, -30]}
			scale={10}
			rotation={[0, Math.PI / 2, 0]} />);
	}
	if (rdoor) {
		doors.push(<Image
			key={'rightdoor'}
			material='phong'
			image={images(visualData ? `./doors/door10.png` : `./checkerboard.png`).default}
			onOver={(e) => {
				document.body.style.cursor = "e-resize";
				setMessager("", 10, null, false, texts.ui.enter + (rtext ? ": " + rtext : ""));
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX + 1);
			}}
			repeat={false}
			position={[19.9, -3, -30]}
			scale={10}
			rotation={[0, Math.PI / 2, 0]} />);
	}

	let windows = [];

	if (wins == 2) {
		windows.push(<Image
			material='phong'
			image={images(visualData ? `./echo/lancet2.png` : `./checkerboard.png`).default}
			repeat={false}
			position={[0, 20, -60]}
			rotation={[0, 0, 0]} />);
	} else if (wins) {
		windows.push(<Image
			material='phong'
			image={images(visualData ? `./echo/lancet.png` : `./checkerboard.png`).default}
			repeat={false}
			position={[0, 20, -60]}
			rotation={[0, 0, 0]} />);
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			{<group><Image
				image={ images(visualData ? `./brick.jpg` : `./checkerboard.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={3}
				position={[0, 0, -40]}
				scale={200}
				rotation={[0, 0, 0]} />
			<Image
				material='phong'
				image={images(visualData ? `./echo/pages/curse_27.png` : `./checkerboard.png`).default}
				repeat={false}
				scale={[10, 100, 100]}
				position={[0, -7, 0]}
				rotation={[Math.PI/2, 0, 0]} />
			<mesh scale={[100, 100, 100]}
			position={[0, -8, 0]}
			rotation={[-Math.PI/2, 0, 0]}>
				<planeGeometry/>
				<meshPhongMaterial color='#000'/>
			</mesh>
			</group>}
			<group onPointerOver={() => { setMessager("", 10, null, false, texts.ui.view); document.body.style.cursor = 'zoom-in' }} onPointerOut={() => { setMessager(); document.body.style.cursor = 'default' }}>
				<NPC material='phong' scale={2} image={images(visualData ? './texture-parchment.png' : `./checkerboard.png`).default} position={[0, 0, -10]} repeat={false}
					onOver={() => {
						document.body.style.cursor = 'help';
						setCode({
							...code,
							header: 'scripture',
							input: empiredao.scriptureInput,
							algorithm: empiredao.scriptureAlgorithm,
						});
						setMessager("", 10, null, false, texts.ui.read);
					}}
					onOut={() => {
						document.body.style.cursor = 'default';
						setMessager();
					}}
					onClick={() => {
						setCoding(true);
						setCoded(true);
					}}
					/>
				<mesh scale={[3,4,4]} material='phong' position={[0, -4, -10]}>
					<boxGeometry></boxGeometry>
					<meshPhongMaterial color='#333'></meshPhongMaterial>
				</mesh>
				</group>
			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ(0);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>
		</Suspense>


	</group>)
}

export default React.memo(Church);
