import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import useKeypress from 'react-use-keypress';
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Captcha = ({ images, setMessage, setAvatar, roomY, setRoomX, setRoomY, setRoomZ, clip, setSpeed, setWait, code, setCode, setMessager, hallend, texts, setCoding, saveStatChange, inventory, setInventory, equipped, setEquipped }) => {

	const [ stage, setStage ] = useState(0);
	const [ texture, setTexture ] = useState(`./bg-stars.png`);

	const avatar = images(texts.captcha.avatar).default;

	useEffect(() => {
		let timer = null;
		switch (stage) {
			case 0:
				timer = setTimeout(() => {
					setMessager(texts.captcha.trust, 40, avatar, true);
					setInventory(inventory.concat([{id: 'lock'}]));
					setStage(stage + 1);
				}, 1000);
			break;
			return () => clearTimeout(timer);
		}
	}, [stage]);

	useEffect(() => {
    if (stage == 1) {
			if (code.algorithm.find(d=>d.id == 'lock')) {
				setCoding(false);
				let temp = equipped.slice();
				let lock = code.algorithm.findIndex(d=>d.id == 'lock');
				let captcha = code.algorithm.findIndex(d=>d.id == 'captcha');
				if (lock != -1) temp.splice(lock, 1);
				if (captcha != -1) temp.splice(captcha, 1);
				temp.push({id: 'captcha', nft: true});
				setEquipped(temp);
				setMessager(texts.captcha.complete, 40, avatar, true);
				setStage(2);
			}
		}
  }, [code]);

	let blackbox = stage >= 2 && <NPC
		scale={texture == `./bg-stars.png` ? 4 : 12}
		position={[0, 0, -25]}
		image={images(texture).default}
		geometry={texture == `./bg-stars.png` ? <boxGeometry attach="geometry" /> : null}
		material='phong'
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager()
		}}
		onMove={(e) => {
			document.body.style.cursor = "context-menu";
			setMessager(texts.captcha.debugger, 40, avatar, false, texts.ui.debug);
			setCode({
				...code,
				input: [],
				algorithm: [{id: 'bias'}, {id: 'other'}],
			});
		}}
		castShadow={true}
		onClick={(e) => {
			setCoding(true);
			setMessager(texts.captcha.protocols, 40, avatar, true);
		}}
		receiveShadow={true} />

	return ([

		<Suspense key={'foyer'} fallback={null}>
			{stage == 2 &&
				<NPC
					image={images('./doors/door12.png').default}
					onOver={(e) => {
						document.body.style.cursor = "n-resize";
						setMessager("", 10, null, false, texts.ui.enter);
					}}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						setRoomX(-100);
						setRoomY(101);
						setRoomZ(0);
					}}
					repeat={false}
					position={[0, 0, -29.9]}
					scale={10}
					rotation={[0, 0, 0]} />}

		</Suspense>

	])
}

export default memo(Captcha);
