import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { Redirect } from "react-router-dom";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, roomX, roomY, setHeaded, texts, remembering, setRedirect, puredao, lab, inventory }) => {

	let portal = lab.algorithm.find(d=>d.id == 'portal');

	let door = [];
	if (portal) {
		door.push(<Image
			scale={[10, 12, 1]}
			position={[0, -2, -30]}
			scale={[12, 14, 2]}
			rotation={[0, 0, 0]}
			image={images(`./doors/door40.png`).default}
			onMove={(e) => {
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default"
				setMessager();
			}}
			onClick={(e) => {
				document.body.style.cursor = "default";
				setRoomY(roomY + 2);
			}}
			/>);
	}

	let npc = <NPC
		scale={1.5}
		position={[7, 3, -15]}
		rotation={[0, -Math.PI/1.5, 0]}
		image={images(`./enemy/face5.jpg`).default}
		geometry={<sphereGeometry attach="geometry" />}
		material='phong'
		onOver={(e) => {
			document.body.style.cursor = "help";
			setCode({
				...code,
				header: 'memory', input: inventory,
				algorithm: puredao.labScientist,
			});
			setMessager("", 10, null, false, texts.ui.remember);
		}}
		onClick={(e) => {
			setCoding(true);
			setMessager(texts.ui.remembering, 10, images(`./enemy/face5.jpg`).default, true);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
	/>;


	return (<group>

		<Suspense fallback={<Loader/>}>
			{remembering && npc}
			<Image
				image={ images(`./checkerboard.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.exit);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					document.body.style.cursor = "default";
					setRoomX(-100);
					setRoomY(roomY - 2);
				}}
				repeat={true}
				material='phong'
				scale={68}
				position={[0, -15, 0]}
				rotation={[Math.PI/2, 0, 0]} />
			{door}
			<Image
				scale={[5, 4, 2.5]}
				rotation={[0, 0, 0]}
				position={[9, -1, -30]}
				image={images(`./products/monitor.png`).default}
				material='phong'
				onMove={(e) => {
					document.body.style.cursor = "zoom-in";
					setCode({
						...code,
						'header': 'laboratory',
						'input': lab.input,
						'algorithm': lab.algorithm,
					});
					setMessager("", 10, null, false, texts.ui.view);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomY(roomY + 1);
					setCoding(true);
					setMessager(texts.puredao.generator, 10);
				}}
				repeat={false}
				castShadow={true}
				receiveShadow={true} />

		</Suspense>

	</group>)
}

export default React.memo(Room);
