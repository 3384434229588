import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"

const Controltower = ({ images, setMessage, setAvatar, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, setWait, setMessager, texts, setCoding, addAffiliation, affiliations, inventory, setTooltip, analyzer }) => {

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				scale={6}
				position={[22, 0, -20]}
				image={images('./face-1.png').default}
				geometry={<boxGeometry attach="geometry" />}
				material='phong'
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onOver={(e) => {
					document.body.style.cursor = "context-menu";
					setCode({
						...code,
						header: 'analyzer',
						input: inventory,
						algorithm: [{id: 'analyzer', trustreq: 99999}]
					});
					setMessager(texts.egrecore.offline, 10, images('./face-1.png').default, false, texts.ui.load);
				}}
				onClick={(e) => {
					setCoding(true);
					console.log(code.input.length);
					let count = 0;
					for (var i = 0; i < code.input.length; i++) {
						if (['bones', 'graveyardNPC', 'hallucin', 'scarecrow', 'npsee', 'valueshard'].indexOf(code.input[i].id) != -1) count ++
					}
					if (count == 0) setMessager(texts.egrecore.nothing, 30, images('./face-1.png').default, true);
				}}
				castShadow={true}
				receiveShadow={true} />
			<Image
				scale={[10, 10, 30]}
				rotation={[0, Math.PI / 2, 0]}
				position={[0, 2, -45]}
				image={images(`./echo/controlroom.png`).default}
				geometry={<boxGeometry attach="geometry" />}
				onOver={(e) => {
					document.body.style.cursor = "zoom-in";
					setMessager(texts.egrecore.map, 10, null, false, texts.ui.view);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setMessager();
					setRoomZ('controlmap');
				}}
				material='phong'
				repeat={true}
				castShadow={true}
				textureRepeat={1}
				receiveShadow={true} />
			<Image
				scale={[2, 3, 20]}
				rotation={[0, Math.PI / 2, 0]}
				position={[0, -5, -25]}
				image={images(`./brick.jpg`).default}
				geometry={<boxGeometry attach="geometry" />}
				material='phong'
				repeat={true}
				castShadow={true}
				textureRepeat={1}
				receiveShadow={true} />
			<Image
				scale={[2, 3, 20]}
				rotation={[0, Math.PI / 2, 0]}
				position={[0, -5, -15]}
				image={images(`./brick.jpg`).default}
				geometry={<boxGeometry attach="geometry" />}
				material='phong'
				repeat={true}
				castShadow={true}
				textureRepeat={1}
				receiveShadow={true} />
			<Image
				key='floor'
				image={ images(`./checkerboard.png`).default }
				material='phong'
				repeat={true}
				position={[0, -7, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'ceiling'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[0, 10, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'backwall'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[0, 0, -50]}
				scale={68}
				rotation={[0, 0, 0]} />
			<Image
				key={'rightwall'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[20, 0, -10]}
				scale={68}
				rotation={[0, -Math.PI / 2, 0]} />
			<Image
				key={'leftwall'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[-20, 0, -10]}
				scale={68}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'stainedglass1'}
				geometry={<boxGeometry attach="geometry" />}
				image={images(`./echo/stainedglass.png`).default}
				material='phong'
				repeat={false}
				position={[10, 0, -50]}
				scale={[8, 10, 1]}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'stainedglass2'}
				geometry={<boxGeometry attach="geometry" />}
				image={images(`./echo/stainedglass.png`).default}
				material='phong'
				repeat={false}
				position={[-10, 0, -50]}
				scale={[8, 10, 1]}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'elevator'}
				image={images(`./echo/outelevator.png`).default}
				onOver={(e) => {
					document.body.style.cursor = "grab";
          setTooltip(texts.ui.elevator);
        }}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setTooltip("");
				}}
				onClick={(e) => {
					setWait(false);
					setRoomZ('elevator');
				}}
				repeat={false}
				position={[-19, -3, -30]}
				scale={20}
				rotation={[0, Math.PI / 2, 0]} />
		</Suspense>

	</group>)
}

export default React.memo(Controltower);
