import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue }) => {

	let doors = [];
	doors.push(<Image
		image={ images(`./doors/door-sub1.png`).default }
		material='phong'
		repeat={false}
		position={[0, -3, -40]}
		scale={[7, 10, 1]}
		rotation={[0, 0, 0]}
		onOver={(e) => {
			document.body.style.cursor = "n-resize";
			setMessager("", 10, null, false, texts.ui.enter);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
		onClick={(e) => {
			setRoomY(roomY + 1);
		}}
		/>);

	let windows = [];
	for (var i = 0; i < 2; i++) {
		for (var j = 0; j < 2; j++) {
			windows.push(<Image
				image={ images(`./echo/porthole1.png`).default }
				material='phong'
				repeat={false}
				position={[-20 + (j * 40), 10, -40 + i * 20]}
				scale={3}
				rotation={[0, -j * -Math.PI/20, 0]} />);
		}
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				image={ images(`./echo/pages/curse_4.png`).default }
				material='phong'
				repeat={false}
				position={[-20, 0, -25]}
				scale={[7, 10, 1]}
				onOver={() => {
					readValue('paintingleft');
				}}
				onOut={() => {
					readValue();
				}}
				onClick={() => {
					readValue('paintingleft', 1);
				}}
				rotation={[0, Math.PI/2, 0]} />
			<Image
				image={ images(`./echo/pages/curse_12.png`).default }
				material='phong'
				repeat={false}
				position={[20, 0, -25]}
				scale={[7, 10, 1]}
				onOver={() => {
					readValue('paintingright');
				}}
				onOut={() => {
					readValue();
				}}
				onClick={() => {
					readValue('paintingright', 1);
				}}
				rotation={[0, -Math.PI/2, 0]} />
			{doors}
			{windows}
			{face != null && speech != null && <NPC
				scale={2}
				position={position == null ? [0, 0, -20] : position}
				rotation={[0, -Math.PI/2 + (position == null ? 0 : -position[0]*Math.PI/100), 0]}
				image={images(`./enemy/face${face}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setMessager(speech, 40, images(`./enemy/face${face}.jpg`).default, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>}
			</Suspense>


	</group>)
}

export default React.memo(Room);
