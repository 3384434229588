import React from "react";
import * as THREE from 'three'

const Loader = () => (
  <mesh scale={50} position={[0, 0, -2]} castShadow={true} receiveShadow={true}>
    <boxGeometry attach="geometry" />
    <meshLambertMaterial attach="material" />
  </mesh>
);

export default Loader;
