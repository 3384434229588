import React, { Fragment, Suspense, useMemo, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue, go }) => {

	let doors = [];
	if (up) {
		doors.push(<Image
			image={ images(`./echo/cagedlight.png`).default }
			material='phong'
			repeat={false}
			position={[0, 3, -20]}
			scale={[3, 1.5, 1]}
			rotation={[0, 0, 0]}
			/>);
		doors.push(<Image
			image={ images(`./doors/door-sub2.png`).default }
			material='phong'
			repeat={false}
			position={[0, -3, -20]}
			scale={[7, 10, 1]}
			rotation={[0, 0, 0]}
			onOver={(e) => {
				document.body.style.cursor = "n-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomY(roomY + 1);
			}}
			/>);
	}
	if (right) {
		doors.push(<Image
			image={ images(`./doors/door-sub2.png`).default }
			material='phong'
			repeat={false}
			position={[15, -3, -15]}
			scale={[7, 10, 1]}
			rotation={[0, -Math.PI/3, 0]}
			onOver={(e) => {
				document.body.style.cursor = "e-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX + 1);
			}}
			/>);
	}
	if (left) {
		doors.push(<Image
			image={ images(`./doors/door-sub2.png`).default }
			material='phong'
			repeat={false}
			position={[-15, -3, -15]}
			scale={[7, 10, 1]}
			rotation={[0, Math.PI/3, 0]}
			onOver={(e) => {
				document.body.style.cursor = "w-resize";
				setMessager("", 10, null, false, texts.ui.enter);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX - 1);
			}}
			/>);
	}

	let pipes = [];
	for (var i = 0; i < 10; i++) {
		for (var j = 0; j < i % 3; j++) {
			let x = Math.sin(i/10 * Math.PI*2 + j*20) * 6;
			let y = Math.cos(i/10 * Math.PI*2 + j*20) * 6;
			if (y > 0 && x < 5 && x > -5) continue
			pipes.push(<mesh position={[
				x,
				0,
				y,
			]} scale={[0.5, 20, 0.5]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#bbbbbb'}></meshPhongMaterial>
				</mesh>);
		}
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<group rotation={[Math.PI/2, 0, 0]} position={[0, 0, -10]}>{pipes}</group>
			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					go('d');
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>
			<mesh
				scale={[10,80,1]}
				position={[0,-8,-20]}
				rotation={[-Math.PI/2, 0, 0]}>
				<planeGeometry></planeGeometry>
				<meshPhongMaterial color='#000000'></meshPhongMaterial>
			</mesh>
			{doors}
			{face != null && speech != null && <NPC
				scale={2}
				position={position == null ? [0, 0, -20] : position}
				rotation={[0, -Math.PI/2 + (position == null ? 0 : -position[0]*Math.PI/100), 0]}
				image={images(`./enemy/face${face}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setMessager(speech, 40, images(`./enemy/face${face}.jpg`).default, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>}
			</Suspense>


	</group>)
}

export default React.memo(Room);
