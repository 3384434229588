import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import { useAuth0 } from "@auth0/auth0-react";

import Writer from "../components/Writer.js"

import blank from "../assets/lantern.png"
import dim from "../assets/lantern-dim.png"

const Lantern = ({ visible, roomX, code, setCode, setMessager, texts, setCoding, inventory, equipped, loadedBubble, uiData, colorData, scriptData, visualData, objectData }: Props) => {

  useEffect(() => {
    if (roomX > 0) setMessager(texts.empiredao.data, 30, null, true);
  }, [scriptData]);

  return (visible) ?
	<div className='lantern'>
		<div className='image'>
      <img
        onMouseOver={() => {
          document.body.style.cursor = "context-menu";
          setCode({
            ...code,
            'header': 'lantern',
            'input': inventory || [],
            'algorithm': equipped || []
          });
          setMessager(texts.ui.lantern, 10);
        }}
        onMouseOut={() => {
          document.body.style.cursor = "default";
          setMessager();
        }}
        onClick={() => {
          setCoding(true);
        }}
        style={{  position: 'absolute', zIndex: '950', right: '50px',
          bottom: '0' }}
        src={ !objectData ? dim : blank }>
      </img>
		</div>
	</div>
	: null;
};

export default Lantern;
