import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue, setCoding }) => {

	let doors = [];
	doors.push();

	let windows = [];
	for (var i = 0; i < 12; i++) {
		windows.push(<Image
			image={ images(`./echo/porthole1.png`).default }
			material='phong'
			repeat={false}
			position={[Math.sin(i/12*Math.PI*2) * 20, Math.cos(i/12*Math.PI*2) * 20, -40]}
			scale={3}
			rotation={[-Math.PI/2, 0, 0]} />);
	}

	// <Image
	// 	image={ images(`./doors/door-hatch.png`).default }
	// 	onOver={(e) => {
	// 		document.body.style.cursor = "s-resize";
	// 		setMessager("", 10, null, false, texts.ui.enter);
	// 	}}
	// 	onOut={(e) => {
	// 		document.body.style.cursor = "default";
	// 		setMessager();
	// 	}}
	// 	onClick={(e) => {
	// 		setRoomZ(roomZ - 1);
	// 	}}
	// 	geometry={<sphereGeometry></sphereGeometry>}
	// 	material='phong'
	// 	repeat={false}
	// 	position={[0, -3, -10]}
	// 	scale={2}
	// 	rotation={[0, -Math.PI/2, 0]} />

	return (<group>

		<Suspense fallback={<Loader/>}>
			<NPC
				scale={1.5}
				position={[-5, 0, -20]}
				rotation={[0, -Math.PI/2 + 5*Math.PI/100, 0]}
				image={images(`./enemy/face${20}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setCode({
						...code,
						header: 'memory', input: [],
						algorithm: [{id: 'valueshard'}, {id: 'corrector'}],
					});
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setCoding(true);
					setMessager(texts.ui.remembering, 10, null, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>
			<Image
				image={ images(`./echo/porthole4.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.enter);
					readValue(3);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
					readValue();
				}}
				onClick={(e) => {
					setRoomZ(roomZ - 1);
					readValue();
				}}
				material='phong'
				repeat={false}
				position={[5, -3, -10]}
				scale={8}
				rotation={[0, 0, Math.PI/2]} />
			{doors}
			<group rotation={[Math.PI/2, 0, 0]} position={[0, -35, 0]}>{windows}</group>
			</Suspense>


	</group>)
}

export default React.memo(Room);
