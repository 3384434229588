import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));

  return (
      <Plane args={[100, 100]} ref={ref}>
        <meshBasicMaterial opacity={0} transparent />
      </Plane>
  );
}

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setRoomZ, setWait, roomX, roomY, setHeaded, texts, puredao, inventory, equipped, setEquipped }) => {

	let enemies = [];
  for (var i = 0; i < 3; i++) {
		enemies.push(<Enemy spawn={[
			seeds[1 + i] * 40 - 20,
			1,
			seeds[8 + i] * -50
		]}
		image={images(`./avatars/avatar_${i+1}.png`).default }
		key={'enemy' + '_' + seeds[10] + '_' + i}
		armed={armed} geometry={true}
		doGlitch={doGlitch} stopGlitch={stopGlitch} clip={clip} setAvatar={setAvatar} setSpeed={setSpeed} setMessage={setMessage} setRoomX={setRoomX} setRoomY={setRoomY} setWait={setWait} saveStatChange={saveStatChange} />);
	}

	let columns = [];
	for (var i = 0; i < 2; i++) {
		for (var j = 0; j < 2; j++) {
			columns.push(<Image
				image={images(`./echo/pages/curse_${Math.floor(seeds[3] * 20) + 20}.png`).default}
				geometry={<cylinderBufferGeometry args={[0.2,0.2,4]} attach="geometry" />}
				scale={[2, 2, 2]}
				repeat={false}
				position={[-20 + (j * 40), 10, -40 + i * 20]}
				scale={10}
				rotation={[0, 0, 0]} />);
			columns.push(<Image
				image={images(`./echo/pages/curse_${Math.floor(seeds[3] * 20) + 60}.png`).default}
				repeat={false}
				position={[-20 + (j * 40), -5, -38 + i * 20]}
				scale={[20, 10, 1]}
				rotation={[0, 0, 0]} />);
		}
	}

	let doors = <Image
		image={ images(`./echo/pages/right_64.png`).default }
		onOver={(e) => {
			document.body.style.cursor = "n-resize";
			setMessager("", 10, null, false, texts.ui.exit);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
		onClick={(e) => {
      if (document.body.style.cursor == 'crosshair') return
      setRoomX(-100);
			setRoomY(roomY - 1);
      setRoomZ(0);
		}}
		material='phong'
		scale={[8,15,1]}
		position={[
			0,
			-1,
			-79
		]}
		rotation={[0, Math.PI/2 + (Math.PI/2), 0]} />;

	let npc = <NPC
		scale={1}
		position={[5, 0, -15]}
		rotation={[0, -Math.PI/1.5, 0]}
		image={images(`./enemy/face18.jpg`).default}
		geometry={<sphereGeometry attach="geometry" />}
		material='phong'
		onOver={(e) => {
			document.body.style.cursor = "help";
      setCode({
				...code,
				header: 'memory', input: inventory,
				algorithm: puredao.hivesPerson,
			});
			setMessager("", 10, null, false, texts.ui.remember);
		}}
		onClick={(e) => {
      setCoding(true);
			setMessager(texts.ui.remembering, 10, null, true);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
	/>;

  let bot = <NPC
    scale={1}
    position={[-5, 0, -15]}
    rotation={[-Math.PI/8, -Math.PI/4, 0]}
    image={images(`./avatars/avatar17.png`).default}
    geometry={<sphereGeometry attach="geometry" />}
    material='phong'
    onOver={(e) => {
      document.body.style.cursor = "help";
      setCode({
        ...code,
        header: 'memory', input: inventory,
        algorithm: puredao.hivesNPC,
      });
      setMessager("", 10, null, false, texts.ui.remember);
    }}
    onClick={(e) => {
      setCoding(true);
      setMessager(texts.ui.remembering, 10, null, true);
      setTimeout(() => {
        setMessager(texts.puredao.accused, 30, null, true);
        setEquipped(equipped.concat([{id: 'stigmata', nft: true}]));
      }, 10000);
    }}
    onOut={(e) => {
      document.body.style.cursor = "default";
      setMessager();
    }}
  />;


	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				key='floor'
				image={ images(`./echo/pages/curse_${40 + Math.floor(seeds[0] * 20)}.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={1}
				position={[0, -7, -10]}
				scale={[150, 150, 1]}
				rotation={[-Math.PI / 2, 0, 0]} />
				<Image
					key={'ceiling'}
					image={ images(`./echo/pages/curse_${0 + Math.floor(seeds[0] * 20)}.png`).default }
					transparent={false}
					material='phong'
					repeat={true}
					textureRepeatX={2}
					textureRepeatY={20}
					position={[0, 8, -10]}
					scale={100}
					rotation={[-Math.PI / 2, 0, 0]} />
				<Image
					key={'backwall'}
					image={ images(`./echo/pages/curse_${60 + Math.floor(seeds[0] * 20)}.png`).default }
					transparent={false}
					repeat={true}
					textureRepeatY={1}
					textureRepeatX={10}
					position={[0, 0, -80]}
					scale={[200, 25, 1]}
					rotation={[0, 0, 0]} />
				<Image
					key={'rightwall'}
					image={ images(`./echo/pages/curse_${60 + Math.floor(seeds[0] * 20)}.png`).default }
					transparent={false}
					repeat={true}
					textureRepeatY={1}
					textureRepeatX={10}
					position={[40, 0, -10]}
					scale={[200, 25, 1]}
					rotation={[0, -Math.PI / 2, 0]} />
				<Image
					key={'leftwall'}
					image={ images(`./echo/pages/curse_${60 + Math.floor(seeds[0] * 20)}.png`).default }
					transparent={false}
					repeat={true}
					textureRepeatY={1}
					textureRepeatX={10}
					position={[-40, 0, -10]}
					scale={[200, 25, 1]}
					rotation={[0, Math.PI / 2, 0]} />
      <Physics gravity={[0, -100, 0]}>
				<PhyPlane position={[0, -7, -10]} scale={200} rotation={[-Math.PI / 2, 0, 0]} />
				{false && enemies}
			</Physics>
      {doors}
			{roomX == -100 && npc}
			{roomX == -98 && bot}
			</Suspense>

	</group>)
}

export default React.memo(Room);
