import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import $ from 'jquery';

import * as THREE from 'three'
import { Box, Plane, Sphere, Torus, Cylinder, useTexture } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping, RedFormat } from 'three';
import { EffectComposer, Pixelation, Glitch } from '@react-three/postprocessing';
import lerp from 'lerp'
/*import GifLoader from 'three-gif-loader';*/

const images = require.context('../assets', true);

const width = 8;
const height = 11;
const depth = 10;

/*const giffer = new GifLoader();
const explosion = giffer.load(
	images(`./enemy/explosion.gif`).default,
	function (reader) {
		console.log(reader.numFrames());
	},
	function (xhr) {
		//console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`);
	},
	function () {
		console.error('GIF failed to load.');
	}
);*/

function Block({ doGlitch, stopGlitch, health, setHealth, setVisible, clip, saveStatChange, spawn, image, armed, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, geometry, ...props }) {
  const [ref, api] = useBox(() => ({ args: [width, height, depth], mass: 1, fixedRotation: false, ...props }));

	const values = useRef([0, 0, 0]);

  // api.rotation.set(
  //   -Math.PI * 8,
  //   0,
  //   0
  // );

  if (geometry != null) {
    api.angularVelocity.set(
      0,
      -10,
      0
    );
  }


  const tex = useTexture({
    map: image
  })

	const texture = useLoader(TextureLoader, image);
	const { viewport } = useThree();

  useFrame(({state, clock}) => {
    let time = clock.getElapsedTime()
    ref.current.scale.x = 1 + Math.sin(time * 10) / 10;
    ref.current.scale.y = 1 + Math.cos(time * 10) / 10;
    if (geometry == null) {
      ref.current.rotation.x = 0;
      ref.current.rotation.y = -props.position[0] / 15;
      ref.current.rotation.z = 0;
    }
    // if (health > 5) {
    //   api.velocity.set(
    //     -spawn[0]/10,
    //     0,
    //     10
    //   );
    // } else if (health <= 0) {
    //   api.velocity.set(
    //     spawn[0]/100,
    //     0,
    //     -100
    //   );
    // }
  });

	texture.generateMipmaps = false
	texture.wrapS = texture.wrapT = ClampToEdgeWrapping
	texture.minFilter = LinearFilter
	texture.needsUpdate = true

  return (geometry != null ?
      <Sphere args={[2,8,8]} ref={ref} rotation={[-Math.PI/2, 0, 0]} onClick={() => {
        if (!armed || clip == 0) return
        let damage = 5;
        setMessage("You SHOT a BOT! The creator of this BOT was awarded +1CTX", 10);
        api.applyImpulse([0, 0, -50], [0, 0, 10]);
        setHealth(0);
        document.body.style.cursor = "default";
        setTimeout(() => {
          setVisible(false);
        }, 700);
      }}
      onPointerMove={(e) => {
        if (armed) {
          document.body.style.cursor = "crosshair";
        }
      }}
      onPointerOut={(e) => {
        document.body.style.cursor = "default";
      }}
      >
        <meshPhongMaterial transparent={true} {...tex} />
      </Sphere>
      : <Plane args={[width, height, depth]} ref={ref} onClick={() => {
				if (!armed || clip == 0) return
        let damage = 5;
        setMessage("You SHOT a BOT! The creator of this BOT was awarded +1CTX", 10);
				api.applyImpulse([0, 0, -50], [0, 0, 10]);
        setHealth(0);
        setTimeout(() => {
          setVisible(false);
        }, 700);
			}}
			onPointerMove={(e) => {
				if (armed) {
          document.body.style.cursor = "crosshair";
        }
			}}
			onPointerOut={(e) => {
				document.body.style.cursor = "default";
			}}
			>
        <meshPhongMaterial attach="material" transparent='true' {...tex} />
      </Plane>
  );
}

const Blockhead = ({ doGlitch, stopGlitch, clip, saveStatChange, spawn, image, armed, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setWait, geometry }) => {

	const [health, setHealth] = useState(15);
	const [visible, setVisible] = useState(true);

	return ([

		<>
			{(visible) && (

  				<Block doGlitch={doGlitch} stopGlitch={stopGlitch} health={health} clip={clip} saveStatChange={saveStatChange} setVisible={setVisible} setHealth={setHealth} geometry={geometry} image={image} spawn={spawn} position={spawn} armed={armed} setAvatar={setAvatar} setSpeed={setSpeed} setMessage={setMessage} setRoomX={setRoomX} setRoomY={setRoomY} setWait={setWait} />

    )}
		</>

	])
}

export default Blockhead;
