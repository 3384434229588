import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import useKeypress from 'react-use-keypress';
import { useGetAndSet } from "react-context-hook";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Captcha = ({ images, setMessage, setAvatar, roomY, setRoomY, clip, setSpeed, setWait, code, setCode, setMessager, hallend, texts, setCoding, saveStatChange, stackdao, setStackdao }) => {

	const [ stage, setStage ] = useState(0);
	const [ texture, setTexture ] = useState(`./bg-stars.png`);

	const avatar = images(texts.captcha.avatar).default;

	useEffect(() => {
		let timer = null;
		switch (stage) {
			case 0:
				timer = setTimeout(() => {
					setMessager(texts.captcha.slow, 40, avatar, true);
					setStage(stage + 1);
				}, 1000);
			break;
			case 1:
				timer = setTimeout(() => {
					setMessager(texts.captcha.debugger, 40, avatar, true);
					setStage(stage + 1);
				}, 2000);
			break;
			return () => clearTimeout(timer);
		}
	}, [stage]);

	useEffect(() => {
    if (stage == 2) {
			if (code.input.find(d=>d.id == 'bias')) {
				setCoding(false);
				setMessager(texts.captcha.simple, 40, avatar, true)
				setStage(3);
			} else if (code.input.find(d=>d.id == 'other')) {
				setStackdao({ ...stackdao, trust: stackdao.trust - 1 });
				setMessager(texts.captcha.wrong, 40, avatar, true)
			}
		}
  }, [code]);

	let blackbox = stage >= 2 && <NPC
		scale={texture == `./bg-stars.png` ? 4 : 12}
		position={[0, 0, -25]}
		image={images(texture).default}
		geometry={texture == `./bg-stars.png` ? <boxGeometry attach="geometry" /> : null}
		material='phong'
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager()
		}}
		onMove={(e) => {
			document.body.style.cursor = "context-menu";
			setMessager(texts.captcha.debugger, 40, avatar, false, texts.ui.debug);
			setCode({
				...code,
				input: [],
				algorithm: [{id: 'bias'}, {id: 'other'}],
			});
		}}
		castShadow={true}
		onClick={(e) => {
			setCoding(true);
			setMessager(texts.captcha.protocols, 40, avatar, true);
		}}
		receiveShadow={true} />

	return ([

		<Suspense key={'foyer'} fallback={null}>
			{stage == 2 && blackbox}
			{stage == 3 &&
				<NPC
					image={images('./doors/door12.png').default}
					onOver={(e) => {
						document.body.style.cursor = "n-resize";
						setMessager("", 10, null, false, texts.ui.enter);
					}}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						setRoomY(roomY + 1);
					}}
					repeat={false}
					position={[0, 0, -29.9]}
					scale={10}
					rotation={[0, 0, 0]} />}
		</Suspense>

	])
}

export default memo(Captcha);
