import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useFrame } from "@react-three/fiber";

import Image from "../Image.js"
import AnimatedImage from "../AnimatedImage.js"
import Loader from "../Loader.js"

const Controlmap = ({ images, setMessager, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, setWait, texts, inventory, analyzer }) => {

	let puredao2 = analyzer.find(d=>d.id == 'scarecrow') ? texts.egrecore.puredao2 : texts.egrecore.unknown;
	let oceandao2 = analyzer.find(d=>d.id == 'valueshard') ? texts.egrecore.oceandao2 : texts.egrecore.unknown;
	let empiredao2 = analyzer.find(d=>d.id == 'b') ? texts.egrecore.empiredao2 : texts.egrecore.unknown;
	let stackdao2 = analyzer.find(d=>d.id == 'c') ? texts.egrecore.stackdao2 : texts.egrecore.unknown;

	return (<group>

		<Suspense fallback={<Loader/>}>
			<mesh transparent={true} scale={2} position={[-2, -2, -5]}
				onPointerOver={(e) => {
					// setMessager(texts.egrecore.puredao + " " + puredao2, 30);
				}}
				onPointerOut={(e) => {
					// setMessager();
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial transparent={true} opacity={0}></meshBasicMaterial>
			</mesh>
			<mesh transparent={true} scale={2} position={[2, -2, -5]}
				onPointerOver={(e) => {
					// setMessager(texts.egrecore.oceandao + " " + oceandao2, 30);
				}}
				onPointerOut={(e) => {
					// setMessager();
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial transparent={true} opacity={0}></meshBasicMaterial>
			</mesh>
			<mesh transparent={true} scale={2} position={[2, 2, -5]}
				onPointerOver={(e) => {
					// setMessager(texts.egrecore.empiredao + " " + empiredao2, 30);
				}}
				onPointerOut={(e) => {
					// setMessager();
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial transparent={true} opacity={0}></meshBasicMaterial>
			</mesh>
			<mesh transparent={true} scale={2} position={[0, -1, -5]}
				onPointerOver={(e) => {
					// setMessager(texts.egrecore.paranormal, 30);
				}}
				onPointerOut={(e) => {
					// setMessager();
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial transparent={true} opacity={0}></meshBasicMaterial>
			</mesh>
			<mesh transparent={true} scale={2} position={[-2, 2, -5]}
				onPointerOver={(e) => {
					// setMessager(texts.egrecore.stackdao + " " + stackdao2, 30);
				}}
				onPointerOut={(e) => {
					// setMessager();
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial transparent={true} opacity={0}></meshBasicMaterial>
			</mesh>
			<AnimatedImage
				image={ images(`./echo/reticule.png`).default }
				repeat={false}
				material='phong'
				position={[0, -1.9, -5]}
				scale={2}
				animRotation={[0, 0.02, 0.02]}
				rotation={[0, 0, 0]} />
			<Image
				key={'backwall'}
				image={ images(`./echo/worldmap.png`).default }
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "zoom-out";
				}}
				onClick={(e) => {
					setRoomZ(0);
				}}
				repeat={false}
				position={[0, 0, -10]}
				scale={20}
				rotation={[0, 0, 0]} />
		</Suspense>

	</group>)
}

export default React.memo(Controlmap);
