import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const LabFoyer = ({ images, setMessager, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, setCoding, seeds, texts, inventory, family }) => {

	useEffect(() => {
		setMessager(texts.stackdao.assigned + family.name.toUpperCase(), 30, null, true);
	}, []);

	return (<group>
			<Suspense fallback={<Loader/>}>
			<mesh scale={10} position={[0, 0, -10]}>
				<boxBufferGeometry args={[1, 1, 2]} />
      	<meshNormalMaterial wireframe />
			</mesh>

			<mesh scale={10} position={[0, 0, -20]}
				onPointerOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onClick={(e) => {
					setRoomY(roomY + 1);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}>
				<boxBufferGeometry args={[0.1, 0.2, 0.1]} />
      	<meshPhongMaterial wireframe />
			</mesh>

			</Suspense>
	</group>)
}

export default React.memo(LabFoyer);
