import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore, useGetAndSet } from "react-context-hook";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Debugger from "../components/Debugger.js"

import items from '../data/items.js'

const images = require.context('../assets/products', true);

const Products = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const managementAPIToken = useStore('managementAPIToken')[0];
  const [ message, setMessage ] = useState("");
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');

  return ([
    <div className='egrecorp products' style={{ overflowY: 'scroll' }}>

      {"NFTs in EGRECORP are not just for show – each gives you special powers that you can use to participate in politics and improve society."}<br></br>

      {items.map(function(d,i){
        let productName = d.name.replace(" ", "");

        let details = d.price;
        let description = d.description;
        let opacity = 0.5;
        let selectable = '';

        if (inventory.find(d=>d.id == productName) || equipped.find(d=>d.id == productName)) {
          details = "ACTIVATED";
          opacity = 1;
          selectable = 'selectable';
        } else {
          opacity = 0.7;
        }

        if (productName == 'memoryblock') opacity /= 1.5;
        if (productName == 'loyalist') opacity *= 1.5;
        if (productName == 'valuator') opacity *= 1.5;

        return (
          <div className={'product ' + selectable} key={"product" + i}>
            <img src={ images(`./${productName}.gif`).default } style={{ opacity: opacity }}/>
            <div className='title'>{d.name.toUpperCase()}</div>
            <div className='details'>{"[" + details + "]"}</div>
            <div className='description'>{description}</div>
          </div>
        )
      })}

    </div>
]);

};

export default Products;
