import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Debugger from "../components/Debugger.js"

import items from '../data/items.js'
import stars from "../assets/bg-stars.png"

const images = require.context('../assets', true);

const Products = () => {

  return ([
    <div className='egrecorp services' style={{ height: '100vh' }}>

      <img style={{width: '50px'}} className='alertimage' src={images('./checkerboard.png').default}></img>
      <u>{"EGRECORE PROTOCOLS"}</u><br></br>
      <div className='alertheader'><Writer msg={"You need 99999 TRUSTWORTH to suggest AMENDMENTS to this CODE"}/></div><br></br>

      <div>
      <Delayer delay={2000}><u className='alertheader'>TRANSPARENCY PROTOCOL</u>: <Writer msg={"Members shall not hide the material causes of things."}/></Delayer><br></br>
    <Delayer delay={4000}><u className='alertheader'>RATIONALITY PROTOCOL</u>: <Writer msg={"Members shall not mystify technology, including but not limited to: alleging that political phenomena and ALGORITHMIC FICTIIONS have non-material causes."}/></Delayer><br></br>
  <Delayer delay={7000}><u className='alertheader'>ACCESSIBILITY PROTOCOL</u>: <Writer msg={"Members shall create environments that are sufficiently predictable."}/></Delayer><br></br>
<Delayer delay={9000}><u className='alertheader'>FIDELITY PROTOCOL</u>: <Writer msg={"Members shall not impersonate other members or create accounts to game VOTES. For exceptions, refer to the CODES of particular CAOs."}/></Delayer><br></br>
      <Delayer delay={13000}><u className='alertheader'>META PROTOCOL</u>: <Writer msg={"Members shall use CODE to fix social, political, and technical problems, rather than seeking out undocumentable, manual, or conjectural solutions."}/></Delayer></div>

      <br></br>
      <NavLink to={{ pathname: 'echo', state: {room: [0, 0, 'servers']} }}>{"< Back to ZERO"}</NavLink>

    </div>
]);

};

export default Products;
