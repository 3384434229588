import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets/', true);

const Gatekeeper = ( idle ) => {

  const [ language, setLanguage ] = useGetAndSet('language');
  const [ message, setMessage ] = useState("");
  const [coding, setCoding] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [code, setCode] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });

  return (

    <div className='egrecorp warning'>
      {<div>
        <img className='alertimage' src={images('./face-2.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"WAIT!"} /></u>
        <Writer delay={1000} speed={10} msg={"Do not leave the computer unattended when ALGORITHMIC FICTIONS are running"}/><br></br>
        <div className='alertheader'><Writer delay={3000} speed={30} msg={"algorithmic fictions must be supervised at all times to prevent contamination of other facts".toUpperCase()}/></div><br></br>
        <Delayer delay={6000}>{`Resetting system in ${50 - idle.idle}`}</Delayer><br></br>
      </div>}
    </div>

  );

};

export default Gatekeeper;
