import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"
import Shooter from "../components/Shooter.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets/', true);

const Gatekeeper = (props) => {

  const [ language, setLanguage ] = useGetAndSet('language');
  const [ debugging, setDebugging ]  = useState(true);
  const [ added, setAdded ]  = useState(false);
  const [ message, setMessage ] = useState("");
  const [coding, setCoding] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [code, setCode] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });

  useEffect(() => {
    if (!coding) return
    if (code.algorithm.find(d=>d.id == 'memoryblock')) {
      setCoding(false);
    }
  }, [code]);

  function addPlugin() {
    setInventory(inventory.concat({id: 'memoryblock'}));
    setAdded(true);
  }

  let loadedWeapon = code.algorithm.find(d=>d.id == 'loyalist');
  let loadedMemory = code.algorithm.find(d=>d.id == 'memoryblock');

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} setMessager={() => {setMessage("")}} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    <Shooter texts={texts[language]} visible={loadedWeapon} setMessager={() => {setMessage("")}} shooting={false} clip={8} setClip={() => {setMessage("")}} code={code} setCode={setCode} setCoding={setCoding} />,

    (<div className='egrecorp offline'>
      <div>
        <img className='alertimage' src={images('./face-2.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"WAIT!"} /></u>
        <Writer delay={1000} speed={10} msg={"Current number of online users is 0"}/><br></br>
      </div>
      {!added && !loadedMemory && (
        <div>
        <Writer delay={3000} speed={10} msg={"Please add a MEMORYBLOCK to DEBUG the memories of inactive accounts:"}/><br></br>
        <Delayer delay={5000}>
          <div className='plugin'>
            <img src={ images(`./products/memoryblock.gif`).default }/>
            <div style={{ textAlign: 'left' }}>
              <div className='title'>{"MEMORYBLOCK v8.9.9"}</div>
              <div className='title'>{"Memory block scraper"}</div><br></br>
              <a className='add' onClick={ addPlugin.bind(this) }>{"Click to add CODE"}</a>
              <img className='pluginlogo' src={ images(`./logo-egrecorp.png`).default }/>
            </div>
          </div>
        </Delayer>
        </div>
      )}
      {added && !loadedMemory && (
        <div>
        <Writer delay={0} speed={10} msg={"MEMORYBLOCK added"}/><br></br>
        <Writer delay={1000} speed={10} msg={"Please click the DEBUGGER to load the MEMORYBLOCK"}/><br></br>
        </div>
      )}
      {loadedMemory && (
        <div>
          <Writer delay={0} speed={10} msg={"MEMORYBLOCK loaded..."}/><br></br>
          <Writer delay={1000} speed={10} msg={"Starting ECHO sequence with coordinates " + props.location.state.route}/><br></br>
          <Delayer delay={3000}> <Redirect to={{
              pathname: 'echo',
              state: { room: props.location.state.route },
              equipped: equipped
            }} /> </Delayer>
        </div>
      )}

    </div>)

  ]);

};

export default Gatekeeper;
