import React, { useState, useEffect, memo } from 'react';
import $ from 'jquery';

import { useAuth0 } from "@auth0/auth0-react";

import Writer from "../components/Writer.js"

const Logs = ({ visible, inventory, hintersteps, stackdao, lifespan, family, setFamily }: Props) => {

  let i = inventory.length;
  let u = stackdao.pan;
  let m = stackdao.moves;
  let s = stackdao.social.length;
  let l = stackdao.coding;
  let a = stackdao.curiosity
  let t = lifespan;
  let w = stackdao.trust;
  let f = Math.floor(stackdao.coding / 10);

  let x = i/30 + u/100 + s/100 + t/10000;
  let y = m/300 + l/60 + w/20 + a/100000;

  let width = $('.plot').width();
  let height = $('.plot').height();

  let families = [
    {name: 'a', x: 0.13, y: 0.43},
    {name: 'b', x: 0.23, y: 0.62},
    {name: 'c', x: 0.41, y: 0.23},
    {name: 'd', x: 0.72, y: 0.56},
    {name: 'e', x: 0.09, y: 0.17},
    {name: 'f', x: 0.80, y: 0.29},
    {name: 'g', x: 0.91, y: 0.50},
    {name: 'h', x: 0.53, y: 0.31},
    {name: 'i', x: 0.66, y: 0.63},
    {name: 'j', x: 0.42, y: 0.73},
    { name: 'k', x: 0.52, y: 0.83},
    { name: 'l', x: 0.91, y: 0.87},
    { name: 'm', x: 0.34, y: 0.03},
    { name: 'n', x: 0.46, y: 0.15},
    { name: 'o', x: 0.17, y: 0.31}
  ];

  let newfam = null;
  let closest = 99999999;
  for (let i = 0; i < families.length; i++) {
    let distance = Math.abs(x - families[i].x) + Math.abs(y - families[i].y);
    if (distance < closest) {
      newfam = families[i];
      closest = distance;
    }
  }

  if (family == null || newfam.name != family.name) setFamily(newfam);

  return (visible && family != null) ?
	<div className='logs'>
    {/*<div><u>{"ANONYMOUS USER"}</u></div>
    <div>{"I: " + i}</div>
    <div>{"U: " + u}</div>
    <div>{"M: " + m}</div>
    <div>{"S: " + s}</div>
    <div>{"L: " + l}</div>
    <div>{"A: " + a}</div>
    <div>{"T: " + t}</div>
    <div>{"W: " + w}</div>
    <div>{"F: " + f}</div>*/}

    <div className='plot'>
      {families.map(function(d,i){
        return (<div style={{ left: d.x * width, bottom: d.y * height }} className='point'>{d.name == family.name ? "[" + d.name.toUpperCase() + "]" : d.name.toUpperCase()}</div>)
      })}
      {/*<div style={{ left: x * width, bottom: y * height }} className='point'>SELF</div>*/}
    </div>

  </div>
	: null;
};

export default memo(Logs);
