import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import useKeypress from 'react-use-keypress';

import Image from "../Image.js"
import texts from "../../data/texts.js"

const Hall = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, code, setCode, go }) => {

	let lights = [];
	for (var i = 0; i < 3; i++) {
		lights.push(<mesh
			rotation={[Math.PI / 2, 0, 0]}
			position={[0, 6.95, i * -10 - 5]}
			scale={[3, 3, 1]}
			>
			<planeGeometry></planeGeometry>
			<meshPhongMaterial color='#ffffff'></meshPhongMaterial>
		</mesh>);
	}

	let rails = [];
	for (var i = 0; i < 2; i++) {
		rails.push(<mesh
			rotation={[0, 0, 0]}
			position={[i*10 -5, -5.5, 0]}
			scale={[0.2, 0.5, 40]}
			>
			<boxGeometry></boxGeometry>
			<meshPhongMaterial color='#cecece'></meshPhongMaterial>
		</mesh>);
	}
	rails.push(<mesh
		rotation={[0, Math.PI/2, 0]}
		position={[i*10 -5, -5.5, -30]}
		scale={[0.2, 0.5, 60]}
		>
		<boxGeometry></boxGeometry>
		<meshPhongMaterial shininess={0} metalness={0} roughness={100} reflectivity={0} color='#333333'></meshPhongMaterial>
	</mesh>);

	return (

		<group>
			{lights}
			{rails}
			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 's-resize';
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
				}}
				onClick={(e) => {
					go('d');
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>
			<mesh
				rotation={[0, 0, 0]}
				position={[-10, 0, -30]}
				scale={[15, 20, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 'w-resize';
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
				}}
				onClick={(e) => {
					go('l');
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial opacity={0} transparent={true}></meshLambertMaterial>
			</mesh>
			<mesh
				rotation={[0, 0, 0]}
				position={[10, 0, -30]}
				scale={[15, 20, 1]}
				onPointerMove={(e) => {
					document.body.style.cursor = 'e-resize';
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
				}}
				onClick={(e) => {
					go('r');
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial opacity={0} transparent={true}></meshLambertMaterial>
			</mesh>
			<mesh
				rotation={[-Math.PI / 2, 0, 0]}
				position={[0, -7, -20]}
				scale={[20, 200, 1]}
				>
				<planeGeometry></planeGeometry>
				<meshPhongMaterial color='#999999'></meshPhongMaterial>
			</mesh>
			<Image
				image={images(`./panels.jpg`).default}
				rotation={[Math.PI / 2, 0, 0]}
				position={[0, 7, 70]}
				scale={[20, 200, 1]}
				material='phong'
				repeat={true}
				textureRepeatX={2}
				textureRepeatY={20}
			/>
			<Image
				key={'backwall'}
				opacity={0.3}
				image={images(`./backrooms.png`).default}
				material='phong'
				repeat={true}
				textureRepeatX={10}
				textureRepeatY={1}
				position={[0, 0, -30]}
				scale={[200,14,1]}
				rotation={[0, 0, 0]} />
			<Image
				key={'leftwall'}
				image={images(`./backrooms.png`).default}
				material='phong'
				repeat={true}
				textureRepeatX={5}
				textureRepeatY={1}
				position={[-5, 0, 30]}
				scale={[100,14,1]}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'rightwall'}
				image={ images(`./backrooms.png`).default }
				material='phong'
				repeat={true}
				textureRepeatX={5}
				textureRepeatY={1}
				position={[5, 0, 30]}
				scale={[100,14,1]}
				rotation={[0, -Math.PI / 2, 0]} />

		</group>

	)
}

export default memo(Hall);
