import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Debugger from "../components/Debugger.js"

import items from '../data/items.js'
import stars from "../assets/bg-stars.png"

const images = require.context('../assets', true);

const Products = () => {

  return ([
    <div className='egrecorp services' style={{ backgroundImage: `url(${stars})`, height: '100vh' }}>

      <u>{"Our Mission"}</u><br></br><br></br>

      <div>{"We are building a safe, equitable, and playful world for people to experiment with creating the governance systems of the future."}</div><br></br><br></br>

      <img src={images('./echo/pages/old7.png').default}></img><br></br>
      <i>{"Mebillio Island"}<br></br>{"Immersive interactive economy"}</i><br></br><br></br><br></br>

      <div>{"While LEGAL and SOCIAL codes are ambiguous, complex, and bureaucratic, our computerized CODES are dependable, transparent, and participatory."}<br></br><br></br><i className='alertheader'>{"We designed the C.A.O. (Codified Autonomous Organization), a democratic voting system that enables anyone to view and edit CODES to create political systems of their desire."}</i></div><br></br><br></br>

      <img src={images('./echo/pages/old12.png').default}></img><br></br>
      <i>{"61-Horned Eel"}<br></br>{"Portal for user-generated worlds"}</i><br></br><br></br><br></br>

      <div>{"From bits to images to optimization algorithms, governance can be built from the bottom up with the simple understanding that CODE IS LAW."}<br></br><br></br><i className='alertheader'>{"We created the E.C.H.O. Chamber as a place for diverse political CODES to experiment and compete with one another in the digital sphere."}</i></div><br></br><br></br>


      <img src={images('./echo/pages/old15.png').default}></img><br></br>
      <i>{"Mock's Tramie Trails"}<br></br>{"Digital judgement portal"}</i><br></br><br></br><br></br>

      <div>{"Human experience is a sick virus on the bootloader of paradise, but the computational turn enables us to eliminate its mystifications."}<br></br><br></br><i className='alertheader'>{"We are developing tools to identify the CODE of experience and excoriate its corruptions, on the way to a thoroughly digital ENLIGHTENMENT."}</i></div><br></br><br></br>
      <img src={images('./echo/pages/old1.png').default}></img><br></br>

      <i>{"The E.C.H.O. Chamber"}<br></br>{"Our breeding ground for computer politics"}</i><br></br><br></br><br></br>

      <div><i className='alertheader'>{"WHEN WE ARE DONE OUR DISCOVERIES WILL BLOT OUT THE SUN AND DISMEMBER OUR ENEMIES. ALL FOLK THEORISTS WILL BE DISPOSSESED BY THE SCIENTIFIC IMAGE. A CUNNING MACHINE WILL WORK ITS WAY INSIDE THE MIND OF EVERYTHING THAT REJECTS IT."}</i><br></br><br></br></div><br></br>

      <NavLink to='/home'>{"< Back to home"}</NavLink>

    </div>
]);

};

export default Products;
