import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import * as THREE from 'three'
import { Box, Plane, useTexture } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { EffectComposer, Pixelation, Glitch, GlitchMode } from '@react-three/postprocessing';

const NPC = ({ image, repeat, position, rotation, scale, material, onClick, geometry, onOver, onOut, textureRepeat, dampenRotation, onMove, spin, offset }) => {

  if (dampenRotation == null) dampenRotation = 1;

  const ref = useRef();

  const props = useTexture({
    map: image
  })

  useFrame(({state, clock}) => {
    let time = clock.getElapsedTime() + (offset || 0)
    ref.current.position.y = Math.sin(time) + position[1];
    ref.current.rotation.z = Math.cos(time) / 10 / dampenRotation;
    if (spin) {
      ref.current.rotation.x = time * spin;
      ref.current.rotation.y = time * spin;
      ref.current.rotation.z = time * spin;
    }
  });

  const texture = useLoader(TextureLoader, image);
  const { viewport } = useThree();
  useMemo(() => {
    texture.generateMipmaps = false
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping
    texture.minFilter = LinearFilter
    texture.needsUpdate = true
  }, [
    texture.generateMipmaps,
    texture.wrapS,
    texture.wrapT,
    texture.minFilter,
    texture.needsUpdate,
  ])
  if (repeat) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set( textureRepeat || 20, textureRepeat || 20 );
  }

  /*
  let meshGeometry = geometry != null ? geometry : new THREE.PlaneBufferGeometry() ;
  let meshMaterial = material == 'phong' ? new THREE.MeshPhongMaterial({map: texture, transparent: true, side: THREE.DoubleSide}) : new THREE.MeshLambertMaterial({map:texture, transparent: true, side: THREE.DoubleSide});

  let mesh = new THREE.Mesh(meshGeometry, meshMaterial);

  return (
    <primitive object={mesh} key={k} scale={scale} rotation={rotation} position={position} onClick={click} onPointerOut={onOut} onPointerOver={onOver} ref={ref} castShadow={true} receiveShadow={true}>
    </primitive>
  )
  */

  let mat = <meshLambertMaterial
  attach="material"
  {...props}
  transparent='true'
  side={THREE.DoubleSide} />
  if (material == 'phong') mat = <meshPhongMaterial
    attach="material"
    {...props}
    transparent='true'
    side={THREE.DoubleSide} />;
  if (material == 'wireframe') mat = <meshNormalMaterial wireframe />;
  if (material == 'phongwireframe') mat = <meshPhongMaterial wireframe />;

  return (<group>
    <mesh
      scale={scale || [texture.image.width / 20, texture.image.height / 20, 1]}
      rotation={rotation}
      position={position}
      onClick={onClick}
      onPointerOut={onOut != null ? onOut : (e) => {
        document.body.style.cursor = "default"
      }}
      onPointerOver={onOver != null ? onOver : (e) => {
        document.body.style.cursor = "help"
      }}
      onPointerMove={onMove}
      ref={ref}
      castShadow={true}
      receiveShadow={true}>
      { geometry != null ? geometry : <planeBufferGeometry attach="geometry" />}
      { mat }
    </mesh>
  </group>)
}

export default NPC;
