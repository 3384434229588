import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import * as THREE from 'three'
import { Box, Plane, useTexture } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { EffectComposer, Pixelation, Glitch, GlitchMode } from '@react-three/postprocessing';

const AnimatedImage = ({ image, repeat, position, rotation, scale, material, onClick, geometry, onOver, onOut, onMove, textureRepeat, animRotation, animPosition, contents, animEase }) => {

  const ref = useRef();

  useFrame(() => {
    if (animRotation != null) {
      ref.current.rotation.x += animRotation[0];
      ref.current.rotation.y += animRotation[1];
      ref.current.rotation.z += animRotation[2];
    }
    if (animPosition != null) {
      ref.current.position.x = ref.current.position.x > 20 ? -20 : ref.current.position.x + animPosition[0];
      ref.current.position.y += animPosition[1];
      ref.current.position.z += animPosition[2];
    }
    if (animEase != null) {
      ref.current.position.x = position[0] + animEase[0] / (ref.current.position.x + 1);
      ref.current.position.y = position[1] + animEase[1] / (ref.current.position.y + 1);
      ref.current.position.z = position[2] + animEase[2] / (ref.current.position.z + 1);
    }
  });

  return (
    <group scale={scale} rotation={rotation} position={position} onClick={onClick} onPointerOut={onOut} onPointerOver={onOver} onPointerMove={onMove} ref={ref} castShadow={true} receiveShadow={true}>
      { contents }
      { material }
    </group>
  )
}

export default AnimatedImage;
