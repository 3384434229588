import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const TrustWall = ({ images, setMessage, setAvatar, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, user, setMessager, go, texts }) => {

	useEffect(() => {
		setMessager(texts.empiredao.entered, 30, null, true);
	}, []);

	return (<group>

		<Suspense  fallback={<Loader/>}>
			<Image
				material='phong'
				image={images(`./doors/door29.png`).default}
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager(texts.empiredao.wall, 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					go('u');
				}}
				repeat={false}
				position={[0, -3, -30]}
				scale={10}
				rotation={[0, 0, 0]} />
			<Image
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				textureRepeat={6}
				position={[0, 92, -31]}
				scale={200}
				rotation={[0, 0, 0]} />
			</Suspense>


	</group>)
}

export default React.memo(TrustWall);
