import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import useKeypress from 'react-use-keypress';

import Image from "../Image.js"
import Loader from "../Loader.js"

const Mirror = ({ images, setMessage, setAvatar, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, setCoding, locked1, locked2, setMessager, texts, go, inventory, loadedBubble, equipped, setEquipped, empiredao, visualData, scriptData }) => {

	const [ stage, setStage ] = useState(0);
	const [ coded, setCoded ] = useState(false);

	useEffect(() => {
		if (coded && !coding) {
			setRoomZ(0);
		}
	}, [coding]);

	let mirrorimage = visualData ? scriptData ? `./echo/cursedmirror.png` : `./echo/mirror.png` : `./checkerboard.png`;

	return (<group>

		<Suspense fallback={<Loader/>}>
			<Image
				key={'stainedglass1'}
				geometry={<boxGeometry attach="geometry" />}
				image={images(visualData ? `./echo/stainedglass.png` : `./checkerboard.png`).default}
				material='phong'
				repeat={false}
				position={[10, 0, -10]}
				scale={[8, 10, 1]}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'stainedglass2'}
				geometry={<boxGeometry attach="geometry" />}
				image={images(visualData ? `./echo/stainedglass.png` : `./checkerboard.png`).default}
				material='phong'
				repeat={false}
				position={[-10, 0, -10]}
				scale={[8, 10, 1]}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'mirror'}
				image={images(mirrorimage).default}
				material='phong'
				repeat={false}
				position={[0, 0, -9.8]}
				scale={[10, 13, 1]}
				onOver={() => {
					document.body.style.cursor = "zoom-in";
					let add = {id: 'shame', nft: true, reflection: true};
					// if (equipped.find(d=>d.id == 'scrape1')) {
					// 	add = {id: 'mark1', nft: true, reflection: true};
					// 	if (equipped.find(d=>d.id == 'scrape2')) {
					// 		add = {id: 'mark2', nft: true, reflection: true};
					// 		if (equipped.find(d=>d.id == 'scrape3')) {
					// 			add = {id: 'mark3', nft: true, reflection: true};
					// 		}
					// 	}
					// }
					let remove = equipped.findIndex(d=>d.reflection);
          setCode({
            ...code,
            'header': 'mirror',
            'input': inventory,
            'algorithm': [add],
          });
					let temp = equipped.slice();
					if (remove != -1) temp.splice(remove, 1);
					temp.push(add);
					setEquipped(temp);
					setMessager("", 10, null, false, texts.ui.view);
				}}
				onOut={() => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={() => {
					setCoding(true);
					setCoded(true);
				}}
				rotation={[0, 0, 0]} />
			</Suspense>

	</group>)
}

export default React.memo(Mirror);
