import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import * as THREE from 'three'
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"
import AnimatedImage from "../AnimatedImage.js"

const LabFoyer = ({ images, setMessager, roomX, roomY, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, setCoding, seeds, texts, inventory, stackdao }) => {

	let assembly = [];
	for (let i = 0; i < 4; i++) {
		assembly.push([<AnimatedImage
			image={ images(`./echo/reticule.png`).default }
			geometry={<boxGeometry/>}
			repeat={false}
			material='wireframe'
			position={[i * -10, 0, -5]}
			scale={1}
			animPosition={[0.1, 0, 0]}
			rotation={[0, 0, 0]}
			onMove={() => {
				document.body.style.cursor = 'context-menu';
				setMessager(texts.ui.blackbox, 10, null, false, texts.ui.debug);
			}}
			onOut={() => {
				document.body.style.cursor = 'default';
				setMessager();
			}}
			onClick={() => {
				setCode({
					...code,
					header: 'assembly',
					input: [],
					algorithm: [{id: 'bias'}, {id: 'other'}],
				});
				setCoding(true);
			}}
			/>,
		<AnimatedImage
			image={ images(`./echo/reticule.png`).default }
			repeat={false}
			geometry={<planeGeometry args={[2,2]}/>}
			material='wireframe'
			position={[i * -10, -2, -5]}
			scale={[1,1,1]}
			animPosition={[0.1, 0, 0]}
			rotation={[Math.PI/2, 0, 0]}
			/>]);
	}

	return (<group>
			<Suspense fallback={<Loader/>}>


			{assembly}

			<mesh
				rotation={[-Math.PI/3, 0, 0]}
				position={[0, -5, -5]}
				scale={[40, 3, 1]}
				onPointerOver={(e) => {
					document.body.style.cursor = 's-resize';
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = 'default';
					setMessager();
				}}
				onClick={(e) => {
					setRoomX(roomX + 1);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshLambertMaterial transparent={true} opacity={0}></meshLambertMaterial>
			</mesh>

			</Suspense>
	</group>)
}

export default React.memo(LabFoyer);
