import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import Blockhead from "../Blockhead.js"
import NPC from "../NPC.js"

const pageWidth = 30;
const pageHeight = 20;
const pageDepth = -40;
const linkDepth = pageDepth + 10;

const HallRoom = ({ reported, images, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, seeds, wait, saveStatChange, armed, setMessager, texts, useKeypress, hallup, hallcutscene, hallt, hallr, halll, hallx, hallend, blackbox, setBlackbox, setCoding, inventory, affiliations, plainroom, setGlitchOn, H1Chest, H1Monitor, go, blackboxarray, hintersteps, setHintersteps, hallupstairs }) => {

	useEffect(() => {
		setHintersteps(hintersteps + 1);
	}, [roomX, roomY]);

	let hall = null;
	let special = [];

	let option = Math.floor(seeds[0] * 3);

	if (hintersteps % 4 == 0) {
		hall = hallup;
	} else if (hintersteps % 4 == 1) {
		if (option == 0) hall = hallt
		else if (option == 1) hall = hallr
		else if (option == 2) hall = halll
	} else if (hintersteps % 4 == 2) {
		hall = hallx;
	} else if (hintersteps % 4 == 3) {
		if (option == 0) hall = hallt
		else if (option == 1) hall = hallr
		else if (option == 2) hall = halll
	}

	let guest = [];
	if (hintersteps == 6) {
		hall = hallup;
	} else if (hintersteps == 5) { // NPSEE
		setBlackbox(false);
		hall = hallcutscene;
		if (!code.input.find(d=>d.id == 'npsee')) guest = <NPC
			scale={2}
			position={[0, 0, -20]}
			rotation={[0, -Math.PI/2, 0]}
			image={images(`./avatars/avatar_6.png`).default}
			geometry={<sphereGeometry attach="geometry" />}
			material='phong'
			onOver={(e) => {
				document.body.style.cursor = "help";
				setMessager("", 10, null, false, texts.ui.debug);
				setCode({
					...code,
					'header': 'memory',
					'input': inventory,
					'algorithm': [{id: 'facedata'}, {id: 'stigmata'}, {id: 'npcshell'}, {id: 'manifesto'}]
				});
			}}
			onClick={(e) => {
				setCoding(true);
				setMessager("", 40, null, true);
			}}
			onOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
		/>;
	} else if (hintersteps == 9) {
		setBlackbox(true);
		hall = plainroom;
	} else if (hintersteps >= 13) {
		setBlackbox(false);
		hall = hallupstairs;
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			{hall}
			{guest}
			{ blackbox && <Image
				scale={4}
				rotation={[0, Math.PI * (seeds[19] - 0.9) * 20, 0]}
				position={[0, -5, -20]}
				image={images(`./bg-stars.png`).default}
				geometry={<boxGeometry attach="geometry" />}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onOver={(e) => {
					document.body.style.cursor = "context-menu";
					setCode({
						...code,
						'header': 'blackbox',
						'input': inventory.concat(special),
						'algorithm': blackboxarray
					});
					setMessager(texts.ui.blackbox, 30, null, false, texts.ui.debug);
				}}
				onClick={(e) => {
					setCoding(true);
				}} /> }
		</Suspense>

	</group>)
}

export default React.memo(HallRoom);
