import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import $ from 'jquery';
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import Loader from "../Loader.js"

let timeout = null;
let newValue = null;

const Servers = ({ images, setMessager, setRoomZ, doGlitch, stopGlitch, clip, setSpeed, code, setCode, coding, locked1, locked2, texts, setTooltip, setRedirect }) => {

	return (<group>

		<Suspense fallback={<Loader/>}>
			<group>
				<Image
					onOver={(e) => {
						setMessager(texts.egrecore.servers, 30, null, false, texts.egrecore.looktax);
						timeout = setInterval(() => {
							newValue = parseFloat($('#networth').html()) - 0.01;
							newValue = Math.round(newValue * 100) / 100;
				      $('#networth').html(newValue);
				    }, 1000);
					}}
					onOut={(e) => {
						setMessager();
						clearInterval(timeout);
					}}
					scale={[30, 10, 4]}
					rotation={[0, Math.PI / 2, 0]}
					position={[15, -2, -30]}
					image={images(`./echo/servers.png`).default}
					geometry={<boxGeometry attach="geometry" />}
					material='phong'
					repeat={true}
					castShadow={true}
					textureRepeat={1}
					receiveShadow={true} />
				<Image
					onOver={(e) => {
						setMessager(texts.egrecore.servers, 30, null, false, texts.egrecore.looktax);
						timeout = setInterval(() => {
							newValue = parseFloat($('#networth').html()) - 0.01;
							newValue = Math.round(newValue * 100) / 100;
				      $('#networth').html(newValue);
				    }, 1000);
					}}
					onOut={(e) => {
						setMessager();
						clearInterval(timeout);
					}}
					scale={[30, 10, 4]}
					rotation={[0, Math.PI / 2, 0]}
					position={[5, -2, -30]}
					image={images(`./echo/servers.png`).default}
					geometry={<boxGeometry attach="geometry" />}
					material='phong'
					repeat={true}
					castShadow={true}
					textureRepeat={1}
					receiveShadow={true} />
				<Image
					onOver={(e) => {
						setMessager(texts.egrecore.servers, 30, null, false, texts.egrecore.looktax);
						timeout = setInterval(() => {
							newValue = parseFloat($('#networth').html()) - 0.01;
							newValue = Math.round(newValue * 100) / 100;
				      $('#networth').html(newValue);
				    }, 1000);
					}}
					onOut={(e) => {
						setMessager();
						clearInterval(timeout);
					}}
					scale={[30, 10, 4]}
					rotation={[0, Math.PI / 2, 0]}
					position={[-5, -2, -30]}
					image={images(`./echo/servers.png`).default}
					geometry={<boxGeometry attach="geometry" />}
					material='phong'
					repeat={true}
					castShadow={true}
					textureRepeat={1}
					receiveShadow={true} />
			</group>
			<Image
				key='floor'
				image={ images(`./checkerboard.png`).default }
				material='phong'
				repeat={true}
				position={[0, -7, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'ceiling'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[0, 10, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'backwall'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[0, 0, -50]}
				scale={68}
				rotation={[0, 0, 0]} />
			<Image
				key={'rightwall'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[20, 0, -10]}
				scale={68}
				rotation={[0, -Math.PI / 2, 0]} />
			<Image
				key={'leftwall'}
				image={ images(`./brick.jpg`).default }
				material='phong'
				repeat={true}
				position={[-20, 0, -10]}
				scale={68}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				key={'elevator'}
				image={images(`./echo/outelevator.png`).default}
				onOver={(e) => {
					document.body.style.cursor = "grab";
          setTooltip("ELEVATOR");
        }}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setTooltip("");
				}}
				onClick={(e) => {
					setRoomZ('elevator');
				}}
				repeat={false}
				position={[-19, -3, -30]}
				scale={20}
				rotation={[0, Math.PI / 2, 0]} />
			<Image
				image={images(`./avatars/avatar38.png`).default}
				onOver={() => {
					document.body.style.cursor = "help";
					setMessager("", 10, null, false, texts.ui.read);
				}}
				onOut={() => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={() => {
					document.body.style.cursor = "default";
					setRedirect(<Redirect to={{ pathname: '/protocols' }} />);
				}}
				repeat={false}
				position={[-19, 0, -15]}
				scale={8}
				rotation={[0, Math.PI / 2, 0]} />
		</Suspense>

	</group>)
}

export default React.memo(Servers);
