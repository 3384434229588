import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import useKeypress from 'react-use-keypress';

import Image from "../Image.js"
import texts from "../../data/texts.js"

const Hall = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, code, setCode, go, onExit }) => {

	return (

		<group>

			<group>
			<Image
				key='floor'
				image={ images(`./checkerboard.png`).default }
				material='phong'
				repeat={true}
				position={[0, -7, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'ceiling'}
				image={ images(`./panels.jpg`).default }
				material='phong'
				repeat={true}
				textureRepeatX={2}
				textureRepeatY={20}
				position={[0, 10, -10]}
				scale={68}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'backwall'}
				image={ images(`./backrooms.png`).default }
				material='phong'
				repeat={true}
				textureRepeatY={1}
				textureRepeatX={10}
				position={[0, 0, -30]}
				scale={[200, 14, 1]}
				rotation={[0, 0, 0]} />
			<Image
				key={'rightwall'}
				image={ images(`./backrooms.png`).default }
				material='phong'
				repeat={true}
				textureRepeatY={1}
				textureRepeatX={10}
				position={[20, 0, -10]}
				scale={[200, 14, 1]}
				rotation={[0, -Math.PI / 2, 0]} />
			<Image
				key={'leftwall'}
				image={ images(`./backrooms.png`).default }
				material='phong'
				repeat={true}
				textureRepeatY={1}
				textureRepeatX={10}
				position={[-20, 0, -10]}
				scale={[200, 14, 1]}
				rotation={[0, Math.PI / 2, 0]} /></group>

		</group>

	)
}

export default memo(Hall);
