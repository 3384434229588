import React, { useState, useEffect, memo } from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import Writer from "../components/Writer.js"

const Debugger = ({ message, avatar, align, speed, code, setCode, coding, setCoding, setMessage, setWait, equipped, debugging, wait, setMessager, inventory, images, texts }: Props) => {
  const [isShown, setIsShown] = useState(false);
	const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  let debugmode = 'off';

  if (debugmode != 'debug' && coding) debugmode = 'debug';
  else if (debugmode != 'warn' && message != null && (message.indexOf("WARNING") != -1 || message.indexOf("PARANORMAL") != -1 || message.indexOf("UNSTABLE") != -1)) debugmode = 'warn';
  else if (debugmode != 'write' && wait) debugmode = 'write';
  else if (debugmode != 'off') debugmode = 'off';

  return (
	<div className='debugger'>
		<div className='image'>
			<img
        src={ images(`./debugger-${debugmode}.png`).default }
        onMouseOver={() => {
          if (wait || !debugging || coding) return
          document.body.style.cursor = "context-menu";
          setCode({
            ...code,
            'header': 'debugger',
            'input': inventory,
            'algorithm': equipped,
          });
          debugmode = 'debug';
          setMessage(texts.ui.debugger);
        }}
        onMouseOut={() => {
          document.body.style.cursor = "default";
          if (avatar == null) setMessage("");
          debugmode = 'off';
        }}
        onClick={() => {
          if (wait || !debugging || coding) return
          setCoding(true);
        }}
        >
      </img>
		</div>
		<div className='textbox'>
			<Writer className='debug-message' msg={message} speed={speed} setWait={setWait}></Writer>
		</div>
    <div className='avatar' style={{ visibility: avatar ? 'visible' : 'hidden' }}>
      {avatar != 'anon' && <img src={ avatar }></img>}
      {avatar == 'anon' && <div className='anon'>{"ANON"}</div>}
    </div>
    <div className='alert'>
      {avatar == null ? <div></div> : <Writer msg={"<<< MESSAGE"} />}
    </div>
	</div>)
};

export default memo(Debugger);
