import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { Redirect } from "react-router-dom";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"
import AnimatedImage from "../AnimatedImage.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue, values, setRedirect, setCoding }) => {

	const [ released, setReleased ] = useState(false);

	let toRead = 'master';
	if (roomZ != 'master') toRead = 'pipe' + (roomX - 100);

	return (<group>

		<Suspense fallback={<Loader/>}>
			<mesh scale={50} position={[-30, 0, -20]}
				onPointerMove={(e) => {
					document.body.style.cursor = "zoom-out";
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					if (roomZ == 'master') {
						setRoomZ(-3);
					} else {
						setRoomX(101);
						setRoomZ(-1);
					}
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial color='#000000'></meshBasicMaterial>
			</mesh>
			<mesh scale={50} position={[30, 0, -20]}
				onPointerMove={(e) => {
					document.body.style.cursor = "zoom-out";
					setMessager("", 10, null, false, texts.ui.back);
				}}
				onPointerOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					if (roomZ == 'master') {
						setRoomZ(-3);
					} else {
						setRoomX(101);
						setRoomZ(-1);
					}
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial color='#000000'></meshBasicMaterial>
			</mesh>
			<mesh scale={[3,5,1]} position={[-1, 0, -10]}
				onPointerMove={(e) => {
					readValue(toRead, null, true);
				}}
				onPointerOut={(e) => {
					readValue();
				}}
				onClick={(e) => {
					readValue(toRead, -1, true);
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial color='#000000'></meshBasicMaterial>
			</mesh>
			<mesh scale={[3,5,1]} position={[1, 0, -10]}
				onPointerMove={(e) => {
					readValue(toRead);
					document.body.style.cursor = "grab";
				}}
				onPointerOut={(e) => {
					readValue();
				}}
				onClick={(e) => {
					readValue(toRead, 1);
					if (roomZ == 'master') {
						setReleased(true);
						setTimeout(() => {
							setRedirect(<Redirect to={{ pathname: '/exploded' }} />);
						}, 5000);
					}
					document.body.style.cursor = "grab";
				}}
				>
				<planeGeometry></planeGeometry>
				<meshBasicMaterial color='#000000'></meshBasicMaterial>
			</mesh>
			<group>
				<mesh position={[0, 0, -10]} scale={[1, 60, 1]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<mesh position={[0, 0, -10]} scale={[2, 2, 1]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<mesh position={[0, 0, -10]} scale={[0.3, 10, 0.3]} rotation={[Math.PI/2,0,0]}>
					<cylinderGeometry></cylinderGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				<Image position={[0, 4.5, -8]} scale={[2, 4, 2]} rotation={[0,0,0]}
					image={images(`./echo/pages/right_7${roomZ == 'master' ? 9 : roomX - 100 + 1}.png`).default}
					material='phong'
					onMove={(e) => {
						document.body.style.cursor = "help";
						setCode({
							...code,
							header: 'policy',
							input: [],
							algorithm: [{id: roomZ == 'master' ? texts.oceandao.master : texts.oceandao.policies[roomX - 101] }],
						});
						setMessager("", 10, null, false, texts.ui.read);
					}}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						setCoding(true);
						setMessager(texts.ui.policy, 10, null, true);
					}}
				/>
				<mesh position={[0, 5, -10]} scale={[2, 4, 2]} rotation={[0,0,0]}>
					<boxGeometry></boxGeometry>
					<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
				</mesh>
				{!released && <Image
					onOver={(e) => {
						document.body.style.cursor = "zoom-in";
						setMessager("", 10, null, false, texts.ui.view);
					}}
					onOut={(e) => {
						document.body.style.cursor = "default";
						setMessager();
					}}
					onClick={(e) => {
						// setRoomY(roomY + 1);
					}}
					image={ images(`./echo/wheel2.png`).default }
					material='phong'
					repeat={false}
					position={[0, 0, -8]}
					scale={4}
					rotation={[0, 0, values[toRead]]}
					/>}
				{released && <AnimatedImage
					image={ images(`./echo/wheel2.png`).default }
					material='phong'
					repeat={false}
					position={[0, 0, -8]}
					scale={4}
					animRotation={[0, 0, -0.1]}
					rotation={[0, 0, 0]} />}
			</group>
		</Suspense>


	</group>)
}

export default React.memo(Room);
