import React, { useState, useEffect, memo } from "react";
import $ from 'jquery';

const Writer = ({ delay, speed, msg, setWait }) => {
  const Typer = ({ speed = 10, children = "" }) => {

  const [idx, setidx] = useState(0);
	const [show, setShow] = React.useState(false)

	useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)

    return () => clearTimeout(timeout)

  }, [show])

  useEffect(() => {
    if (idx >= children.length) {
      if (setWait != null) {
        setWait(false);
      }
      return
    }
    const timer = window.setInterval(() => setidx((v) => (show ? v + 1 : v)), speed);
    return () => window.clearInterval(timer);
  });

	if (!show) return null

  return <div>{children.substr(0, idx)}</div>;
};
return (
  <div>
    <Typer speed={speed} children={msg}></Typer>
  </div>
 );
};

export default memo(Writer);
