import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef, forwardRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import $ from 'jquery';
import useKeypress from 'react-use-keypress';
import texts from "../data/texts.js"

import { useAuth0 } from "@auth0/auth0-react";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import axios from "axios";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Shooter from "../components/Shooter.js"
import Valuator from "../components/Valuator.js"
import Lantern from "../components/Lantern.js"
import Coder from "../components/Coder.js"
import Voter from "../components/Voter.js"
import Logs from "../components/Logs.js"
import Image from "../components/Image.js"

import HallRoom from "../components/halls/HallRoom.js"
import HallUp from "../components/halls/HallUp.js"
import HallEnd from "../components/halls/HallEnd.js"
import HallT from "../components/halls/HallT.js"
import HallR from "../components/halls/HallR.js"
import HallL from "../components/halls/HallL.js"
import HallX from "../components/halls/HallX.js"
import PlainRoom from "../components/halls/PlainRoom.js"
import HallUpstairs from "../components/halls/HallUpstairs.js"
import HallDownstairs from "../components/halls/HallDownstairs.js"
import Backroom from "../components/rooms/Backroom.js"

import Captcha0 from "../components/rooms/Captcha0.js"
import Captcha from "../components/rooms/Captcha.js"
import Captcha1 from "../components/rooms/Captcha1.js"
import PageRoom from "../components/rooms/PageRoom.js"
import Elevator from "../components/rooms/Elevator.js"
import Servers from "../components/rooms/Servers.js"
import Controltower from "../components/rooms/Controltower.js"
import Controlmap from "../components/rooms/Controlmap.js"
import Egrecore from "../components/rooms/Egrecore"

import Arena from "../components/rooms/Arena"
import ArenaEntrance from "../components/rooms/ArenaEntrance"
import ArenaLobby from "../components/rooms/ArenaLobby"
import ArenaOutside from "../components/rooms/ArenaOutside"
import ArenaPassage from "../components/rooms/ArenaPassage"
import ArenaOffice from "../components/rooms/ArenaOffice"
import ArenaCourtyard from "../components/rooms/ArenaCourtyard"
import ArenaHallway1 from "../components/rooms/ArenaHallway1"
import ArenaHallway from "../components/rooms/ArenaHallway"
import ArenaHiveEntrance from "../components/rooms/ArenaHiveEntrance"
import ArenaHive from "../components/rooms/ArenaHive"
import ArenaHives from "../components/rooms/ArenaHives"
import ArenaTower from "../components/rooms/ArenaTower"
import ArenaDepth from "../components/rooms/ArenaDepth"
import ArenaLab from "../components/rooms/ArenaLab"
import ArenaQuarantine from "../components/rooms/ArenaQuarantine"
import Hallucinogen from "../components/rooms/Hallucinogen.js"

import Church from "../components/rooms/Church.js"
import Scripture from "../components/rooms/Scripture.js"
import Garden from "../components/rooms/Garden.js"
import Graveyard from "../components/rooms/Graveyard.js"
import TrustWall from "../components/rooms/TrustWall.js"
import Brick1 from "../components/rooms/Brick1.js"
import Brick2 from "../components/rooms/Brick2.js"
import MirrorRoom from "../components/rooms/MirrorRoom.js"
import Mirror from "../components/rooms/Mirror.js"

import Dock from "../components/rooms/Dock"
import Dock2 from "../components/rooms/Dock2"
import Boat from "../components/rooms/Boat"
import Island from "../components/rooms/Island"
import OceanTower from "../components/rooms/OceanTower"
import Ocean1 from "../components/rooms/Ocean1"
import OceanGallery from "../components/rooms/OceanGallery"
import OceanHall from "../components/rooms/OceanHall"
import OceanHatch from "../components/rooms/OceanHatch"
import OceanQuarters from "../components/rooms/OceanQuarters"
import OceanTemple from "../components/rooms/OceanTemple"
import OceanEngine from "../components/rooms/OceanEngine"
import OceanPipe from "../components/rooms/OceanPipe"

import PipeEnd from "../components/halls/PipeEnd"
import PipeUp from "../components/halls/PipeUp"
import PipeT from "../components/halls/PipeT"
import PipeL from "../components/halls/PipeL"
import PipeR from "../components/halls/PipeR"
import PipeLFork from "../components/halls/PipeLFork"
import PipeRFork from "../components/halls/PipeRFork"
import PipeRoom from "../components/halls/PipeRoom"
import PipeValve from "../components/halls/PipeValve"
import PipeMultiplex from "../components/halls/PipeMultiplex"

import StackEntrance from "../components/rooms/StackEntrance"
import LabWall from "../components/rooms/LabWall.js"
import LabExit from "../components/rooms/LabExit.js"
import LabFoyer from "../components/rooms/LabFoyer.js"
import LabTube from "../components/rooms/LabTube.js"
import LabCouncil from "../components/rooms/LabCouncil.js"
import LabPandemonium from "../components/rooms/LabPandemonium.js"
import LabWorkspace from "../components/rooms/LabWorkspace.js"
import LabHabitat from "../components/rooms/LabHabitat.js"
import LabFountain from "../components/rooms/LabFountain.js"
import LabFork from "../components/rooms/LabFork.js"
import LabHall from "../components/rooms/LabHall.js"


import * as THREE from 'three'
import { Box, Plane, Loader } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame, extend } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { EffectComposer, Noise, Pixelation, Glitch, GlitchMode, Scanline, Sepia, DotScreen } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import CameraControls from 'camera-controls'

const roomXOverride = 0;
const roomYOverride = -5;
const roomZOverride = '';
const headedOverride = 'n';
const noMessageMode = false;

CameraControls.install({ THREE })
extend({ CameraControls })

function Controls({ freedom }) {
  const ref = useRef()
  const camera = useThree((state) => state.camera)
  const gl = useThree((state) => state.gl)
  useFrame((state, delta) => {
    ref.current.azimuthAngle = -state.mouse.x * freedom;
    ref.current.polarAngle = Math.PI / 2 + state.mouse.y * freedom;
    ref.current.update(delta)
  })
  return <cameraControls minDistance={1} maxDistance={1} azimuthRotateSpeed={-0.3} polarRotateSpeed={-0.3} truckSpeed={0} ref={ref} args={[camera, gl.domElement]} />
}

function PhyPlane({ color, ...props }) {
  const [ref] = usePlane(() => ({ ...props }));
  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    ref.current.rotation.x = a;
  });

  return (
      <Plane args={[1000, 1000]} ref={ref} castShadow={true} receiveShadow={true}>
        <meshStandardMaterial color={color} />
      </Plane>
  );
}

const images = require.context('../assets', true);

const Echo = ({ lifespan, ...props }) => {

  const [ diagram, setDiagram ] = useState(null);
  const [ puredao, setPuredao ] = useGetAndSet('puredao');
  const [ stackdao, setStackdao ] = useGetAndSet('stackdao');
  const [ empiredao, setEmpiredao ] = useGetAndSet('empiredao');
  const [ floor, setFloor ] = useGetAndSet('floor');
  const [ language, setLanguage ] = useGetAndSet('language');
  const [ lab, setLab ] = useGetAndSet('laboratory');
  const [ quar, setQuar ] = useGetAndSet('quarantine');
  const [ values, setValues ] = useGetAndSet('values');
  const [ selfvalue, setSelfvalue ] = useState(10);
  const [ value, setValue ] = useState(null);
  const [ hintersteps, setHintersteps ] = useGetAndSet('hintersteps');
  const [ stalker, setStalker ] = useState([111, -80]);
  const [ obelisk, setObelisk ] = useGetAndSet('obelisk');
  const [ seeds, setSeeds ] = useState([
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0
  ]);

  const [ redirect, setRedirect ] = useState(null);
  const [ cameraDistance, setCameraDistance ] = useState(0);
  const [ tooltip, setTooltip ] = useState("");
  const [ roomX, setRoomX ] = useState(props != null && props.location != null && props.location.state != null && props.location.state.room != null ? props.location.state.room[0] : 0);
  const [ roomY, setRoomY ] = useState(props != null && props.location != null && props.location.state != null && props.location.state.room != null ? props.location.state.room[1] : 0);
  const [ roomZ, setRoomZ ] = useState(props != null && props.location != null && props.location.state != null && props.location.state.room != null ? props.location.state.room[2] : 0);
  const [ headed, setHeaded ] = useState('n');
  const [ destination, setDestination ] = useState([{id: 'dock'}]);
  const [ affiliations, setAffiliations] = useState({});
  const managementAPIToken = useStore('managementAPIToken')[0];
  const [ latestRoom, setLatestRoom ] = useStore([0, -1]);
  const [ message, setMessage ] = useState("");
  const [ avatar, setAvatar ] = useState(null);
  const [ speed, setSpeed ]  = useState(40);
  const [ reported, setReported ] = useState(false);
  const [ wait, setWait ] = useState(false);
  const [glitchOn, setGlitchOn] = useState(false);
  const [locked1, setLocked1] = useState(true);
  const [locked2, setLocked2] = useState(true);
  const [H1Monitor, setH1Monitor] = useState([{id: 'scarecrow'}]);
  const [blackboxarray, setBlackboxarray] = useState([]);
  const [H1Chest, setH1Chest] = useState([{id: 'door'}]);
  const [WMonitor, setWMonitor] = useState([{id: 'scarecrow'}]);
  const [MMirror, setMMirror] = useState([]);
  const [family, setFamily] = useGetAndSet('family');
  const [cooldown, setCooldown] = useState(false);
  const [shooting, setShooting] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [analyzer, setAnalyzer] = useGetAndSet('analyzer');
  const [clip, setClip] = useState(8);
  const [mags, setMags] = useState(3);
  const [trust, setTrust] = useState(0);
  const [coding, setCoding] = useState(false);
  const [voting, setVoting] = useState([]);
  const [blackbox, setBlackbox] = useState(false);
  const [code, setCode] = useState({
    'header': null,
    'input': [],
    'algorithm': [],
    'output': [],
    'inventory': []
  });
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  let ref = React.useRef();
  let armed = equipped.find(d=>d.id == 'loyalist') != null;
  let loadedValuator = equipped.find(d=>d.id == 'valuator') != null;
  let loadedLantern = equipped.find(d=>d.id == 'lantern') != null;
  let loadedObjectData = equipped.find(d=>d.id == 'objectdata') != null;
  let loadedVisualData = equipped.find(d=>d.id == 'visualdata') != null;
  let loadedScriptData = equipped.find(d=>d.id == 'scriptdata') != null;
  let loadedUIData = equipped.find(d=>d.id == 'uidata') != null;
  let loadedColorData = equipped.find(d=>d.id == 'colordata') != null;
  let remembering = true // equipped.find(d=>d.id == 'memoryblock') != null;
  let hallucinating = false;
  let bones = equipped.find(d=>d.id == 'bones') != null;
  let canVote = roomX < 10 && roomY < 5;

  useEffect(() => {
    if (armed) {
      setCooldown(true);
      setTimeout(() => {
        setCooldown(false);
      }, 100);
    }
    setSeeds([
      Math.random(),Math.random(),Math.random(),Math.random(), Math.random(),Math.random(),Math.random(),Math.random(),
      Math.random(),Math.random(),Math.random(),Math.random(), Math.random(),Math.random(),Math.random(),Math.random(),
      Math.random(),Math.random(),Math.random(),Math.random(), Math.random(),Math.random(),Math.random(),Math.random(),
      Math.random(),Math.random(),Math.random(),Math.random(), Math.random(),Math.random(),Math.random(),Math.random()
    ]);
    setTooltip("");
    if (!shooting) document.body.style.cursor = "default"
    if (roomX < 0 && roomY > 0) {
      setStackdao({ ...stackdao, moves: stackdao.moves + 1 });
    }
    if (roomX < 0 && roomY > 0 && roomZ == -1) {
      setStackdao({ ...stackdao, pan: stackdao.pan + 1 });
    }
  }, [roomX, roomY, roomZ]);

  useEffect(() => {
    if (selfvalue <= 0) setRedirect(<Redirect to={{ pathname: '/flatlined', state: {cause: 'antivalue'} }} />);
  }, [selfvalue]);

  useEffect(() => {
    if (family != null && ((roomX < 0 && roomY >= 101) || (roomX < 0 && roomY < 0 && roomX > -80 && roomY > -80))) setMessager(texts[language].stackdao.reassigned + family.name.toUpperCase(), 30, null, true);
  }, [family]);

  useEffect(() => {
    if (!coding) return
    switch (code.header) {
      case 'egrecore':
        if (code.algorithm.length != 0) {
          let world = code.algorithm[0];
          if (!remembering) setRedirect(
            <Redirect
            to={{
              pathname: 'informing',
              state: {report: world.id, route: world.route}
            }}/>);
          else {
            setEquipped([...equipped, code.algorithm[0]]);
            setRoomX(world.route[0]);
            setRoomY(world.route[1])
            setRoomZ(world.route[2]);
            setCoding(false);
          }
        }
      break;
      case 'shooter':
        if (code.algorithm.find(d=>d.id == 'bulletOut')) setClip(8);
        if (code.input.find(d=>d.id == 'loyalist')) {
          setInventory([...inventory, {id: 'loyalist'}]);
          let remove = equipped.findIndex(d=>d.id == 'loyalist');
          let temp = equipped.slice();
          temp.splice(remove, 1);
          setEquipped(temp);
        }
      break;
      case 'debugger':
        if (inventory.find(d=>d.type == 'dao')) {
          setRoomX(0);
          setRoomY(0);
          setRoomZ('egrecore');
        }
      break;
      case 'analyzer':
        ['bones', 'hallucin', 'scarecrow', 'valueshard', 'stigmata', 'manifesto'].forEach((d,i) => {
          let search = code.algorithm.find(x=>x.id == d);
          if (search) {
            setMessager(texts[language].analyzer[d], 30, images('./face-1.png').default, true);
            setAnalyzer( analyzer.concat([search]) );
            setCode({ ...code, algorithm: [{id: 'analyzer', trustreq: 99999}] });
          }
        });
      break;
      case 'captcha0':
        if (code.algorithm.find(d=>d.id == 'irrationality')) {
          setCoding(false);
          setMessager(texts[language].captcha.wrong, 30, images(texts[language].captcha.avatar).default, true);
          setStackdao({ ...stackdao, trust: stackdao.trust - 1 });
        }
      break;
      case 'captcha1':
        if (code.algorithm.find(d=>d.id == 'irrationality')) {
          setCoding(false);
          setRoomY(2);
        }
      break;
      case 'captcha2':
        if (code.algorithm.find(d=>d.id == 'irrationality')) {
          setCoding(false);
          setMessager(texts[language].captcha.wrong, 30, images(texts[language].captcha.avatar).default, true);
          setStackdao({ ...stackdao, trust: stackdao.trust - 1 });
        }
      break;
      case 'laboratory':
        let normality = 0;
        if (code.algorithm.length == 1 && code.algorithm[0].id == 'portal') break
        for (let i = 0; i < code.algorithm.length; i++) normality += code.algorithm[i].normal;
        if (normality < 0) {
          setMessager(texts[language].puredao.abnormal, 10);
        } else if (normality > 0) {
          setMessager(texts[language].puredao.normal, 10);
        } else {
          setMessager(texts[language].puredao.stable, 10);
        }
      break;
      case 'quarantine':
        if (code.algorithm.length == 0) {
          setInventory([...inventory, {id: 'scarecrow'}]);
          setCoding(false);
          setRoomZ('escape');
          setMessage(texts[language].puredao.free, 10, null, true);
        }
      break;
      case 'boat':
        if (code.algorithm.length == 1) {
          let destination = code.algorithm[0].id;
          if (destination == 'clan0') {
            setCoding(false);
            setRoomX(100);
            setRoomY(-99);
            setRoomZ(0);
          }
          if (destination == 'clan1') {
            setCoding(false);
            setRoomX(0);
            setRoomY(0);
            setRoomZ('egrecore');
          }
        }
      break;
      case 'scripture':
        if (code.algorithm.find(d=>d.id == 'mirage')) {
          setRedirect(<Redirect to={{ pathname: '/flatlined', state: {cause: 'mirage'} }} />);
        }


        // if (loadedBubble && loadedDenmark && loadedCodeblock && loadedMetablock) {
          // setMessager(texts[language].empiredao.error, 10, null, false);
        // } else {
            if (code.algorithm.find(d=>d.id == 'divinity')) setMessager(texts[language].empiredao.proposed, 10, null, false);
        // }
      break;
      case 'lantern':
        if (roomX == 100 && roomY == 102 && code.algorithm.find(d=>d.id == 'objectdata')) {
          setCoding(false);
          setMessager(texts[language].report.empiredao, 30, null, true);
        }
      break;
      case 'memory':
        if (code.input.find(d=>d.id == 'manifesto')) {
          setCoding(false);
          setRoomY(roomY + 1);
        }
      break;
      case 'graveyardNPC':
        if (code.input.find(d=>d.id == 'graveyardNPC')) {
          setHintersteps(hintersteps + 1);
          setRoomZ(0);
        }
      break;
      case 'blackbox':
        if (code.algorithm.find(d=>d.id == 'manifesto')) setRedirect(<Redirect to={{ pathname: '/manifesto' }} />);
      break;
      case 'assembly':
        setStackdao({ ...stackdao, coding: stackdao.coding + 1 });
      break;
    }
  }, [code]);

  function doGlitch() {
    setGlitchOn(true);
  }

  function stopGlitch() {
    setGlitchOn(false);
  }

  function setMessager(message, speed, avatar, waitNew, tip, diagram) {
    if (wait) return
    if (message == null) message = "";
    if (speed == null) speed = 10;
    if (tip == null) tip = "";
    if (noMessageMode) {
      message = "";
      avatar = null;
      waitNew = false;
    }
    if (roomX < 0 && roomY > 0) {
      if (tip.length > 0) {
        setStackdao({ ...stackdao, curiosity: stackdao.curiosity + 1 });
      }
      if (message.length > 0 && !stackdao.social.includes(message)) {
        setStackdao({ ...stackdao, social: [...stackdao.social, message] });
      }
    }
    setSpeed(speed);
    setAvatar(avatar);
    setWait(waitNew);
    setTooltip(tip);
    setMessage(message);
    // setDiagram(diagram);
  }

  function go(direction, amount) {
    if (blackbox) {
      setBlackbox(false);
    }
    // else if (seeds[19] > 0.9) {
    //   setBlackbox(true);
    // } else {
    //   setBlackbox(false);
    // }
    let a = amount != null ? amount : 1
    setMessager();
    if (headed == 'n') {
      if (direction == 'u') {
        setRoomY(roomY + a);
      } else if (direction == 'd') {
        setRoomY(roomY - a);
        setHeaded('s');
      } else if (direction == 'l') {
        setRoomX(roomX - a);
        setHeaded('w');
      } else if (direction == 'r') {
        setRoomX(roomX + a);
        setHeaded('e');
      }
    } else if (headed == 's') {
      if (direction == 'u') {
        setRoomY(roomY - a);
      } else if (direction == 'd') {
        setRoomY(roomY + a);
        setHeaded('n');
      } else if (direction == 'l') {
        setRoomX(roomX + a);
        setHeaded('e');
      } else if (direction == 'r') {
        setRoomX(roomX - a);
        setHeaded('w');
      }
    } else if (headed == 'w') {
      if (direction == 'u') {
        setRoomX(roomX - a);
      } else if (direction == 'd') {
        setRoomX(roomX + a);
        setHeaded('e');
      } else if (direction == 'l') {
        setRoomY(roomY - a);
        setHeaded('s');
      } else if (direction == 'r') {
        setRoomY(roomY + a);
        setHeaded('n');
      }
    } else if (headed == 'e') {
      if (direction == 'u') {
        setRoomX(roomX + a);
      } else if (direction == 'd') {
        setRoomX(roomX - a);
        setHeaded('w');
      } else if (direction == 'l') {
        setRoomY(roomY + a);
        setHeaded('n');
      } else if (direction == 'r') {
        setRoomY(roomY - a);
        setHeaded('s');
      }
    }
  }

  function UI() {

    const light = useRef();
    const { viewport, mouse } = useThree();
    const tgt = useRef();

    useFrame(({state, clock}) => {
      const t = clock.getElapsedTime();
      let x = (mouse.x * viewport.width / 2) * 8;
      let y = (mouse.y * viewport.height / 2) * 8;
      let z = -5;
      // if (bones) {
      //   x *= -1;
      //   y *= -1;
      // }
      // if (hallucinating) {
      //   z += Math.cos(t);
      // }
      tgt.current.position.set(x, y, z);
    });

    const target = new THREE.Mesh(new THREE.SphereGeometry(0.1, 0.1, 0.1), new THREE.MeshBasicMaterial({ transparent: true }));

    return ([
      <primitive key='uiPrimitive' object={target} ref={tgt} visible={false} />,
      <spotLight key='uiLight' ref={light} distance={0} intensity={1} color="white" transparent='true' penumbra={1} angle={Math.PI / 8} castShadow={true} target={target} >
      </spotLight>
    ])

  }

  function ContextHandler() {
    const ref = useRef();

    const { gl } = useThree();
    useEffect(()=>{
      gl.getContext().canvas.addEventListener('webglcontextlost', function(event) {
        event.preventDefault();
        setTimeout(() => gl.forceContextRestore(), 1000);
      }, false);
      gl.getContext().canvas.addEventListener('webglcontextrestored', function(event) {
        event.preventDefault();
      }, false);
    },[]);

    return (<Plane args={[1, 1]} ref={ref} visible={false}>
      <meshStandardMaterial color={'0xff0000'} />
    </Plane>)
  }


  function shoot() {

    if (!cooldown && armed && document.body.style.cursor != 'help' && document.body.style.cursor != 'n-resize' && document.body.style.cursor != 'context-menu' && clip > 0) {
      setShooting(true);
      setClip(clip - 1);
      setTimeout(() => {
        setShooting(false);
      }, 50);
    }

  }

  function readValue(object, add, devalue) {
    if (!equipped.find(d=>d.id == 'valuator')) {
      setRedirect(<Redirect to={{ pathname: 'valuating', state: {route: [roomX,roomY,roomZ]} }}/>);
    }
    if (parseInt(object) % 1 === 0) return setValue(object);
    if (object == null) {
      document.body.style.cursor = "default";
      setMessager();
      return setValue(null)
    }
    setMessager("", 10, null, false, devalue != null ? texts[language].ui.devalue : texts[language].ui.value);
    document.body.style.cursor = devalue != null ? "grab" : "cell";
    if (add < 0 && values[object] == 0) return
    if (add != null && selfvalue > 0) {
      if (selfvalue - 1 <= 5) {
        setMessager(texts[language].oceandao.warning, 10, null, true);
      }

      setValues({
        ...values,
        [object]: values[object] + add
      });
      setSelfvalue(selfvalue => selfvalue - 1);

      setTimeout(() => {

        if (values[object] > 120) {
          if (add > 0) {
            setMessager(texts[language].oceandao.interest, 40, null, false);
            setSelfvalue(selfvalue => selfvalue + 5);
          } else {
            setMessager(texts[language].oceandao.devested, 40, null, false);
            setSelfvalue(selfvalue => selfvalue - 5);
          }
        } else {
          if (add > 0) {
            setMessager(texts[language].oceandao.lost, 40, null, false);
            setSelfvalue(selfvalue => selfvalue - 5);
          } else {
            setMessager(texts[language].oceandao.haters, 40, null, false);
            setSelfvalue(selfvalue => selfvalue + 5);
          }
        }

      }, 5000 + Math.random() * 10000);

    }
    setValue(values[object] + (add == null ? 0 : add));
  }

  const roomProps = {
    user: user,
    images: images,
    message: message,
    setMessager: setMessager,
    setMessage: setMessage,
    setSpeed: setSpeed,
    setAvatar: setAvatar,
    seeds: seeds,
    roomX: roomX,
    roomY: roomY,
    roomZ: roomZ,
    setRoomX: setRoomX,
    setRoomY: setRoomY,
    setRoomZ: setRoomZ,
    affiliations: affiliations,
    setAffiliations: setAffiliations,
    clip: clip,
    locked1: locked1,
    locked2: locked2,
    code: code,
    setCode: setCode,
    setCoding: setCoding,
    coding: coding,
    doGlitch: doGlitch,
    stopGlitch: stopGlitch,
    setGlitchOn: setGlitchOn,
    reported: reported,
    wait: wait,
    setWait: setWait,
    armed: armed,
    hallucinating: hallucinating,
    voting: voting,
    setVoting: setVoting,
    texts: texts[language],
    useKeypress: useKeypress,
    headed: headed,
    setHeaded: setHeaded,
    go: go,
    equipped: equipped,
    setEquipped: setEquipped,
    blackbox: blackbox,
    setBlackbox: setBlackbox,
    inventory: inventory,
    setInventory: setInventory,
    trust: trust,
    blackboxarray: blackboxarray,
    setTooltip: setTooltip,
    remembering: remembering,
    setRedirect: setRedirect,
    readValue: readValue,
    loadedLantern: loadedLantern,
    visualData: loadedVisualData,
    uiData: loadedUIData,
    colorData: loadedColorData,
    scriptData: loadedScriptData,
    objectData: loadedObjectData,
    puredao: puredao,
    setPuredao: setPuredao,
    empiredao: empiredao,
    setEmpiredao, setEmpiredao,
    stackdao: stackdao,
    setStackdao: setStackdao,
    family: family,
    setFamily: setFamily,
  };
  roomProps.hallup = <HallUp {...roomProps} />;
  roomProps.hallcutscene = <HallUp disableUp={true} disableDown={true} {...roomProps} />;
  roomProps.hallend = <HallEnd {...roomProps} />;
  roomProps.hallt = <HallT {...roomProps} />;
  roomProps.hallr = <HallR {...roomProps} />;
  roomProps.halll = <HallL {...roomProps} />;
  roomProps.hallx = <HallX {...roomProps} />;
  roomProps.plainroom = <PlainRoom {...roomProps} />;
  roomProps.hallupstairs = <HallUpstairs {...roomProps} />;

  let room = null;
  let scanline = <group></group>;
  let freedom = 0.3; // camera movement degrees of freedom
  let debugging = true;
  let governance = null;
  let hud = false;
  let roomValue = null;

  if (roomZ == 'captcha' && roomX == 0 && roomY == 0) {
    room = <Captcha1 {...roomProps} />;
  } else if (roomZ == 'captcha' && roomX == 0 && roomY == 1) {
    room = <Captcha {...roomProps} />;
  } else if (roomZ == 'captcha') {
    room = <Captcha0 {...roomProps} />;
  }

  if (roomZ == 'boat') {
    room = <Boat destination={destination} {...roomProps} />;
  } else if (roomX == 100 && roomY == -100 && roomZ == 0) {
    room = <Dock setValues={setValues} values={values} {...roomProps} />;
  } else if (roomX == 100 && roomY == -99 && roomZ == 0) {
    room = <Island {...roomProps} />;
  } else if (roomX == 100 && roomY == -98 && roomZ == 0) {
    room = <OceanTower {...roomProps} />;
  } else if (roomX == 100 && roomY == -98 && roomZ == -1) {
    room = <Ocean1 up={true} {...roomProps} />;
  } else if (roomX == 100 && roomY == -97 && roomZ == -1) {
    room = <OceanGallery {...roomProps} />;
  } else if (roomX == 101 && roomY == -97 && roomZ == -1) {
    room = <Ocean1 left={true} up={true} face={17} position={[13, 0, -20]} speech={[{id: 'valueshard'}]} {...roomProps} />;
  } else if (roomX == 99 && roomY == -97 && roomZ == -1) {
    room = <Ocean1 right={true} face={16} position={[0, 0, -20]} speech={[{id: 'artwork'}, {id: 'artwork'}, {id: 'valueshard'}]} {...roomProps} />;
  } else if (roomX == 101 && roomY == -96 && roomZ == -1) {
    room = <OceanHall {...roomProps} />;
  } else if (roomX == 101 && roomY == -95 && roomZ == -1) {
    room = <PipeRoom left={true} face={9} speech={[{id: 'valueshard'}, {id: 'grease'}]}  {...roomProps} />;
  } else if (roomX == 101 && roomY == -94 && roomZ == -1) {
    room = <OceanEngine {...roomProps} />;
  } else if (roomX != null && roomY == -94 && roomZ == 'pipe') {
    room = <OceanPipe values={values} {...roomProps} />;
  } else if (roomX == 101 && roomY == -93 && roomZ == -1) {
    room = <PipeEnd up={true} {...roomProps} />;
  } else if (roomX == 101 && roomY == -92 && roomZ == -1) {
    room = <OceanQuarters {...roomProps} />;
  } else if (roomX == 101 && roomY == -91 && roomZ == -1) {
    room = <OceanHatch {...roomProps} />;
  } else if (roomX == 101 && roomY == -91 && roomZ == -2) {
    roomValue = 112;
    room = <OceanTemple obelisk={obelisk} {...roomProps} />;
  } else if (roomX == 101 && roomY == -91 && roomZ == -3) {
    room = <PipeUp {...roomProps} />;
  } else if (roomX == 101 && roomY == -90 && roomZ == -3) {
    room = <PipeEnd up={true} {...roomProps} />;
  } else if (roomX == 101 && roomY == -89 && roomZ == -3) {
    room = <PipeRoom face={11} speech={[{id: 'valueshard'}, {id: 'corrector'}, {id: 'dreadcrumb'}]} right={true} {...roomProps} />;
  } else if (roomZ == 'master') {
    roomValue = 'stalker';
    room = <OceanPipe values={values} {...roomProps} />;
  } else if (roomZ == -3 && (roomX == 110 || roomX == 90 || roomY == -80 || roomY == -100)) {
    roomValue = 'stalker';
    room = <PipeValve {...roomProps} />;
  } else if (roomX == 150 && roomY == 150 && roomZ == 0) {
    room = <Dock2 {...roomProps} />;
  } else if (roomX > 0 && roomY < 0) {
    roomValue = 'stalker';
    room = <PipeMultiplex stalker={stalker} setStalker={setStalker} {...roomProps} />;

  } else if (roomZ == 'graveyard') {
    room = <Graveyard{...roomProps} />;
  } else if (roomX < 0 && roomX > -90 && roomY > -100 && roomY < 90) {
    if (roomZ == 0) {
      room = <HallRoom hintersteps={hintersteps} setHintersteps={setHintersteps} {...roomProps} />;
    } else if (roomZ == 1) {
      room = <Backroom {...roomProps}/>
    }

  } else if (roomX == 100 && roomY == 103 && roomZ == 1) {
    room = <Scripture {...roomProps} />;
  } else if (roomX == 100 && roomY == 103 && roomZ == 0) {
    room = <Church ldoor={1} banners={'curse_29'} carpet={'curse_14'} wins={2} back={[100, 102, 0]} ltext={texts[language].empiredao.halls[0]} scripture={true} {...roomProps}/>;
  } else if (roomX == 102 && roomY == 102 && roomZ == 0) {
    room = <Graveyard {...roomProps}/>;
  } else if (roomX == 100 && roomY == 100 && roomZ == 0) {
    room = <TrustWall {...roomProps} />;
  } else if (roomX == 100 && roomY == 101) {
    room = <Church banners={'curse_74'} cols={1} udoor={1} wins={1} {...roomProps} />;
  } else if (roomX == 100 && roomY == 102 && roomZ == 0) {
    room = <Church cols={2} ldoor={1} rdoor={1} udoor={1} face={13} ltext={texts[language].empiredao.halls[0]} rtext={empiredao.scriptureAlgorithm.find(d=>d.id == 'velvet') ? "GARDEN OF DELIGHT" : texts[language].empiredao.halls[1]} utext={texts[language].empiredao.halls[2]} position={[7, 0, -20]} art={'./echo/watermelon.png'} speech={[{id: 'npclantern'}, {id: 'mark1'}]} {...roomProps} />;
  } else if (roomX == 99 && roomY == 102 && roomZ == 0) {
    room = <Church back={[100, 102, 0]} face={7} speech={[{id: 'npclantern'}, {id: 'mark2'}]} position={[-18, 0, -20]} banners={'curse_27'} carpet={'curse_12'} art={'./echo/pages/curse_3.png'} udoor={1} {...roomProps}></Church>
  } else if (roomX == 99 && roomY == 103 && roomZ == 0) {
    room = <MirrorRoom {...roomProps}/>
  } else if (roomX == 99 && roomY == 103 && roomZ == 'mirror') {
    room = <Mirror {...roomProps} />;
  } else if (roomX == 101 && roomY == 102 && roomZ == 0) {
    room = <Garden {...roomProps}/>;
  }

  if (roomX < 0 && roomY > 0 && roomZ == -1) {
    freedom = 0;
    room = <PageRoom up={true} left={true} right={true} down={true} {...roomProps} />;
  } else if (roomX == -100 && roomY == 100 ) {
    room = <StackEntrance lifespan={lifespan} {...roomProps} />;
  } else if (roomX == -100 && roomY == 101 ) {
    room = <LabFoyer {...roomProps} />;
  } else if (roomX == -100 && roomY == 102 ) {
    room = <LabTube {...roomProps} />;
  } else if (roomX == -100 && roomY == 103 ) {
    room = <LabCouncil {...roomProps} />;
  } else if (roomX == -101 && roomY == 102 ) {
    room = <LabWorkspace {...roomProps} />;
  } else if (roomX == -99 && roomY == 102 ) {
    room = <LabHabitat {...roomProps} />;
  } else if (roomX == -100 && roomY >= 104 ) {
    freedom = 1;
    room = <LabPandemonium {...roomProps} />;
  }

  if (roomX == 0 && roomY == 0) {
    if (roomZ == 0) {
      room = <Controltower analyzer={analyzer} {...roomProps} />;
    } else if (roomZ == 'controlmap') {
      room = <Controlmap analyzer={analyzer} {...roomProps} />;
    } else if (roomZ == 'elevator') {
      room = <Elevator floor={floor} setFloor={setFloor} { ...roomProps} />;
    } else if (roomZ == 'servers') {
      room = <Servers {...roomProps} />;
    } else if (roomZ == 'egrecore') {
      room = <Egrecore {...roomProps} />;
    }
  }

  if (roomZ == 'escape') {
    if (roomY == -90) {
      setRoomX(0);
      setRoomY(0);
      setRoomZ('egrecore');
    } else {
      freedom = 0;
      room = <PageRoom up={true} enemy={roomY + 95} {...roomProps} />;
    }
  } else if (roomX == -100 && roomY == -100) {
    room = <ArenaEntrance {...roomProps} />;
  } else if (roomX == -100 && roomY == -99 && roomZ == 5) {
    room = <ArenaHallway1 {...roomProps} />;
  } else if (roomX == -100 && roomY == -99 && roomZ == 0) {
    room = <ArenaLobby {...roomProps} />;
  } else if (roomX < -90 && roomY < -90 && roomZ == 1) {
    room = <ArenaHives {...roomProps} />;
  } else if ((roomX == -100 && roomY == -98) || (roomX == -100 && roomY == -92)) {
    hud = true;
    room = <ArenaOutside {...roomProps} />;
  } else if ((roomX == -100 && roomY == -97) || (roomX == -100 && roomY == -93)) {
    hud = true;
    room = <ArenaPassage {...roomProps} />;
  } else if ((roomX == -100 && roomY == -96) || (roomX == -100 && roomY == -94)) {
    hud = true;
    room = <ArenaOffice {...roomProps} />;
  } else if (roomX == -100 && roomY == -95) {
    hud = true;
    room = <ArenaCourtyard {...roomProps} lab={lab} />;
  } else if (roomX == -99 && roomY == -98) {
    room = <ArenaHiveEntrance {...roomProps} />;
  } else if (roomX == -99 && roomY == -97) {
    room = <ArenaHive {...roomProps} />;
  } else if (roomX == -99 && roomY == -96) {
    room = <ArenaQuarantine quar={quar} setQuar={setQuar} {...roomProps} />;
  } else if (roomX == -98 && roomY == -98) {
    room = <ArenaHallway {...roomProps} />;
  } else if (roomX == -98 && roomY == -97) {
    room = <ArenaLab {...roomProps} lab={lab} />;
  } else if ((roomX == -98 && roomY == -96) || (roomX == -98 && roomY == -95) || (roomX == -99 && roomY == -95)) {
    room = <Hallucinogen lab={lab} setLab={setLab} quar={quar} setQuar={setQuar} {...roomProps} />;
  }


  let devmode = true;

  return ([
    <Debugger key='debugger' texts={texts[language]} inventory={inventory} debugging={debugging} equipped={equipped} code={code} setCode={setCode} setWait={setWait} wait={wait} coding={coding} setCoding={setCoding} setMessage={setMessage} setMessager={setMessager} message={message} speed={speed} avatar={avatar} images={images} />,
    <Shooter key='shooter' inventory={inventory} equipped={equipped} hud={hud} texts={texts[language]} visible={armed} setMessager={setMessager} shooting={shooting} clip={clip} setClip={setClip} code={code} setCode={setCode} setCoding={setCoding} />,
    <Coder key='coder' texts={texts[language]} trust={trust} setMessager={setMessager} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={governance} equipped={equipped} setEquipped={setEquipped} setEmpiredao={setEmpiredao} empiredao={empiredao} stackdao={stackdao} voting={voting} setVoting={setVoting} visualData={loadedVisualData} uiData={loadedUIData} scriptData={loadedScriptData} colorData={loadedColorData} loadedLantern={loadedLantern} />,
    false && <div style={{ zIndex: '999', position: 'absolute', top: '50px', width: '40px', right: '10px' }}>
      <div>{headed}</div><br></br>
      <input value={roomX} onChange={(e) => setRoomX(Math.round(parseFloat(e.target.value)))}/><br></br>
      <input value={roomY} onChange={(e) => setRoomY(Math.round(parseFloat(e.target.value)))}/><br></br>
      <input value={roomZ} onChange={(e) => setRoomZ(e.target.value)}/>
    </div>,
    <Logs family={family} setFamily={setFamily} stackdao={stackdao} lifespan={lifespan} inventory={inventory} hintersteps={hintersteps} visible={roomX < 0 && roomY >= 101 && roomZ != null} />,
    <Valuator inventory={inventory} equipped={equipped} visible={loadedValuator} yourValue={selfvalue} value={value} roomValue={roomValue} stalker={stalker} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,
    <Lantern inventory={inventory} equipped={equipped} visible={loadedLantern}  uiData={loadedUIData} code={code} setCode={setCode} setCoding={setCoding} visualData={loadedVisualData} scriptData={loadedScriptData} colorData={loadedColorData} objectData={loadedObjectData} setMessager={setMessager} texts={texts[language]} />,
    <div className='tooltipb'>{tooltip}</div>,
    diagram && <div className='diagram'><img src={diagram}></img></div>,
    !(roomX == 0 && roomY == 0) && <NavLink onClick={() => {
        setRoomX(0);
        setRoomY(0)
        setRoomZ('egrecore');
      }} className='exit' style={{ zIndex: '999', position: 'absolute', top: '0', right: '0' }}>{texts[language].ui.egrecore}</NavLink>,
    <div key='scene' className='echo' onClick={shoot.bind(this)}
      onMouseMove={(e) => {
        $('.tooltipb').css({'top': e.clientY, 'left': e.clientX + 20});
      }}
    >
      {redirect}
      <Canvas className='threejs' gl={{antialias:false}} camera={{ position: [0, 0, 1], near: 0.1, far: 1000 }}>
        <UI key={'ui'} />

        <Suspense fallback={null}>
        { !loadedLantern || (loadedLantern && loadedObjectData) ? room : null }
        </Suspense>

        { (shooting) && (<pointLight intensity={0.8} distance={30} decay={2} position={[10, 0, 5]} />) }

        <EffectComposer>
          {scanline}
          {!loadedLantern && <Noise blendFunction={BlendFunction.OVERLAY} />}
          {!loadedColorData && <Sepia />}
          <DotScreen scale={loadedLantern ? 2 : 1} />
          <Glitch active={glitchOn} mode={2} />
        </EffectComposer>

        <Controls freedom={freedom} />

      </Canvas>
      <Loader/>

  </div>
]);

};

export default React.memo(Echo);
