import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { Box, Plane, Sphere, Torus, useTexture } from "@react-three/drei";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { Redirect } from "react-router-dom";

import Blockhead from "../Blockhead.js"
import Image from "../Image.js"
import Loader from "../Loader.js"
import Enemy from "../Enemy.js"
import NPC from "../NPC.js"

const directions = ['l', 'u', 'r'];
const pointers =   ['nw', 'n', 'ne'];
const size = 40;

const Room = ({ images, code, setCode, coding, setCoding, setMessager, go, seeds, blackbox, armed, doGlitch, stopGlitch, clip, saveStatChange, setAvatar, setSpeed, setMessage, setRoomX, setRoomY, setRoomZ, setWait, roomX, roomY, roomZ, setHeaded, texts, remembering, setRedirect, puredao, lab, inventory }) => {

	let npc = <NPC
		scale={1}
		position={[5, 0, -15]}
		rotation={[0, -Math.PI/1.5, 0]}
		image={images(`./enemy/face14.jpg`).default}
		geometry={<sphereGeometry attach="geometry" />}
		material='phong'
		onOver={(e) => {
			document.body.style.cursor = "help";
			setCode({
				...code,
				header: 'memory', input: inventory,
				algorithm: [{id: 'stigmata'}, {id: 'flag'}],
			});
			setMessager("", 10, null, false, texts.ui.remember);
		}}
		onClick={(e) => {
			setCoding(true);
			setMessager(texts.ui.remembering, 10, null, true);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
	/>;


	return (<group>

		<Suspense fallback={<Loader/>}>
			{remembering && npc}
			<Image
				image={ images(`./checkerboard.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "s-resize";
					setMessager("", 10, null, false, texts.ui.exit);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					document.body.style.cursor = "default";
					setRoomX(-100);
					setRoomY(-99);
					setRoomZ(0);
				}}
				repeat={true}
				material='phong'
				scale={68}
				position={[0, -15, 0]}
				rotation={[Math.PI/2, 0, 0]} />




			<Image
				image={ images(`./checkerboard.png`).default }
				repeat={true}
				material='phong'
				scale={68}
				geometry={<boxGeometry></boxGeometry>}
				position={[0, 50, -100]}
				rotation={[Math.PI/2, 0, 0]} />



			<Image
				image={ images(`./echo/pages/curse_64.png`).default }
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default"
					setMessager();
				}}
				onClick={(e) => {
					document.body.style.cursor = "default";
					setRoomY(roomY + 1);
				}}
				material='phong'
				scale={[8,15,1]}
				position={[0,10,-100]}
				rotation={[0, 0, 0]} />

		</Suspense>

	</group>)
}

export default React.memo(Room);
