import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Room = ({ images, setMessage, setAvatar, roomY, roomZ, setRoomY, setRoomZ, clip, setSpeed, code, setCode, go, setMessager, texts }) => {

	useEffect(() => {
		setMessager(texts.puredao.entered, 30, null, true);
	}, []);

	let columns = [];
	for (var i = 0; i < 4; i++) {
		for (var j = 0; j < 2; j++) {
			columns.push(<Image
				material='phong'
				image={images(`./echo/pages/curse_${(i + 1)*(j + 1)}.png`).default}
				scale={30}
				repeat={false}
				position={[-20 + (j * 40), 10, -60 + i * 15]}
				scale={[5,20,1]}
				rotation={[0, 0, 0]} />);
		}
	}

	return ([

		<Suspense fallback={null}>
			<Image
				key={'floor'}
				image={ images(`./echo/pages/curse_41.png`).default }
				material='phong'
				textureRepeat={1}
				repeat={true}
				position={[0, -7, -10]}
				scale={[15, 78, 3]}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				material='phong'
				dampenRotation={10}
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, "ENTER");
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ(5);
					setRoomY(roomY + 1);
				}}
				scale={[30,25,1]}
				position={[0, 10, -50]}
				image={ images(`./echo/pages/killarena.png`).default } />

			{columns}

			{/*<Image scale={40} position={[0, 0, -20]} image={ images(`./echo/${X.toString() + Y.toString()}.png`).default } />*/}
			{/*<Image image={ images(`./echo/av1.png`).default } />*/}

		</Suspense>

	])
}

export default memo(Room);
