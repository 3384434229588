import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { useGetAndSet, withStore, useStore } from "react-context-hook";
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import texts from "../data/texts.js"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Hero from "../components/Hero";

import Writer from "../components/Writer.js"
import Delayer from "../components/Delayer.js"
import Remover from "../components/Remover.js"
import Debugger from "../components/Debugger.js"
import Coder from "../components/Coder.js"
import Shooter from "../components/Shooter.js"
import Valuator from "../components/Valuator.js"
import Lantern from "../components/Lantern.js"

import captcha from "../assets/logo-captcha.png"
import av1 from "../assets/avatars/avatar_19.png"
import av2 from "../assets/avatars/avatar_6.png"
import av3 from "../assets/avatars/avatar_7.png"
import av4 from "../assets/avatars/avatar_8.png"

import items from '../data/items.js'

import { useAuth0 } from "@auth0/auth0-react";

const images = require.context('../assets/', true);

const Gatekeeper = (props) => {

  const [ language, setLanguage ] = useGetAndSet('language');
  const [ debugging, setDebugging ]  = useState(true);
  const [ added, setAdded ]  = useState(false);
  const [ message, setMessage ] = useState("");
  const [coding, setCoding] = useState(false);
  const [equipped, setEquipped] = useGetAndSet('equipped');
  const [inventory, setInventory] = useGetAndSet('inventory');
  const [code, setCode] = useState({
    'header': null,
    'input': inventory,
    'algorithm': equipped,
  });

  useEffect(() => {
    if (!coding) return
    if (code.algorithm.find(d=>d.id == 'lantern')) {
      setCoding(false);
      if (!inventory.find(d=>d.id == 'visualdata') && !equipped.find(d=>d.id == 'visualdata')) {
        setInventory(inventory.concat({id: 'objectdata'}));
        let temp = equipped.slice();
        let remove = equipped.findIndex(d=>d.id == 'lantern');
        temp.slice(remove, 1);
        temp.push({id: 'uidata'});
        setEquipped(temp);
      }
    }
  }, [code]);

  function addPlugin() {
    setInventory(inventory.concat({id: 'lantern'}));
    setAdded(true);
  }

  let hasLantern = code.input.find(d=>d.id == 'lantern');
  let loadedWeapon = code.algorithm.find(d=>d.id == 'loyalist');
  let loadedValuator = code.algorithm.find(d=>d.id == 'valuator');
  let loadedLantern = code.algorithm.find(d=>d.id == 'lantern');

  return ([

    (debugging && <Debugger texts={texts[language]} inventory={inventory} equipped={equipped} setCoding={setCoding} coding={coding} code={code} setCode={setCode} message={message} debugging={debugging} setMessage={setMessage} images={images} governance={'root'} />),

    (debugging && <Coder texts={texts[language]} trust={0} setMessager={() => {setMessage("")}} setCoding={setCoding} visible={coding} code={code} setCode={setCode} governance={'root'} />),

    <Shooter texts={texts[language]} visible={loadedWeapon} setMessager={() => {setMessage("")}} shooting={false} clip={8} setClip={() => {setMessage("")}} code={code} setCode={setCode} setCoding={setCoding} />,

    <Valuator visible={loadedValuator} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,

    <Lantern visible={loadedLantern} code={code} setCode={setCode} setCoding={setCoding} setMessager={() => {}} texts={texts[language]} />,

    (<div className='egrecorp offline'>
      <div>
        <img className='alertimage' src={images('./face-2.png').default}></img>
        <u className='alertheader'><Writer delay={0} speed={10} msg={"WAIT!"} /></u>
        <Writer delay={1000} speed={10} msg={"The DEBUGGER is not allowed to convert DATA into images here"}/><br></br>
      </div>
      {!added && !hasLantern && !loadedLantern && (
        <div>
        <Writer delay={3000} speed={10} msg={"Please add an imager that is supported in this environment:"}/><br></br>
        <Delayer delay={5000}>
          <div className='plugin'>
            <img src={ images(`./products/lantern.gif`).default }/>
            <div style={{ textAlign: 'left' }}>
              <div className='title'>{"ENLIGHTENER v26.0"}</div>
              <div className='title'>{"Environment imager"}</div><br></br>
              <a className='add' onClick={ addPlugin.bind(this) }>{"Click to add CODE"}</a>
              <img className='pluginlogo' src={ images(`./logo-egrecorp.png`).default }/>
            </div>
          </div>
        </Delayer>
        </div>
      )}
      {(added || hasLantern) && !loadedLantern && (
        <div>
        <Writer delay={0} speed={10} msg={"ENLIGHTENER added"}/><br></br>
        <Writer delay={1000} speed={10} msg={"Please click the DEBUGGER to load the ENLIGHTENER"}/><br></br>
        </div>
      )}
      {loadedLantern && (
        <Redirect to={{
              pathname: 'echo',
              state: { room: props.location.state.route },
              equipped: equipped
            }} />
      )}

    </div>)

  ]);

};

export default Gatekeeper;
