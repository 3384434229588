import React, { Fragment, Suspense, useMemo, useState, useEffect } from "react";
import { withStore, useStore } from "react-context-hook";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Debugger from "../components/Debugger.js"

import items from '../data/items.js'
import stars from "../assets/bg-stars.png"

const images = require.context('../assets', true);

const Products = () => {

  return ([
    <div className='egrecorp services' style={{ backgroundImage: `url(${stars})`, height: '100vh' }}>

      <u>{"The E.C.H.O. Chamber"}</u><br></br><br></br>
      <div>{"The ECHO CHAMBER is an interactive breeding ground for experimenting with CODES to create new societies, political environments, and systems of governance."}<br></br><br></br></div>

      <div>{"Before you go, please make sure to read "}<NavLink to='services'>Our Mission</NavLink>{". This is a living, breathing experiment that depends on your help to survive"}<br></br><br></br></div>

      <NavLink to={{ pathname: 'echo', state: {room: [0, 0, 'egrecore']} }}>{"Continue to the E.C.H.O. Chamber >"}</NavLink><br></br><br></br>

      <NavLink to='/home'>{"< Back to home"}</NavLink>

    </div>
]);

};

export default Products;
