import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import Loader from "../Loader.js"
import NPC from "../NPC.js"

const Dock = ({ images, setMessager, setRoomY, setRoomZ, clip, code, setCode, go, texts, values, setValues, readValue }) => {

	return ([

		<Suspense key={'foyer'} fallback={null}>
			<Image
				key={'floor'}
				image={ images(`./echo/boards.jpg`).default }
				material='phong'
				textureRepeat={4}
				repeat={true}
				position={[0, -7, -10]}
				scale={[15, 78, 3]}
				rotation={[-Math.PI / 2, 0, 0]} />
			<Image
				key={'skybox'}
				image={ images(`./echo/pages/curse_${2}.png`).default }
				geometry={<sphereGeometry></sphereGeometry>}
				material='phong'
				repeat={false}
				repeatTexture={false}
				position={[0, 0, -100]}
				scale={600}
				rotation={[Math.PI/8, 0, 0]} />
			<Image
				key='floor'
				image={ images(`./echo/pages/curse_${40}.png`).default }
				material='phong'
				repeat={true}
				textureRepeat={1}
				position={[0, -8, -10]}
				scale={[300, 150, 1]}
				rotation={[-Math.PI / 2, 0, 0]} />
			<NPC
				image={ images(`./echo/pages/right_62.png`).default }
				material='phong'
				repeat={false}
				position={[-15, -10, -30]}
				rotation={[Math.PI/4, 0, 0]}
				onOver={() => {
					readValue(0);
				}}
				onOut={() => {
					readValue();
				}}
				scale={[7, 10, 1]}/>
			<NPC
				key={'boat'}
				material='phong'
				dampenRotation={10}
				onOver={(e) => {
					document.body.style.cursor = "n-resize";
					setMessager("", 10, null, false, texts.ui.enter);
				}}
				onOut={(e) => {
					document.body.style.cursor = "default";
					setMessager();
				}}
				onClick={(e) => {
					setRoomZ('boat');
				}}
				scale={30}
				position={[0, 3, -50]}
				image={ images(`./echo/boat.png`).default } />

		</Suspense>

	])
}

export default memo(Dock);
