import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import * as THREE from 'three'
import { Box, Plane, useTexture } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { Physics, useBox, usePlane, useSphere } from "@react-three/cannon";
import { TextureLoader, LinearFilter, ClampToEdgeWrapping } from 'three';
import { EffectComposer, Pixelation, Glitch, GlitchMode } from '@react-three/postprocessing';

const Image = ({ image, repeat, position, rotation, scale, material, onClick, geometry, onOver, onOut, onMove, textureRepeat, textureRepeatX, textureRepeatY, opacity, transparent }) => {

  const ref = useRef();

  const props = useTexture({
    map: image
  })

  const texture = useLoader(TextureLoader, image);
  const { viewport } = useThree();
  useMemo(() => {
    texture.generateMipmaps = false
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping
    texture.minFilter = LinearFilter
    texture.needsUpdate = true
    if (repeat) {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set( textureRepeatX || textureRepeat || 20, textureRepeatY || textureRepeat || 20 );
    }
  }, [
    texture.generateMipmaps,
    texture.wrapS,
    texture.wrapT,
    texture.minFilter,
    texture.needsUpdate,
    texture.repeat,
  ])

  /*
  let meshGeometry = geometry != null ? geometry : new THREE.PlaneBufferGeometry() ;
  let meshMaterial = material == 'phong' ? new THREE.MeshPhongMaterial({map: texture, transparent: true, side: THREE.DoubleSide}) : new THREE.MeshLambertMaterial({map:texture, transparent: true, side: THREE.DoubleSide});

  let mesh = new THREE.Mesh(meshGeometry, meshMaterial);

  return (
    <primitive object={mesh} key={k} scale={scale} rotation={rotation} position={position} onClick={click} onPointerOut={onOut} onPointerOver={onOver} ref={ref} castShadow={true} receiveShadow={true}>
    </primitive>
  )
  */

  let mat = <meshLambertMaterial attach="material" {...props} transparent='true' side={THREE.DoubleSide} />;
  if (material == 'phong') mat = <meshPhongMaterial opacity={opacity || 1} attach="material" {...props} transparent={transparent != null ? transparent : 'false'} side={THREE.DoubleSide} />;
  else if (material == 'normal') mat = <meshNormalMaterial attach="material" {...props} transparent='true' side={THREE.DoubleSide} />;
  else if (material == 'basic') mat = <meshBasicMaterial attach="material" {...props} transparent='true' side={THREE.DoubleSide} />;

  return (
    <mesh scale={scale || [texture.image.width / 20, texture.image.height / 20, 1]} rotation={rotation} position={position} onClick={onClick} onPointerOut={onOut} onPointerOver={onOver} onPointerMove={onMove} ref={ref} castShadow={true} receiveShadow={true}>
      { geometry != null ? geometry : <planeBufferGeometry attach="geometry" />}
      { mat }
    </mesh>
  )
}

export default Image;
