import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { withStore, useStore } from "react-context-hook";

import Hero from "../components/Hero";

const Codes = () => {
  const [ getCodes, setCodes, deleteCodes ] = useStore('codes');

  return (
    <Container className="mb-5">
      <Row>
        <Highlight>{JSON.stringify(getCodes, null, 2)}</Highlight>
      </Row>
    </Container>
  );
};

export default Codes;
