import React, { Fragment, Suspense, useMemo, useState, useEffect, memo, useRef } from "react";

import Image from "../Image.js"
import NPC from "../NPC.js"
import Loader from "../Loader.js"

const Room = ({ images, setMessager, roomX, roomY, roomZ, setRoomX, setRoomY, setRoomZ, doGlitch, stopGlitch, clip, code, setCode, coding, locked1, locked2, user, texts, up, left, right, face, position, speech, NPCvalue, readValue }) => {

	let doors = [];
	doors.push(<Image
		image={ images(`./echo/cagedlight.png`).default }
		material='phong'
		repeat={false}
		position={[0, 3, -70]}
		scale={[3, 1.5, 1]}
		rotation={[0, 0, 0]}
		/>);
	doors.push(<Image
		image={ images(`./doors/door-sub2.png`).default }
		material='phong'
		repeat={false}
		position={[0, -3, -70]}
		scale={[7, 10, 1]}
		rotation={[0, 0, 0]}
		onOver={(e) => {
			document.body.style.cursor = "n-resize";
			setMessager("", 10, null, false, texts.ui.enter);
		}}
		onOut={(e) => {
			document.body.style.cursor = "default";
			setMessager();
		}}
		onClick={(e) => {
			setRoomY(roomY + 1);
		}}
		/>);

	let pipes = [];
	for (let i = 0; i < 4; i++) {
		pipes.push(<group onPointerOver={(e) => {
				document.body.style.cursor = "zoom-in";
				setMessager("", 10, null, false, texts.ui.view);
			}}
			onPointerOut={(e) => {
				document.body.style.cursor = "default";
				setMessager();
			}}
			onClick={(e) => {
				setRoomX(roomX + i);
				setRoomZ('pipe');
			}}>
			<mesh position={[-28.3 + i * 18.9, 2, -38]} scale={[2, 4, 1]} rotation={[0,0,0]}>
				<boxGeometry></boxGeometry>
				<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
			</mesh>
			<mesh position={[-30 + i * 20, 20, -40]} scale={[1, 60, 1]}>
				<cylinderGeometry></cylinderGeometry>
				<meshPhongMaterial color={'#777777'}></meshPhongMaterial>
			</mesh>
			<Image
				image={ images(`./echo/wheel2.png`).default }
				material='phong'
				repeat={false}
				position={[-28.3 + i * 18.9, -3, -37]}
				scale={4}
				rotation={[0, 0, 0]}
				/>
		</group>);
	}

	return (<group>

		<Suspense fallback={<Loader/>}>
			<group>{pipes}</group>
			<mesh
				scale={[10,80,1]}
				position={[0,-8,-20]}
				rotation={[-Math.PI/2, 0, 0]}>
				<planeGeometry></planeGeometry>
				<meshPhongMaterial color='#000000'></meshPhongMaterial>
			</mesh>
			{doors}
			{face != null && speech != null && <NPC
				scale={2}
				position={position == null ? [0, 0, -20] : position}
				rotation={[0, -Math.PI/2 + (position == null ? 0 : -position[0]*Math.PI/100), 0]}
				image={images(`./enemy/face${face}.jpg`).default}
				geometry={<sphereGeometry attach="geometry" />}
				material='phong'
				onOver={(e) => {
					document.body.style.cursor = "help";
					readValue('npc' + face);
					setMessager("", 10, null, false, texts.ui.remember);
				}}
				onClick={(e) => {
					setMessager(speech, 40, images(`./enemy/face${face}.jpg`).default, true);
				}}
				onOut={(e) => {
					readValue();
					document.body.style.cursor = "default";
					setMessager();
				}}
			/>}
			</Suspense>


	</group>)
}

export default React.memo(Room);
